<div class="pd-wrapper">
  <div class="logo- holder offset-1z">
    <app-file-upload
      [placeholder]="supplier?.logo ? supplier?.logo : ('Logo' | translate)"
      [iconType]="'add_photo_alternate'"
      [imageUrl]="supplier?.logo"
      [readonly]="true"
    ></app-file-upload>
  </div>
  <div class="info-holder">
    <div class="info-header">
      <h5 class="name">
        {{ supplier?.name }}
      </h5>

      <!--actions-->
      <div class="icon-holder">
        <div
          id="view-icon"
          matTooltip="{{ 'View Supplier' | translate }}"
          matTooltipClass="custom-tooltip-center centering"
          matTooltipPosition="above"
        >
          <app-custom-icon
            test-id="icon-supplier_page_details-view_supplier"
            [icon_category]="'security'"
            [icon_name]="'eye'"
            (click)="viewDetails()"
          >
          </app-custom-icon>
        </div>
        <div
          id="edit-icon"
          matTooltip="{{ 'Edit Supplier' | translate }}"
          matTooltipClass="custom-tooltip-center centering"
          matTooltipPosition="above"
        >
          <app-custom-icon
            test-id="icon-supplier_page_details-edit_supplier"
            [icon_category]="'files'"
            [icon_name]="'edit'"
            (click)="editDetails()"
          >
          </app-custom-icon>
        </div>
        <div
          id="delete-icon"
          matTooltip="{{ 'Delete Supplier' | translate }}"
          matTooltipClass="custom-tooltip-center centering"
          matTooltipPosition="above"
        >
          <app-custom-icon
            test-id="icon-supplier_page_details-delete_supplier"
            [icon_category]="'essential'"
            [icon_name]="'trash'"
            (click)="removeSupplier()"
          >
          </app-custom-icon>
        </div>
      </div>
    </div>
    <div class="description-holder">
      <div class="description">
        {{ 'Description' | translate }} :
        <span *ngIf="supplier?.description; else noneblock">
          <app-view-rich-text [content]="supplier.description ?? ''"></app-view-rich-text>
        </span>
      </div>
    </div>

    <!-- <div class="details-btn">
      <app-rounded-button
        (click)="viewDetails()"
        color="tetriary-link"
        class="link"
        [button_text]="'Read supplier details'"
        [icon_category]="'finance'"
        [icon_name]="'document'"
        [icon_right]="true"
      >
      </app-rounded-button>
    </div> -->
  </div>
</div>

<ng-template #noneblock>
  <span class="col-8">
    {{ 'None' | translate }}
  </span>
</ng-template>

import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ISupplier, ISuppliersResponse } from '@app/core/interface/suppliers.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CountryList } from '@app/core/utils/country-list';
import { CreateSupplierComponent } from '@app/module/dashboard/supplier/create-supplier/create-supplier.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { applyFilter } from '@app/core/utils/apply-filter';
import { environment } from '@env/environment';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss'],
})
export class SupplierListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['name', 'city', 'country', 'count_unique_product_chains', 'actions'];
  cardDataSource = new MatTableDataSource<ISupplier>();
  tableDataSource = new MatTableDataSource<ISupplier>();
  supplierListObservable$!: Observable<ISupplier[]>;
  supplierList: ISupplier[] = [];
  assetPath = environment.assetPath;
  deleteUuid = '';
  show_deactivated = false;
  countryList = CountryList;

  constructor(
    private readonly _suppliersFacade: SuppliersFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly _router: Router,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private readonly _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._suppliersFacade.getSupplierTableList$('is_active,name', 999999).subscribe({
      next: this._getSupplierListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.paginator.pageSize = 12;
      this.cardDataSource.paginator = this.paginator;
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    });
  }

  viewChange(value: string): void {
    if (value === 'tableView') {
      this._changeDetectorRef.detectChanges();
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    } else {
      this.cardDataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource, this.tableDataSource);
  }

  deleteSupplierDialog(uuid: string, name: string): void {
    this.deleteUuid = uuid;

    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete supplier: ${name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._suppliersFacade.deleteSupplier$(uuid).subscribe({
          next: this._deleteSupplier.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _deleteSupplier(): void {
    this.cardDataSource.data = this.cardDataSource.data.filter(supplier => supplier.uuid !== this.deleteUuid);
    this.tableDataSource.data = this.tableDataSource.data.filter(supplier => supplier.uuid !== this.deleteUuid);

    this._snackbarService.openTypeSnackbar(`Supplier deleted`, NotificationType.success);

    this.deleteUuid = '';
  }

  private _getSupplierListSuccess(data: ISuppliersResponse): void {
    if (data?.results.length) {
      this.supplierList = data.results.map(supplier => {
        return {
          ...supplier,
          country: this.countryList.getCountryName(supplier.country as string),
        };
      });

      if (this.show_deactivated) {
        this.tableDataSource.data = this.supplierList;
        this.cardDataSource.data = this.supplierList;
        this.supplierListObservable$ = this.cardDataSource.connect();
      } else {
        this.cardDataSource.data = this.supplierList.filter(supplier => supplier.is_active);
        this.tableDataSource.data = this.supplierList.filter(supplier => supplier.is_active);
        this.supplierListObservable$ = this.cardDataSource.connect();
      }
    } else {
      this._router.navigate(['/dashboard/supplier/supplier-list']);
    }
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  createSupplierDialog(): void {
    const dialogRef = this._dialog.open(CreateSupplierComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  deactivateSupplier(supplier: ISupplier) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to deactivate the selected supplier. Note that with deactivating the supplier, you are also deactivating all certificates & labels.`,
        confirmationText: 'Are you sure you want to deactivate this supplier?',
        btnText: 'Deactivate Supplier',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._suppliersFacade.activateDeactivateSupplier$(supplier.uuid, { is_active: false }).subscribe({
          next: this._success.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  reactivateSupplier(supplier: ISupplier) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to reactivate the selected supplier. Note that with reactivating the supplier, you are also reactivating all certificates & labels.`,
        confirmationText: 'Do you wish to continue??',
        btnText: 'Reactivate Supplier',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._suppliersFacade.activateDeactivateSupplier$(supplier.uuid, { is_active: true }).subscribe({
          next: this._success.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  _success(): void {
    this.ngOnInit();
  }

  showDeactivatedSuppliers(event: MatCheckboxChange): void {
    this.show_deactivated = !this.show_deactivated;
    this.tableDataSource.data = !event.checked
      ? this.supplierList?.filter(product => product?.is_active)
      : this.supplierList;
    this.tableDataSource.paginator?.firstPage();
    this.cardDataSource.data = !event.checked
      ? this.supplierList?.filter(product => product?.is_active)
      : this.supplierList;
    this.cardDataSource.paginator?.firstPage();
    this.supplierListObservable$ = this.cardDataSource.connect();
  }
}

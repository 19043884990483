import {
  ILanguageManagementBrandsResponse,
  ILanguageManagementCertificatesResponse,
  ILanguageManagementProductsResponse,
  ILanguageManagementPutRequest,
  ILanguageManagementResponse,
  ILanguageManagementSuppliersResponse,
  ILanguageManagementSupplyChainListResponse,
  ILanguageManagementSupplyChainResponse,
  ILanguageManagementSupplyChainTotalCount,
} from '../interface/language-management.interface';
import { Injectable, signal } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageManagementService {
  private apiUrl = environment.api;
  private refreshBrandSource = new BehaviorSubject<boolean>(false);
  refreshBrand$ = this.refreshBrandSource.asObservable();
  private refreshProductSource = new BehaviorSubject<boolean>(false);
  refreshProduct$ = this.refreshProductSource.asObservable();
  private refreshSupplierSource = new BehaviorSubject<boolean>(false);
  refreshSupplier$ = this.refreshSupplierSource.asObservable();
  private refreshSupplayChainSource = new BehaviorSubject<boolean>(false);
  refreshSupplayChain$ = this.refreshSupplayChainSource.asObservable();
  private refreshSupplayCertificateSource = new BehaviorSubject<boolean>(false);
  refreshCertificateChain$ = this.refreshSupplayCertificateSource.asObservable();
  private refreshTotalCounterSource = new BehaviorSubject<boolean>(false);
  refreshTotalCounter$ = this.refreshTotalCounterSource.asObservable();

  constructor(private _http: HttpClient) {}

  getLanguageManagementCertificates$(isFullyTranslated?: boolean): Observable<ILanguageManagementCertificatesResponse> {
    const supplyChainFilters = [this.supplyChainParamsFilter(), this.supplyChainUuidFilter()]
      .filter(filter => !!filter)
      .join('');

    const endpointUrl = isFullyTranslated
      ? `${this.apiUrl}/language-management/certificate-translations/?filter=is_fully_translated`
      : `${this.apiUrl}/language-management/certificate-translations/${supplyChainFilters}`;

    return this._http.get<ILanguageManagementCertificatesResponse>(endpointUrl);
  }

  getLanguageManagementBrands$(): Observable<ILanguageManagementBrandsResponse> {
    return this._http.get<ILanguageManagementBrandsResponse>(`${this.apiUrl}/language-management/brand-translations/`);
  }

  getLanguageManagementProducts$(isFullyTranslated?: boolean): Observable<ILanguageManagementProductsResponse> {
    const supplyChainFilters = [this.supplyChainParamsFilter(), this.supplyChainUuidFilter()]
      .filter(filter => !!filter)
      .join('');

    const endpointUrl = isFullyTranslated
      ? `${this.apiUrl}/language-management/product-translations/?filter=is_fully_translated`
      : `${this.apiUrl}/language-management/product-translations/${supplyChainFilters}`;

    return this._http.get<ILanguageManagementProductsResponse>(endpointUrl);
  }

  getLanguageManagementSupplyChains$(isFullyTranslated?: boolean): Observable<ILanguageManagementSupplyChainResponse> {
    const supplyChainFilters = [this.supplyChainParamsFilter(), this.supplyChainUuidFilter()]
      .filter(filter => !!filter)
      .join('');

    const endpointUrl = isFullyTranslated
      ? `${this.apiUrl}/language-management/chain-translations/?filter=is_fully_translated`
      : `${this.apiUrl}/language-management/chain-translations/${supplyChainFilters}`;

    return this._http.get<ILanguageManagementSupplyChainResponse>(endpointUrl);
  }

  getLanguageManagementSupplyChainsFilter$(): Observable<ILanguageManagementSupplyChainResponse> {
    return this._http.get<ILanguageManagementSupplyChainResponse>(
      `${this.apiUrl}/language-management/chain-translations/?filter=all`
    );
  }

  getBrandForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/brand-translations/${uuid}/`
    );
  }

  putBrandForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/brand-translations/${uuid}/translations/`,
      data
    );
  }

  getCertificatedForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/certificate-translations/${uuid}/?filter=all`
    );
  }

  getSupplyChainForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/chain-translations/${uuid}/?filter=all`
    );
  }

  putCertificateForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/certificate-translations/${uuid}/translations/?filter=all`,
      data
    );
  }

  getProductForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/product-translations/${uuid}/?filter=all`
    );
  }

  putProductForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/product-translations/${uuid}/translations/?filter=all`,
      data
    );
  }

  putSupplyChainForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/chain-translations/${uuid}/translations/?filter=all`,
      data
    );
  }

  getLanguageManagementSuppliers$(isFullyTranslated?: boolean): Observable<ILanguageManagementSuppliersResponse> {
    const supplyChainFilters = [this.supplyChainParamsFilter(), this.supplyChainUuidFilter()]
      .filter(filter => !!filter)
      .join('');

    const endpointUrl = isFullyTranslated
      ? `${this.apiUrl}/language-management/supplier-translations/?filter=is_fully_translated`
      : `${this.apiUrl}/language-management/supplier-translations/${supplyChainFilters}`;

    return this._http.get<ILanguageManagementSuppliersResponse>(endpointUrl);
  }
  getSupplierForTranslation$(uuid: string | null): Observable<ILanguageManagementResponse> {
    return this._http.get<ILanguageManagementResponse>(
      `${this.apiUrl}/language-management/supplier-translations/${uuid}/?filter=all`
    );
  }

  putSupplierForTranslation$(
    uuid: string,
    data: ILanguageManagementPutRequest[]
  ): Observable<ILanguageManagementPutRequest> {
    return this._http.put<ILanguageManagementPutRequest>(
      `${this.apiUrl}/language-management/supplier-translations/${uuid}/translations/?filter=all`,
      data
    );
  }

  getListOfSupplyCains$(): Observable<ILanguageManagementSupplyChainListResponse> {
    return this._http.get<ILanguageManagementSupplyChainListResponse>(
      `${this.apiUrl}/language-management/supplychain_filter/`
    );
  }

  getChainTRanslationTotalCount$(uuid: string): Observable<ILanguageManagementSupplyChainTotalCount> {
    return this._http.get<ILanguageManagementSupplyChainTotalCount>(
      `${this.apiUrl}/language-management/chain-translations/${uuid}/fields/?filter=all`
    );
  }

  supplyChainUuidFilter = signal<string>('');
  supplyChainParamsFilter = signal<string>('');

  triggerBrandRefresh(refresh: boolean): void {
    this.refreshBrandSource.next(refresh);
  }

  triggerProductRefresh(refresh: boolean): void {
    this.refreshProductSource.next(refresh);
  }
  triggerSupplierRefresh(refresh: boolean): void {
    this.refreshSupplierSource.next(refresh);
  }

  triggerSupplyChainRefresh(refresh: boolean): void {
    this.refreshSupplayChainSource.next(refresh);
  }

  triggerCertificateRefresh(refresh: boolean): void {
    this.refreshSupplayCertificateSource.next(refresh);
  }

  triggerTotalCounterRefresh(refresh: boolean): void {
    this.refreshTotalCounterSource.next(refresh);
  }
}

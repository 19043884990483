import { IPhase } from '@app/core/interface/phase-management.interface';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { ProductChainGroup } from '../product-chain.group';
import { UntypedFormGroup } from '@angular/forms';
import { infoDialogHeight, infoDialogWidth, NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { EditClassificationComponent } from '@app/module/publication/chain-overview/setup-chain/edit-classification/edit-classification.component';
import { CustomPublicationsFacade } from '@app/module/publication/custom-publications/custom-publications.facade';
import { NodeContainerComponent } from '../node-container/node-container.component';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';

@Component({
  selector: 'app-add-steps-phase',
  templateUrl: './add-steps-phase.component.html',
  styleUrls: ['./add-steps-phase.component.scss'],
  providers: [ProductChainGroup, StepsFacade],
})
export class AddStepsPhaseComponent implements OnInit {
  selectedPhase?: IPhase;
  formToggleUpdateStep: UntypedFormGroup;
  isChanged = signal<boolean>(false);
  protected tooltipDisabledPhase =
    'In order to edit or create new phase please first deselect the selected phase by clicking on it';

  constructor(
    private readonly _group: ProductChainGroup,
    private readonly _stepFacade: StepsFacade,
    private readonly _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: { product_chain: IProductChainResult; selectedSteps: string[] },
    public dialogRef: MatDialogRef<AddStepsPhaseComponent>,
    private readonly _dialog: MatDialog,
    private readonly _customPublicationFacade: CustomPublicationsFacade
  ) {
    this.formToggleUpdateStep = this._group.updatePhaseStep;
    this.data.product_chain.steps.sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnInit() {
    this._customPublicationFacade.data$.subscribe(data => {
      if (data) {
        if (data.selectedChain) {
          this.data.product_chain = data.selectedChain;
        }
        this.data.selectedSteps = data.selectedStepsList || [];
      }
    });
  }

  selectPhase(phase: IPhase): void {
    this.data.selectedSteps = [];

    if (this.selectedPhase === phase) {
      this.selectedPhase = undefined;
      if (this.isChanged()) {
        this.isChanged.set(false);
        this._refresh();
      }
    } else {
      this.selectedPhase = phase;
      this.formToggleUpdateStep.patchValue({
        type_step: this.selectedPhase?.uuid,
      });

      const selectedSteps = this.data.product_chain.steps.filter(step => step.type_step?.uuid === phase.uuid);
      selectedSteps.forEach(step => step.uuid && this.data.selectedSteps.push(step.uuid));
    }
  }

  toggleHelper(stepUuid: string): boolean {
    return this.data.selectedSteps.includes(stepUuid);
  }

  updateStepList(stepUuid: string, checked: boolean): void {
    this.isChanged.set(true);
    let typeStep = '';

    if (checked) {
      typeStep = this.selectedPhase?.uuid || '';
    } else {
      typeStep = '';
    }

    this.formToggleUpdateStep.patchValue({
      type_step: typeStep,
    });
    this._stepFacade.updateStep$(this.formToggleUpdateStep.value, stepUuid).subscribe({
      next: () => {},
      error: this._error.bind(this),
    });
  }

  createNewEditPhase(classification?: IPhase): void {
    if (this.selectedPhase !== undefined) {
      return;
    }

    const dialogRef = this._dialog.open(EditClassificationComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: { classification, product_chain: this.data.product_chain.uuid },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        this._refresh();
      }
    });
  }

  removeClassification(uuid: string): void {
    if (this.selectedPhase !== undefined) {
      return;
    }

    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete the phase. Please note that this action won’t delete the steps, it will just remove them from the phase.',
        confirmationText: 'Are you sure you want to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && this.data.product_chain.uuid) {
        this._customPublicationFacade.deleteClassification(this.data.product_chain.uuid, uuid).subscribe({
          next: () => this._refresh(),
          error: this._error.bind(this),
        });
      }
    });
  }

  /////////////////////////////////////////////Private Methods/////////////////////////////////////////////

  private _refresh(): void {
    this.selectedPhase = undefined;
    NodeContainerComponent.refreshEvent.emit();
  }

  private _error(error: string[]): void {
    this._snackbarService.openTypeSnackbar(`${error}`, NotificationType.error);
    this._refresh();
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { EMAIL_SUGGESTIONS } from '@app/core/constants';
import { EmailGroup } from '../../email.group';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-email-content',
  templateUrl: './create-email-content.component.html',
  styleUrls: ['./create-email-content.component.scss'],
  providers: [EmailGroup],
})
export class CreateEmailContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() templateContent = '';
  @Input() lang = '';
  @Input() isEditModal = false;
  @Output() templateContentChange = new EventEmitter<string>();
  @Output() resetForm = new EventEmitter<boolean>();
  @ViewChild('textarea') textarea!: ElementRef;
  @ViewChild('hiddenDiv') hiddenDiv!: ElementRef;

  @HostListener('document:click', ['$event'])
  hideSuggestions() {
    this.showSuggestions = false;
  }

  suggestionsTop = 0;
  suggestionsLeft = 0;
  showSuggestions = false;
  readonly suggestions = EMAIL_SUGGESTIONS;
  translatedSuggestions: string[] = [];
  form!: UntypedFormGroup;
  values: object = {};
  subscription?: Subscription;
  regex = /\{[^}]*\}/g;

  constructor(private readonly _group: EmailGroup) {
    this.form = this._group.emailForm;
  }
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.subscription = this.form.controls['content'].valueChanges.subscribe(value => {
      this.checkAndMoveCursor(value);
    });
  }
  private checkAndMoveCursor(text: string): void {
    const textarea: HTMLTextAreaElement = this.textarea.nativeElement;
    const cursorPosition = textarea.selectionStart;

    // Find all matches of variables in the form of {variable}
    let match;
    while ((match = this.regex.exec(text)) !== null) {
      const start = match.index;
      const end = this.regex.lastIndex;
      if (cursorPosition > start && cursorPosition < end) {
        // Move cursor to the end of the variable if within variable bounds
        textarea.setSelectionRange(end, end);
        break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['templateContent']) {
      this.form.setValue(changes['templateContent'].currentValue);
      this.values = changes['templateContent'].currentValue;
    }
    if (changes['lang']) {
      this.translatedSuggestions = this.suggestions.map(suggestion => suggestion[this.lang as keyof typeof suggestion]);
    }
  }

  resetFormValues(): void {
    this.resetForm.emit(true);
    this.form.markAsPristine();
  }

  getCursorPosition(): number {
    const textarea = this.textarea.nativeElement;
    const cursorPosition = textarea.selectionStart;
    return cursorPosition;
  }

  handleKeydown(event: KeyboardEvent): void {
    this.handleVariableBoundary(event);
    this.handleSuggestions(event);
  }

  private handleVariableBoundary(event: KeyboardEvent): void {
    const textarea: HTMLTextAreaElement = this.textarea.nativeElement;
    const content = this.form.controls['content'].value;
    const cursorPosition = textarea.selectionStart;

    // Early return for up and down arrows to allow default line moving behavior
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      return; // Allows the default behavior of moving the cursor between lines
    }
    // Regular expression to find variables enclosed in curly braces
    let match;
    while ((match = this.regex.exec(content)) !== null) {
      const start = match.index;
      const end = this.regex.lastIndex;
      // Check if the cursor is within a variable
      if (event.key === 'ArrowLeft' && cursorPosition > start && cursorPosition < end) {
        textarea.setSelectionRange(start, start);

        // Cancel the event to prevent editing inside the variable
        event.preventDefault();
        return; // Exit the loop and function if editing within a variable
      }
      if (event.key !== 'ArrowLeft' && cursorPosition > start && cursorPosition < end) {
        // Move cursor to the end of the variable
        textarea.setSelectionRange(end, end);

        // Cancel the event to prevent editing inside the variable
        event.preventDefault();
        return; // Exit the loop and function if editing within a variable
      }
    }
  }

  updateSuggestionsPosition(): void {
    const coords = this._getCursorCoords();
    this.suggestionsTop = coords.top + coords.height * 2;
    this.suggestionsLeft = coords.left;
  }

  addSuggestion(suggestion: string): void {
    const index = this.getCursorPosition();
    const newValue =
      this.form.controls['content'].value.slice(0, index - 1) +
      suggestion +
      this.form.controls['content'].value.slice(index);
    this.form.controls['content'].setValue(newValue);
    this.showSuggestions = false;
    this.emitTemplateContent();
  }

  emitTemplateContent(): void {
    this.templateContentChange.emit(this.form.controls['content'].value);
  }
  private handleSuggestions(event: KeyboardEvent): void {
    const index = this.getCursorPosition();
    const lastTypedPlus =
      event.key === '+' ||
      (event.key === 'Shift' && this.form.controls['content'].value.slice(0, index).at(-1) === '+');
    this.showSuggestions = lastTypedPlus;
    if (this.showSuggestions) {
      this.updateSuggestionsPosition();
    }
  }

  private _getCursorCoords(): { top: number; left: number; height: number } {
    const textareaElement = this.textarea.nativeElement as HTMLTextAreaElement;
    const textareaRect = textareaElement.getBoundingClientRect();
    const computedStyle = getComputedStyle(textareaElement);
    const fontSize = parseFloat(computedStyle.fontSize);

    const textBeforeCursor = this.form.controls['content'].value.substring(0, textareaElement.selectionStart);
    // textBeforeCursor += '\u200B'; // Add a zero-width space

    // Calculate the width of a character to determine how many characters fit in one line
    const charSize = this._getTextWidth('A', fontSize.toString());
    const charWidth = charSize.width;
    const rowLimitLength = Math.ceil(textareaRect.width / charWidth) - 25;

    // Count both explicit new lines and wrapping lines
    const rowsSeparatedByNewLine = textBeforeCursor.split('\n');
    // Mimic the textarea using a hidden div
    const mirrorDiv = document.createElement('div');
    mirrorDiv.style.cssText = computedStyle.cssText;
    mirrorDiv.style.overflow = 'auto';
    mirrorDiv.style.whiteSpace = 'pre-wrap';
    mirrorDiv.style.overflowWrap = 'break-word'; // Add this line
    mirrorDiv.style.width = this.textarea.nativeElement.clientWidth - 20 + 'px';
    mirrorDiv.style.position = 'absolute';
    mirrorDiv.style.visibility = 'hidden';
    mirrorDiv.textContent = textBeforeCursor;
    document.body.appendChild(mirrorDiv);

    // Create a span at the cursor's position in the mirror div
    const cursorSpan = document.createElement('span');
    // cursorSpan.textContent = '\u200B'; // Use a zero-width space
    mirrorDiv.appendChild(cursorSpan);
    // check if string before is greater then row if it is get the module as position in current row
    const positionInCurrentRow =
      rowsSeparatedByNewLine[rowsSeparatedByNewLine.length - 1].length < rowLimitLength
        ? rowsSeparatedByNewLine[rowsSeparatedByNewLine.length - 1].length
        : rowsSeparatedByNewLine[rowsSeparatedByNewLine.length - 1].length % rowLimitLength;
    // Cleanup
    const top = mirrorDiv.clientHeight;
    document.body.removeChild(mirrorDiv);
    const cursorLeftInside = positionInCurrentRow * charSize.width + parseFloat(computedStyle.paddingLeft);
    return {
      top: top + 50,
      left: cursorLeftInside,
      height: charSize.height,
    };
  }

  private _getTextWidth(text: string, font: string): { width: number; height: number } {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    // Set the font on the context
    context.font = font + 'px';

    // Measure the text width
    const metrics = context.measureText(text);
    const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

    return { width: metrics.width, height: height };
  }
}

import { Component, EventEmitter, Input, Output, inject } from '@angular/core';

import { IPublicationProduct, IPublicationProductSetUp } from '@app/core/interface/publication-management.interface';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { ProductSetupDialogComponent } from '../product-setup-dialog/product-setup-dialog.component';
import { PublicationManagementService } from '@app/core/service/publication-management.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';

@Component({
  selector: 'app-product-publication-card',
  templateUrl: './product-publication-card.component.html',
  styleUrl: './product-publication-card.component.scss',
})
export class ProductPublicationCardComponent {
  readonly _dialog = inject(MatDialog);
  readonly _publicationManagementService = inject(PublicationManagementService);
  readonly _publicationManagementFacade = inject(PublicationManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  @Input() product!: IPublicationProduct;
  @Output() refresh = new EventEmitter<boolean>();
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';

  openSetupProduct(product: IPublicationProduct): void {
    const dialogRef = this._dialog.open(ProductSetupDialogComponent, {
      width: '1160px',
      height: '680px',
      panelClass: ['padding-0', 'overflow-hidden-dialog'],
      data: { product: product },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh.emit(true);
      }
    });
  }

  manageProductVisibility(uuid: string, is_display_on_brand_page: boolean): void {
    const formData = new FormData();
    formData.append('is_display_on_brand_page', String(!is_display_on_brand_page));
    this._publicationManagementService.manageProductVisibility$(uuid, formData).subscribe({
      next: this.successManageProductVisibility.bind(this),
      error: this._error.bind(this),
    });
  }

  managePrivacySetting(uuid: string, field: keyof IPublicationProductSetUp, currentValue: boolean): void {
    const updatedValue = !currentValue;
    const updateData: Partial<IPublicationProductSetUp> = { [field]: updatedValue };

    this._publicationManagementFacade.updatePublicationProductPartially$(uuid, updateData).subscribe({
      next: () => this._handlePrivacySuccess(field),
      error: this._error.bind(this),
    });
  }
  /////////// Private methods ////////////

  private successManageProductVisibility(): void {
    this.refresh.emit(true);
    this._snackbarService.openTypeSnackbar('Visibility updated successfully', NotificationType.success);
  }

  private readonly _successMessages: Record<string, string> = {
    is_description_private: 'Description updated successfully',
    is_reference_private: 'Reference No. updated successfully',
  };

  private _handlePrivacySuccess(field: string): void {
    this.refresh.emit(true);
    const message = this._successMessages[field] || 'Updated successfully'; // Fallback message
    this._snackbarService.openTypeSnackbar(message, NotificationType.success);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}

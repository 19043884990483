import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  IActivateDeactivateSupplierResponse,
  IDeleteSupplier,
  IDeleteSupplierContact,
  IFactory,
  IRecentSuppliersResponse,
  ISupplier,
  ISupplierContact,
  ISupplierContactsResponse,
  ISupplierEmailsResponse,
  ISupplierResponse,
  ISuppliersResponse,
} from '../interface/suppliers.interface';
import { IsDocMediaForPublicationResponse, IsLinkForPublicationResponse } from '../interface/products.interface';

import { Breadcrumb } from '../interface/header.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SuppliersService {
  private apiUrl = environment.api;
  public supplierDetailsName: Subject<Breadcrumb> = new Subject();

  supplierDetailsActions: Subject<string> = new Subject();

  changesBehaviour = new BehaviorSubject(false);

  constructor(private readonly _http: HttpClient) {}

  getSuppliers$(onlyActive?: boolean): Observable<ISuppliersResponse> {
    if (onlyActive) {
      const params = new HttpParams().set('is_active', true);
      return this._http.get<ISuppliersResponse>(`${this.apiUrl}/suppliers/`, { params: params });
    }
    return this._http.get<ISuppliersResponse>(`${this.apiUrl}/suppliers/`);
  }

  getRecentSuppliers$(): Observable<IRecentSuppliersResponse> {
    return this._http.get<IRecentSuppliersResponse>(`${this.apiUrl}/suppliers/action_logs/`);
  }

  createSupplier$(data: FormData): Observable<ISupplier> {
    return this._http.post<ISupplier>(`${this.apiUrl}/suppliers/`, data);
  }

  getSupplierList$(ordering?: string, limit?: number): Observable<ISuppliersResponse> {
    if (ordering && limit) {
      const params = new HttpParams().set('ordering', ordering).set('size', limit);
      return this._http.get<ISuppliersResponse>(`${this.apiUrl}/suppliers/`, { params: params });
    }
    return this._http.get<ISuppliersResponse>(`${this.apiUrl}/suppliers/`);
  }

  getSupplierTableList$(ordering?: string, limit?: number): Observable<ISuppliersResponse> {
    if (ordering && limit) {
      const params = new HttpParams().set('ordering', ordering).set('size', limit);
      return this._http.get<ISuppliersResponse>(`${this.apiUrl}/suppliers-list/`, { params: params });
    }
    return this._http.get<ISuppliersResponse>(`${this.apiUrl}/suppliers-list/`);
  }

  deleteSupplier$(uuid: string): Observable<IDeleteSupplier> {
    return this._http.delete<IDeleteSupplier>(`${this.apiUrl}/suppliers/${uuid}/`);
  }

  getSupplier$(uuid: string): Observable<ISupplierResponse> {
    return this._http.get<ISupplierResponse>(`${this.apiUrl}/suppliers/${uuid}/`);
  }

  getSupplierManufactories$(uuid: string): Observable<IFactory[]> {
    return this._http.get<IFactory[]>(`${this.apiUrl}/suppliers/${uuid}/manufactories/`);
  }

  getSupplierContacts$(supplier_uuid: string): Observable<ISupplierContactsResponse> {
    return this._http.get<ISupplierContactsResponse>(`${this.apiUrl}/suppliers/${supplier_uuid}/contacts/`);
  }

  createSupplierContact$(supplier_uuid: string, data: FormData): Observable<ISupplierContact> {
    return this._http.post<ISupplierContact>(`${this.apiUrl}/suppliers/${supplier_uuid}/contacts/`, data);
  }

  updateSupplierContact$(supplier_uuid: string, uuid: string, data: FormData): Observable<ISupplierContact> {
    return this._http.patch<ISupplierContact>(`${this.apiUrl}/suppliers/${supplier_uuid}/contacts/${uuid}/`, data);
  }

  deleteSupplierContact$(supplier_uuid: string, uuid: string): Observable<IDeleteSupplierContact> {
    return this._http.delete<IDeleteSupplierContact>(`${this.apiUrl}/suppliers/${supplier_uuid}/contacts/${uuid}/`);
  }

  updateSupplier$(supplier_uuid: string, data: FormData): Observable<ISupplier> {
    return this._http.patch<ISupplier>(`${this.apiUrl}/suppliers/${supplier_uuid}/`, data);
  }

  activateDeactivateSupplier$(uuid: string, data: FormData): Observable<IActivateDeactivateSupplierResponse> {
    return this._http.patch<IActivateDeactivateSupplierResponse>(`${this.apiUrl}/suppliers/${uuid}/active/`, data);
  }

  updateSupplierDocuments$(
    supplier_uuid: string,
    document_uuid: string,
    data: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._http.patch<IsDocMediaForPublicationResponse>(
      `${this.apiUrl}/suppliers/${supplier_uuid}/documents/${document_uuid}/`,
      data
    );
  }

  updateSupplierMedias$(
    supplier_uuid: string,
    media_uuid: string,
    data: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._http.patch<IsDocMediaForPublicationResponse>(
      `${this.apiUrl}/suppliers/${supplier_uuid}/medias/${media_uuid}/`,
      data
    );
  }

  updateSupplierLinks$(
    supplier_uuid: string,
    link_uuid: string,
    data: FormData
  ): Observable<IsLinkForPublicationResponse> {
    return this._http.patch<IsLinkForPublicationResponse>(
      `${this.apiUrl}/suppliers/${supplier_uuid}/links/${link_uuid}/`,
      data
    );
  }

  getAllSupplierEmails$(): Observable<ISupplierEmailsResponse[]> {
    return this._http.get<ISupplierEmailsResponse[]>(`${this.apiUrl}/suppliers/emails/`);
  }
}

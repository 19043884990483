import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';

import {
  ILanguageManagementSupplyChainTotalCount,
  ISupplyChain,
} from '@app/core/interface/language-management.interface';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTabGroup } from '@angular/material/tabs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { NotificationType } from '@app/core/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-language-management',
  templateUrl: './language-management.component.html',
  styleUrl: './language-management.component.scss',
  providers: [LanguageManagementFacade],
})
export class LanguageManagementComponent implements OnInit, OnDestroy {
  opened = false;
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  @ViewChild('tabs') tabs!: MatTabGroup;
  @Input() selectedChainUuid!: string | null;

  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _languageManagementService = inject(LanguageManagementService);
  readonly _snackbarService = inject(SnackbarService);

  selectedToggle: string = 'missing';
  supplyChains: ISupplyChain[] = [];
  selectedSupplyChain: ISupplyChain = { name: '', uuid: '' };
  isFullyTranslated = false;
  total_translated_fields: number = 0;
  total_fields: number = 0;
  filteredSupplyChains: ISupplyChain[] = [];
  searchTerm: string = '';
  private skipPreselect = false;
  private readonly STORAGE_KEY = 'selectedChainUuid';
  private readonly TAB_STORAGE_KEY = 'selectedTabIndex';
  private readonly TOGGLE_STORAGE_KEY = 'selectedToggle';
  private refreshSubscription!: Subscription;

  ngOnInit(): void {
    this.selectedChainUuid = this.selectedChainUuid || this.getLocalStorage(this.STORAGE_KEY);
    if (this.selectedChainUuid) {
      this._updateSupplyChainFilters(this.selectedChainUuid);
    }
    this.selectedToggle = this.getLocalStorage(this.TOGGLE_STORAGE_KEY) || this.selectedToggle;
    this._languageManagementFacade.getLanguageManagementSupplyChainsFilter$().subscribe(response => {
      this.supplyChains = response.results;
      if (this.selectedChainUuid && !this.skipPreselect) {
        this._preselectSupplyChain();
      }
      this.skipPreselect = false;
      this.filteredSupplyChains = [...this.supplyChains];
    });
    this.subscribeToRefresh();
  }

  filterSupplyChains(): void {
    if (!this.searchTerm) {
      this.filteredSupplyChains = [...this.supplyChains];
    } else {
      this.filteredSupplyChains = this.supplyChains.filter(chain =>
        chain.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  ngAfterViewInit(): void {
    const storedTabIndex = localStorage.getItem(this.TAB_STORAGE_KEY);
    if (storedTabIndex) {
      this.tabs.selectedIndex = +storedTabIndex;
    }
  }

  onToggleChange(toggleState: string): void {
    this.selectedToggle = toggleState;
    localStorage.setItem(this.TOGGLE_STORAGE_KEY, toggleState);
  }

  onSupplyChainChange(supplyChain: ISupplyChain | null, resetTabIndex = false): void {
    if (resetTabIndex) this.tabs.selectedIndex = 0;
    this.selectedChainUuid = supplyChain?.uuid || null;
    this.selectedSupplyChain = supplyChain || { name: '', uuid: '' };
    this.searchTerm = supplyChain?.name || '';
    this.getChainTRanslationTotalCount(this.selectedSupplyChain.uuid);
    this._updateSupplyChainFilters(this.selectedChainUuid || '');
    supplyChain?.uuid
      ? this.setLocalStorage(this.STORAGE_KEY, supplyChain.uuid)
      : this.removeLocalStorage(this.STORAGE_KEY);
  }

  subscribeToRefresh(): void {
    this.refreshSubscription = this._languageManagementService.refreshTotalCounter$.subscribe(refresh => {
      if (refresh && this.selectedSupplyChain.uuid) {
        this.getChainTRanslationTotalCount(this.selectedSupplyChain.uuid);
      }
    });
  }
  getChainTRanslationTotalCount(uuid: string): void {
    this._languageManagementFacade.getChainTRanslationTotalCount$(uuid).subscribe({
      next: this.getChainTRanslationTotalCountSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  getChainTRanslationTotalCountSuccess(data: ILanguageManagementSupplyChainTotalCount): void {
    this._languageManagementService.triggerTotalCounterRefresh(false);
    this.total_fields = data.total_fields;
    this.total_translated_fields = data.total_translated_fields;
    this.isFullyTranslated = this.total_fields === this.total_translated_fields;
  }

  displayFn(chain: ISupplyChain | null): string {
    return chain?.name ?? '';
  }

  onOpenOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  onClearSelection(trigger: MatAutocompleteTrigger): void {
    this.selectedChainUuid = null;
    this.selectedSupplyChain = { name: '', uuid: '' };
    this.searchTerm = '';
    this._languageManagementService.supplyChainUuidFilter.set('');
    this._languageManagementService.supplyChainParamsFilter.set('');
    localStorage.removeItem(this.STORAGE_KEY);
    this.tabs.selectedIndex = 0;
    this.skipPreselect = true;
    this.ngOnInit();
    trigger.closePanel();
    this._languageManagementService.triggerTotalCounterRefresh(false);
  }

  ngOnDestroy(): void {
    localStorage.removeItem(this.STORAGE_KEY);
    localStorage.removeItem(this.TAB_STORAGE_KEY);
    localStorage.removeItem(this.TOGGLE_STORAGE_KEY);
    this._updateSupplyChainFilters();
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  onTabChange(index: number): void {
    const previousTabIndex = localStorage.getItem('selectedTabIndex');
    if (previousTabIndex && +previousTabIndex === index) {
      return;
    }
    localStorage.setItem('selectedTabIndex', index.toString());
    this.selectedToggle = 'missing';
    localStorage.setItem(this.TOGGLE_STORAGE_KEY, this.selectedToggle);
  }
  //////////// Private method ////////////

  private _updateSupplyChainFilters(uuid: string = ''): void {
    this._languageManagementService.supplyChainUuidFilter.set(uuid);
    this._languageManagementService.supplyChainParamsFilter.set(uuid ? `?supplychain_uuid=` : '');
  }

  private _preselectSupplyChain(): void {
    const matchedChain = this.supplyChains.find(chain => chain.uuid === this.selectedChainUuid);
    if (matchedChain) {
      this.onSupplyChainChange(matchedChain);
    }
  }
  private setLocalStorage(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  private getLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  private removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable, Subscription } from 'rxjs';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagementCertificate,
  ILanguageManagementCertificatesResponse,
} from '@app/core/interface/language-management.interface';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-certificate-translations',
  templateUrl: './certificate-translations.component.html',
  styleUrl: './certificate-translations.component.scss',
  providers: [LanguageManagementFacade],
})
export class CertificateTranslationsComponent implements OnInit, AfterViewInit, OnDestroy {
  isMissing: boolean = true;
  hasSupplyChainFIlter = false;
  missingTranslations!: number | undefined | null;
  fullyTranslated!: number | undefined | null;
  certificates!: ILanguageManagementCertificate[];
  cardDataSource = new MatTableDataSource<ILanguageManagementCertificate>();
  certificatesObservable$!: Observable<ILanguageManagementCertificate[]>;
  private refreshSubscription!: Subscription;

  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _languageManagementService = inject(LanguageManagementService);
  readonly _snackbarService = inject(SnackbarService);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() toggleChanged = new EventEmitter<string>();
  @Input() initialToggleState: string = 'missing';
  @Input() selectedChainUuid: string | null = null;

  ngOnInit(): void {
    this.isMissing = this.initialToggleState === 'missing';
    this.getLanguageManagementCertificates(!this.isMissing);
    this.hasSupplyChainFIlter = this.selectedChainUuid !== null;
    this.subscribeToRefresh();
  }

  subscribeToRefresh(): void {
    this.refreshSubscription = this._languageManagementService.refreshCertificateChain$.subscribe(refresh => {
      if (refresh) {
        this.getLanguageManagementCertificates(!this.isMissing);
      }
    });
  }
  ngAfterViewInit() {
    this.paginator.pageSize = 8;
    this.cardDataSource.paginator = this.paginator;
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.isMissing = event.value === 'missing';
    this.toggleChanged.emit(event.value);
    if (event.value === 'fully') {
      this.getLanguageManagementCertificates(true);
    } else {
      this.getLanguageManagementCertificates(false);
    }
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource);
  }

  getLanguageManagementCertificates(isFullyTranslated: boolean): void {
    this._languageManagementFacade.getLanguageManagementCertificates$(isFullyTranslated).subscribe({
      next: this._getLanguageManagementCertificatesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  //////////////////////////////////////////////////Private methods///////////////////////////////////////////////

  private _getLanguageManagementCertificatesSuccess(data: ILanguageManagementCertificatesResponse): void {
    if (data) {
      this.certificates = data.results;
      this.missingTranslations = data?.certificates_missing_translations;
      this.fullyTranslated = data?.certificates_fully_translated;
      this.cardDataSource.data = data.results;
      this.certificatesObservable$ = this.cardDataSource.connect();
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}

<div class="medias-wrap">
  <!-- DOCUMENTS  -->
  <div class="row-wrap">
    <div class="header-holder">
      <h4 class="row-title section-header">
        {{ 'Your Recent Documents' | translate }}
      </h4>
      <div class="button-holder wrp-doc">
        <app-rounded-button
          color="tetriary-link"
          class="doc-btn-1"
          [button_text]="'See all documents' | translate"
          [icon_category]="'files'"
          [icon_name]="'document-text'"
          [size]="'md'"
          [icon_right]="true"
          (click)="openFileList('documents', 'documents')"
          [disabled]="!documents?.length"
          test-id="button-useful_data-see_all_documents"
        >
        </app-rounded-button>
        <app-rounded-button
          color="turquoise"
          class="doc-btn-2"
          [button_text]="'Upload Document' | translate"
          [icon_category]="'essential'"
          [icon_name]="'upload'"
          [size]="'md'"
          [icon_right]="true"
          (click)="addFile('documents', false)"
          test-id="button-useful_data-upload_document"
        >
        </app-rounded-button>
      </div>
    </div>
    <div class="items-wrap" *ngIf="documents?.length; else no_documents">
      <div *ngFor="let document of documents" class="item-card align-items-center">
        <div class="document-icon">
          <app-custom-icon [icon_category]="'files'" [icon_name]="'document-text-white'"> </app-custom-icon>
        </div>

        <div class="document-holder">
          <div class="title-icon-holder">
            <div class="media-title-icon align-items-center">
              <p class="section-header card-name">
                {{ document.name }}
              </p>
            </div>

            <div class="icon-holder justify-content-center align-items-center">
              <div class="edit-icon">
                <div
                  [matMenuTriggerFor]="documetOptions"
                  class="action d-flex cursor-pointer justify-content-center align-items-center"
                >
                  <app-custom-icon
                    matTooltip="{{ 'View All Actions' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    [matTooltipPosition]="'above'"
                    [icon_category]="'settings'"
                    [icon_name]="'more'"
                  >
                  </app-custom-icon>
                </div>
                <mat-menu class="custom-menu action-menu" #documetOptions="matMenu" xPosition="before">
                  <button
                    test-id="button-useful_data-open_document"
                    class="custom-item"
                    mat-menu-item
                    (click)="openFileList('documents', 'documents', document)"
                  >
                    <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
                    <p class="name m-0">
                      {{ 'Open' | translate }}
                    </p>
                  </button>
                  <button
                    test-id="button-useful_data-edit_document"
                    class="custom-item"
                    mat-menu-item
                    (click)="addFile('documents', true, document)"
                  >
                    <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"></app-custom-icon>
                    <p class="name m-0">
                      {{ 'Edit' | translate }}
                    </p>
                  </button>
                  <button
                    test-id="button-useful_data-remove_document"
                    class="custom-item"
                    mat-menu-item
                    (click)="openConfirmationDialog(document.name, 'documents', document.uuid)"
                  >
                    <app-custom-icon
                      [icon_category]="'essential'"
                      [icon_name]="'trash-red'"
                      class="trash-red"
                    ></app-custom-icon>
                    <p class="name m-0 color-red">
                      {{ 'Remove' | translate }}
                    </p>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MEDIAS -->
  <div class="row-wrap">
    <div class="header-holder">
      <h4 class="row-title section-header">{{ 'Your Recent Media' | translate }}</h4>
      <div class="button-holder">
        <app-rounded-button
          color="tetriary-link"
          [button_text]="'See all images' | translate"
          [icon_category]="'essential'"
          [icon_name]="'image'"
          [size]="'md'"
          [icon_right]="true"
          (click)="!disableViewImages && openFileList('images', 'medias')"
          [disabled]="disableViewImages"
          test-id="button-useful_data-see_all_images"
        >
        </app-rounded-button>

        <app-rounded-button
          color="tetriary-link"
          [button_text]="'See all videos' | translate"
          [icon_category]="'essential'"
          [icon_name]="'video-2'"
          [size]="'md'"
          [icon_right]="true"
          (click)="!diableViewVideos && openFileList('videos', 'medias')"
          [disabled]="diableViewVideos"
          test-id="button-useful_data-see_all_videos"
        >
        </app-rounded-button>
        <app-rounded-button
          color="turquoise"
          [button_text]="'Upload Media' | translate"
          [icon_category]="'essential'"
          [icon_name]="'upload'"
          [size]="'md'"
          [icon_right]="true"
          (click)="addFile('medias', false)"
          test-id="button-useful_data-upload_media"
        >
        </app-rounded-button>
      </div>
    </div>
    <div class="items-wrap" *ngIf="medias?.length; else no_medias">
      <div class="item-card align-items-center" *ngFor="let media of medias">
        <div class="document-icon">
          <img *ngIf="media.mimetype.indexOf('image') > -1" class="document-icon" src="{{ media.file }}" alt="" />
          <video class="document-icon" *ngIf="media.mimetype.indexOf('video') > -1" src="{{ media.file }}"></video>
        </div>
        <div class="document-holder">
          <div class="title-icon-holder">
            <div class="media-title-icon align-items-center">
              <app-custom-icon
                *ngIf="media.mimetype.indexOf('image') > -1"
                class="custom-icon"
                [icon_category]="'essential'"
                [icon_name]="'image-blue'"
              >
              </app-custom-icon>
              <app-custom-icon
                *ngIf="media.mimetype.indexOf('video') > -1"
                class="custom-icon"
                [icon_category]="'essential'"
                [icon_name]="'video-2'"
              >
              </app-custom-icon>
              <p class="section-header card-name">
                {{ media.name }}
              </p>
            </div>

            <div class="icon-holder">
              <div
                [matMenuTriggerFor]="mediaOptions"
                class="action cursor-pointer d-flex justify-content-center align-items-center"
              >
                <app-custom-icon
                  matTooltip="{{ 'View All Actions' | translate }}"
                  matTooltipClass="custom-tooltip-center"
                  [matTooltipPosition]="'above'"
                  [icon_category]="'settings'"
                  [icon_name]="'more'"
                >
                </app-custom-icon>
              </div>

              <mat-menu class="custom-menu action-menu" #mediaOptions="matMenu" xPosition="before">
                <button class="custom-item" mat-menu-item (click)="openFileList('medias', 'medias', media)">
                  <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
                  <p class="name m-0">
                    {{ 'Open' | translate }}
                  </p>
                </button>
                <button class="custom-item" mat-menu-item (click)="addFile('medias', true, media)">
                  <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"></app-custom-icon>
                  <p class="name m-0">
                    {{ 'Edit' | translate }}
                  </p>
                </button>
                <button
                  class="custom-item"
                  mat-menu-item
                  (click)="openConfirmationDialog(media.name, 'medias', media.uuid)"
                >
                  <app-custom-icon
                    [icon_category]="'essential'"
                    [icon_name]="'trash-red'"
                    class="trash-red"
                  ></app-custom-icon>
                  <p class="name m-0 color-red">
                    {{ 'Remove' | translate }}
                  </p>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LINKS  -->
  </div>
  <div class="row-wrap">
    <div class="header-holder">
      <h4 class="row-title section-header">{{ 'Your Recent Links' | translate }}</h4>
      <div class="button-holder">
        <app-rounded-button
          color="tetriary-link"
          [button_text]="'See all links' | translate"
          [icon_category]="'essential'"
          [icon_name]="'link'"
          [size]="'md'"
          [icon_right]="true"
          (click)="openFileList('links', 'links')"
          [disabled]="!links?.length"
          test-id="button-useful_data-see_all_links"
        >
        </app-rounded-button>
        <app-rounded-button
          color="turquoise"
          [button_text]="'Add Links' | translate"
          [icon_category]="'essential'"
          [icon_name]="'add'"
          [size]="'md'"
          [icon_right]="true"
          (click)="addFile('links', false)"
          test-id="button-useful_data-add_links"
        >
        </app-rounded-button>
      </div>
    </div>
    <div class="items-wrap" *ngIf="links?.length; else no_links">
      <div *ngFor="let site of links" class="item-card-link">
        <div class="link-heder-holder justify-content-center">
          <div class="link-holder align-items-center">
            <a [href]="site.url" [matTooltip]="site.url" aria-label="Visit page" target="_blank">
              <app-custom-icon
                class="custom-icon d-flex align-items-center justify-content-end"
                [icon_category]="'essential'"
                [icon_name]="'link'"
              >
              </app-custom-icon>
            </a>
            <p class="section-header card-name">
              {{ site.name }}
            </p>
          </div>
        </div>
        <div class="icon-holder">
          <div
            [matMenuTriggerFor]="mediaOptions"
            class="action cursor-pointer d-flex justify-content-center align-items-center"
          >
            <app-custom-icon
              matTooltip="{{ 'View All Actions' | translate }}"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
              [icon_category]="'settings'"
              [icon_name]="'more'"
            >
            </app-custom-icon>
          </div>

          <mat-menu class="custom-menu action-menu" #mediaOptions="matMenu" xPosition="before">
            <button class="custom-item" mat-menu-item (click)="goToLink(site.url)">
              <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
              <p class="name m-0">
                {{ 'Open' | translate }}
              </p>
            </button>

            <button class="custom-item" mat-menu-item (click)="addFile('links', true, site)">
              <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"></app-custom-icon>
              <p class="name m-0">
                {{ 'Edit' | translate }}
              </p>
            </button>
            <button class="custom-item" mat-menu-item (click)="openConfirmationDialog(site.url, 'links', site.uuid)">
              <app-custom-icon
                [icon_category]="'essential'"
                [icon_name]="'trash-red'"
                class="trash-red"
              ></app-custom-icon>
              <p class="name m-0 color-red">
                {{ 'Remove' | translate }}
              </p>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #no_documents>
  <div class="no-wrap">
    <div class="not-set-bg">
      <app-custom-icon [icon_category]="'files'" [icon_name]="'document-text-white'"> </app-custom-icon>
    </div>
    <span class="not-set site">
      {{ 'There are no documents uploaded yet.' | translate }}
    </span>
  </div>
</ng-template>
<ng-template #no_medias>
  <div class="no-wrap">
    <div class="not-set-bg">
      <app-custom-icon class="custom-icon img-ic" [icon_category]="'essential'" [icon_name]="'image-blue'">
      </app-custom-icon>
      <app-custom-icon class="custom-icon vid-ic" [icon_category]="'essential'" [icon_name]="'video-2'">
      </app-custom-icon>
    </div>
    <span class="not-set site">
      {{ 'There is no media uploaded yet.' | translate }}
    </span>
  </div>
</ng-template>
<ng-template #no_links>
  <div class="no-wrap">
    <div class="not-set-bg">
      <app-custom-icon class="custom-icon" [icon_category]="'essential'" [icon_name]="'link'"> </app-custom-icon>
    </div>
    <span class="not-set site">
      {{ 'There are no links added yet.' | translate }}
    </span>
  </div>
</ng-template>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPublicationSupplier } from '@app/core/interface/publication-management.interface';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupplierSetupDialogComponent } from '@app/module/publication-management/publication-setup/suppliers-publication/supplier-setup-dialog/supplier-setup-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-supplier-publication-card',
  templateUrl: './supplier-publication-card.component.html',
  styleUrl: './supplier-publication-card.component.scss',
})
export class SupplierPublicationCardComponent {
  @Input() supplier?: IPublicationSupplier;
  @Output() refresh = new EventEmitter<void>();
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';

  constructor(
    private _dialog: MatDialog,
    private _publicationManagementFacade: PublicationManagementFacade,
    private _snackbarService: SnackbarService
  ) {}

  openSetupSupplier() {
    const dialogRef = this._dialog.open(SupplierSetupDialogComponent, {
      width: '1160px',
      height: '800px',
      panelClass: 'top-padding-0',
      data: {
        title: 'Supplier Setup',
        supplierUuid: this.supplier?.uuid,
        related_publicated_chain: this.supplier?.related_publicated_chain,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refresh.emit();
      }
    });
  }

  onConfidentialChange(event: MatCheckboxChange): void {
    const is_private = event.checked;

    if (this.supplier && this.supplier.uuid) {
      this._publicationManagementFacade.updateConfidentialSupplier$(this.supplier.uuid, is_private).subscribe({
        next: this._updateConfidentialSupplierSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _updateConfidentialSupplierSuccess(): void {
    this._snackbarService.openTypeSnackbar(
      `All supplier objects have been set to ${this.supplier?.is_hidden ? 'private' : 'public'}.`,
      NotificationType.success
    );
    this.refresh.emit();
  }

  onToggleChange(field: 'is_name_private' | 'is_description_private', event: MatSlideToggleChange): void {
    const value = !event.checked;
    this.updateNameDescriptionPublicationSupplier(field, value);
  }

  updateNameDescriptionPublicationSupplier(field: 'is_name_private' | 'is_description_private', value: boolean): void {
    if (this.supplier && this.supplier.uuid) {
      const updateData = { [field]: value };

      this._publicationManagementFacade
        .updateNameDescriptionPublicationSupplier$(this.supplier.uuid, updateData)
        .subscribe({
          next: this._updateSupplierSuccess.bind(this),
          error: this._error.bind(this),
        });
    }
  }

  private _updateSupplierSuccess(): void {
    this._snackbarService.openTypeSnackbar(`Supplier updated successfully`, NotificationType.success);
    this.refresh.emit();
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}

<div class="card-holder d-flex flex-column">
  <div class="wrapper d-flex align-items-center justify-content-between">
    <div class="info-wrapp d-flex align-items-center">
      <p class="m-0 chain-name">{{ supply_chain.name }}</p>
    </div>
    <app-custom-icon
      class="cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
      (click)="onManageTranslations(supply_chain)"
    ></app-custom-icon>
  </div>
  <div class="progress" [ngClass]="isTranslationComplete(supply_chain) ? 'fully' : 'missing'">
    <p class="m-0">{{ 'Translations progress:' }}</p>
    <span class="txt">{{ supply_chain?.translated_fields + '/' + supply_chain?.total_fields }}</span>
  </div>
</div>

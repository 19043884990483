<div class="product-card d-flex flex-column">
  <div class="indicator" [ngClass]="product.is_published ? 'published' : 'non-published'"></div>
  <div class="card-header d-flex justify-content-between">
    <div class="header-info d-flex align-items-center">
      <img class="product-logo" [src]="product.image ? product.image : imgPlaceholder" alt="" />
      <p class="m-0 product-name">{{ product.name }}</p>
    </div>
    <app-custom-icon
      [icon_category]="'settings'"
      [icon_name]="'setting-4'"
      class="cursor-pointer"
      matTooltip="{{ 'Product visibility setup' | translate }}"
      matTooltipClass="custom-tooltip-center"
      matTooltipPosition="above"
      (click)="openSetupProduct(product)"
    ></app-custom-icon>
  </div>
  <div class="card-body d-flex flex-column">
    <div class="toggle-wrapper d-flex align-items-center justify-content-between">
      <div class="label">
        {{ 'Visible on respect-code' | translate }}
      </div>
      <mat-slide-toggle
        class="small-slide-toggle"
        [checked]="product.is_display_on_brand_page && product.is_published"
        [disabled]="!product.is_published"
        matTooltip="{{
          !product.is_published
            ? 'This product doesn’t have published chain and it cannot be switched to visible'
            : ('' | translate)
        }}"
        matTooltipClass="custom-tooltip-center"
        matTooltipPosition="above"
        (change)="product.is_published && manageProductVisibility(product.uuid, product.is_display_on_brand_page)"
      ></mat-slide-toggle>
    </div>
    <div class="divider"></div>
    <div class="product-action d-flex flex-column">
      <div class="toggle-wrapper d-flex align-items-center justify-content-between">
        <div class="label">
          {{ 'Product description' | translate }}
        </div>
        <mat-slide-toggle
          class="small-slide-toggle"
          [checked]="!product.is_description_private"
          (change)="managePrivacySetting(product.uuid, 'is_description_private', product.is_description_private)"
        ></mat-slide-toggle>
      </div>
      <div class="toggle-wrapper d-flex align-items-center justify-content-between">
        <div class="label">
          {{ 'Product reference no.' | translate }}
        </div>
        <mat-slide-toggle
          class="small-slide-toggle"
          [checked]="!product.is_reference_private"
          (change)="managePrivacySetting(product.uuid, 'is_reference_private', product.is_reference_private)"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

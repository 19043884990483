<div class="background">
  <div class="supplier-grid-view">
    <mat-card class="supplier-list-wraper">
      <div class="header-wraper">
        <mat-card class="options-header">
          <div class="supplier-heading">
            <p [routerLink]="['/dashboard/supplier']">
              {{ 'Suppliers' | translate }}
            </p>
            <div class="filter">
              <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
                <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
                <input
                  test-id="input-supplier_list-quick_search"
                  matInput
                  (keyup)="applyFilter($event)"
                  placeholder="Ex. Basel"
                  #input
                />
                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                ></app-custom-icon>
              </mat-form-field>
            </div>
          </div>

          <div class="toggle-button-wraper">
            <div class="toggle-group">
              <mat-button-toggle-group #group="matButtonToggleGroup" (change)="viewChange($event.value)">
                <div class="table-view toggle">
                  <mat-button-toggle
                    matTooltip="{{ 'List View' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    [matTooltipPosition]="'above'"
                    value="tableView"
                    aria-label="Table View"
                    [checked]="true"
                    test-id="button_toggle-supplier_list-list_view"
                  >
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'menu-3lines'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
                <div class="card-view toggle">
                  <mat-button-toggle
                    matTooltip="{{ 'Grid View' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    [matTooltipPosition]="'above'"
                    value="cardView"
                    aria-label="Card View"
                    test-id="button_toggle-supplier_list-grid_view"
                  >
                    <app-custom-icon [icon_category]="'grid'" [icon_name]="'element-3-large'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
              </mat-button-toggle-group>
            </div>
            <div class="create-supplier-btn">
              <app-rounded-button
                color="primary"
                (click)="createSupplierDialog()"
                [button_text]="'Create Supplier'"
                [icon_category]="'product'"
                [icon_name]="'truck'"
                [icon_right]="true"
                [size]="'md'"
                test-id="button-supplier_list-create_supplier"
              >
              </app-rounded-button>
            </div>
          </div>
        </mat-card>
        <div class="check-box d-flex justify-content-end">
          <mat-checkbox
            class="checkbox-primary"
            (change)="showDeactivatedSuppliers($event)"
            [checked]="show_deactivated"
            test-id="checkbox-supplier_list-show_deactivated_suppliers"
          >
            {{ 'show deactivated suppliers' | translate }}
          </mat-checkbox>
        </div>
      </div>

      <div class="main-holder">
        <!-- Card View -->
        <div class="cards-wraper row" *ngIf="group.value === 'cardView'">
          <mat-card
            *ngFor="let supplier of supplierListObservable$ | async"
            class="custom-card col-12 col-md-6 d-flex flex-row"
            [ngClass]="supplier.is_active ? 'active_supplier' : 'inactive'"
          >
            <div class="supplier-details-card">
              <img
                class="preview supplier-details-pic"
                alt="{{ supplier.name }}"
                src="{{ supplier.logo ? supplier.logo : assetPath + 'images/image-placeholder.jpg' }}"
              />
            </div>
            <div class="data-actions-holder">
              <mat-card-title-group class="title-holder d-flex justify-content-between flex-column">
                <mat-card-title class="supplier-title">{{ supplier.name }}</mat-card-title>
                <mat-card-subtitle class="supplier-location">
                  {{ supplier.city ? supplier.city + ',' : '' }} {{ supplier.country }}
                </mat-card-subtitle>
                <div class="chains-actions-holder d-flex justify-content-between align-items-center">
                  <div
                    class="product-chains"
                    [ngClass]="!supplier.chains ? 'product-chains-gray' : 'product-chains-green'"
                  >
                    <span class="dot-green">
                      <div class="div-dot"></div>
                    </span>
                    <span class="number">{{ supplier.chains }}</span>
                    <span class="text">product chains</span>
                  </div>
                  <div class="actions d-flex">
                    <app-custom-icon
                      *ngIf="supplier.is_active"
                      [routerLink]="['/supplier-management/', supplier.uuid]"
                      [icon_category]="'security'"
                      matTooltip="{{ 'View Supplier' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                      [icon_name]="'eye'"
                      class="cursor-pointer"
                    ></app-custom-icon>
                    <app-custom-icon
                      class="cursor-pointer"
                      *ngIf="supplier.is_active"
                      id="deactivate"
                      [icon_category]="'essential'"
                      matTooltip="{{ 'Deactivate Supplier' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                      [icon_name]="'deactivate-red'"
                      (click)="deactivateSupplier(supplier)"
                    ></app-custom-icon>

                    <!-- reactivate icon  -->
                    <app-custom-icon
                      class="cursor-pointer"
                      *ngIf="!supplier.is_active"
                      id="activate"
                      [icon_category]="'essential'"
                      matTooltip="{{ 'Reactivate Supplier' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                      [icon_name]="'activate'"
                      (click)="reactivateSupplier(supplier)"
                    ></app-custom-icon>
                  </div>
                </div>
              </mat-card-title-group>
            </div>
          </mat-card>
        </div>
        <!-- Table View -->
        <div class="table-wraper" *ngIf="group.value === 'tableView'">
          <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Supplier name' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div class="supplier-name" [ngClass]="element.is_active ? 'active' : 'inactive'">
                  {{ element.name }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'City' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div class="supplier-name" [ngClass]="element.is_active ? 'active' : 'inactive'">
                  {{ element.city }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Country' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div class="supplier-name" [ngClass]="element.is_active ? 'active' : 'inactive'">
                  {{ element.country }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="count_unique_product_chains">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Used in chains' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div class="supplier-name" [ngClass]="element.is_active ? 'active' : 'inactive'">
                  <div [ngClass]="!element.chains ? 'product-chains-gray' : 'product-chains-green'">
                    <span class="dot-green">
                      <div class="div-dot"></div>
                    </span>
                    <span class="number">{{ element.chains }}</span>
                    <span class="text">supply chains</span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="actions-header text-center">{{ 'Actions' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="actions-body text-center">
                <div class="icon-holder">
                  <app-custom-icon
                    *ngIf="element.is_active"
                    matTooltip="{{ 'View Supplier' | translate }}"
                    matTooltipClass="custom-tooltip-center centering"
                    matTooltipPosition="above"
                    id="eye"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    [routerLink]="['/supplier-management/', element.uuid]"
                  >
                  </app-custom-icon>

                  <app-custom-icon
                    *ngIf="element.is_active"
                    id="deactivate"
                    [icon_category]="'essential'"
                    matTooltip="{{ 'Deactivate Supplier' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    [icon_name]="'deactivate-red'"
                    (click)="deactivateSupplier(element)"
                  ></app-custom-icon>

                  <!-- reactivate icon  -->
                  <app-custom-icon
                    *ngIf="!element.is_active"
                    id="activate"
                    [icon_category]="'essential'"
                    matTooltip="{{ 'Reactivate Supplier' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    [icon_name]="'activate'"
                    (click)="reactivateSupplier(element)"
                  ></app-custom-icon>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>

      <div class="card-footer">
        <mat-paginator
          test-id="paginator-supplier_list"
          class="custom-paginator"
          showFirstLastButtons
          aria-label="Select page of suppliers"
        >
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>

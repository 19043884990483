<div class="holder d-flex flex-column justify-content-between">
  <div class="table-wraper">
    <table class="custom-table" mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="chain-name">
          {{ 'Supply Chain Name' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }" class="chain-name">
          <div
            class="co2-impact"
            *ngIf="enabledCo2 && element.total_co2_impact.value"
            [ngStyle]="{
              color:
                element.total_co2_impact.value <= 50
                  ? '#1AD9B6'
                  : element.total_co2_impact.value >= 50 && element.total_co2_impact.value <= 100
                    ? '#EEBE13'
                    : '#E20613',
            }"
          >
            co2 {{ element.total_co2_impact.value }} {{ element.total_co2_impact.unit | lowercase }}
            <div
              class="arrow-down ms-2"
              [ngStyle]="{
                'border-top':
                  element.total_co2_impact.value <= 50
                    ? '10px solid #1AD9B6'
                    : element.total_co2_impact.value >= 50 && element.total_co2_impact.value <= 100
                      ? '10px solid #EEBE13'
                      : '10px solid #E20613',
              }"
            ></div>
          </div>
          <div>
            {{ element.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Supply chain ID' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="product-info">
            <div class="logo">
              <img
                class="avatar"
                src="{{
                  element.product?.image
                    ? element.product.image
                    : '../../../../../../assets/images/upload-placeholder-blue.svg'
                }}"
              />
            </div>
            <div class="name">
              <p>{{ element.product?.name }}</p>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Tags' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let tag of element.tags">{{ tag }} </span>
          <span *ngIf="!element.tags.length">/</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Chain Status' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div
            class="chain-status"
            [ngClass]="{ 'in-progress': element.status === 'IN PROGRESS', completed: element.status === 'COMPLETE' }"
          ></div>
          {{ element.status | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="batches">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Batches' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.batches }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header">{{ 'Actions' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="actions-body">
          <div class="action-icons" *ngIf="element.is_active">
            <app-custom-icon
              [icon_category]="'settings'"
              [icon_name]="'more'"
              *ngIf="element.is_active"
              [matMenuTriggerFor]="menu"
              matTooltip="View All Actions"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
            ></app-custom-icon>
          </div>

          <div class="icon-holder">
            <app-custom-icon
              matTooltip="{{ 'Reactivate Chain' | translate }}"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
              [icon_category]="'essential'"
              [icon_name]="'activate'"
              class="activate"
              id="activate"
              *ngIf="!element.is_active"
              (click)="updateChainStatus(false, element)"
            ></app-custom-icon>
          </div>

          <mat-menu
            class="chain-action"
            #menu="matMenu"
            (click)="$event.stopPropagation()"
            #actionMenu="matMenu"
            xPosition="before"
          >
            <app-action-list
              [actionLists]="actionLists"
              [element]="element"
              (operationClicked)="operationClicked($event, element)"
            ></app-action-list>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of contacts"> </mat-paginator>
  </div>
</div>

import { Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagement,
  ILanguageManagementResponse,
  ILanguageManagementSupplyChain,
} from '@app/core/interface/language-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import {
  IChainStep,
  SupplyChainTranslationDialogComponent,
} from '../supply-chain-translation-dialog/supply-chain-translation-dialog.component';
import { NotificationType } from '@app/core/constants';

@Component({
  selector: 'app-supply-chain-translation-card',
  templateUrl: './supply-chain-translation-card.component.html',
  styleUrl: './supply-chain-translation-card.component.scss',
})
export class SupplyChainTranslationCardComponent {
  @Input() supply_chain!: ILanguageManagementSupplyChain;
  @Input() selectedChainUuid!: string | null;
  supplyChainDataFromBackend!: ILanguageManagementResponse;
  supplyChainDataForTranslate: ILanguageManagement[] = [];
  modelName!: string;

  readonly _dialog = inject(MatDialog);
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);

  chainSteps: IChainStep[] = [
    {
      name: 'Step 1',
      translations_progress: 2,
      translations_total: 18,
    },
    {
      name: 'Step 2',
      translations_progress: 2,
      translations_total: 18,
    },
    {
      name: 'Step 3',
      translations_progress: 2,
      translations_total: 18,
    },
  ];

  isTranslationComplete(supply_chain: ILanguageManagementSupplyChain): boolean {
    return supply_chain.translated_fields === supply_chain.total_fields;
  }
  onManageTranslations(supply_chain: ILanguageManagementSupplyChain): void {
    this._getSupplyChainForTranslate(supply_chain);
  }

  ////////////////////////////////////////Private Methods////////////////////////////////////////

  private _getSupplyChainForTranslate(supply_chain: ILanguageManagementSupplyChain): void {
    this._languageManagementFacade.getSupplyChainForTranslation$(supply_chain.uuid).subscribe({
      next: data => this._getSupplyChainForTranslateSuccess(data, supply_chain),
      error: this._error.bind(this),
    });
  }

  private _getSupplyChainForTranslateSuccess(
    data: ILanguageManagementResponse,
    supply_chain: ILanguageManagementSupplyChain
  ): void {
    this.supplyChainDataFromBackend = data;
    this.supplyChainDataForTranslate = [
      {
        ...this.supplyChainDataFromBackend.default_translation,
        uuid: 'default',
        count_translated_fields: 0,
      },
      ...this.supplyChainDataFromBackend.translations,
    ];

    this.modelName = 'Supply Chain data';
    this._openDialog(supply_chain);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _openDialog(supply_chain: ILanguageManagementSupplyChain): void {
    const dialogRef = this._dialog.open(SupplyChainTranslationDialogComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: {
        supply_chain: supply_chain,
        chainSteps: this.chainSteps,
        supplyChainDataForTranslate: this.supplyChainDataForTranslate,
        modelName: this.modelName,
        selectedChainUuid: this.selectedChainUuid,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        dialogRef.close();
      }
    });
  }
}

import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IBatchResponse, IBatchResult } from '@app/core/interface/batch.interface';
import { IProduct, IProductResponse } from '@app/core/interface/products.interface';
import { IProductChainResult, IProductChainsResponse } from '@app/core/interface/productchain.interface';

import { BatchFacade } from '@app/core/facade/batch.facade';
import { ISupplierResponse } from '@interface/suppliers.interface';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-chains-batches',
  templateUrl: './chains-batches.component.html',
  styleUrls: ['./chains-batches.component.scss'],
  providers: [ProductChainFacade, BatchFacade],
})
export class ChainsBatchesComponent implements OnInit {
  productChainList!: IProductChainResult[];
  batchList!: IBatchResult[];
  dataSource = new MatTableDataSource<IProductChainResult>();
  batchDataSource = new MatTableDataSource<IBatchResult>();
  toggleCo2 = false;
  isInactiveChains = false;
  isInactiveBatches = false;
  @Input() supplier!: ISupplierResponse;
  @Input() showFilters = true;
  @Input() chainUuidList?: string[];
  @Input() pageSize!: number;
  @Input() productUuid: string | null = null;
  @Output() isChains = new EventEmitter<boolean>();
  @Input() product!: IProduct | IProductResponse;
  pagesize = 12;
  isChainsView = true;

  constructor(
    private readonly _facade: ProductChainFacade,
    private readonly _batchFacade: BatchFacade,
    private readonly _snackbarService: SnackbarService,
    public _router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getProductChains();
    this.route.paramMap.subscribe(snapshot => {
      const chains = snapshot.get('chains');
      if (chains) {
        const chainsConverted = chains === 'true';
        this.isChainsView = chainsConverted;
        this.isChains.emit(chainsConverted);
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageSize']) this.pagesize = changes['pageSize'].currentValue;
  }

  onQuickFilterChanged(event: Event): void {
    applyFilter(event, this.dataSource, this.dataSource);
  }

  showInactiveChains(event?: MatCheckboxChange): void {
    let filteredChains: IProductChainResult[];
    // Update the checkbox state directly if the event is provided
    this.isInactiveChains = event?.checked ?? this.isInactiveChains;

    if (this.isInactiveChains) {
      // Show inactive chains first, followed by active chains
      filteredChains = this._sortArray(this.productChainList);
    } else {
      // Show only active chains
      filteredChains = this.productChainList?.filter(chain => chain?.is_active);
    }
    this.dataSource.data = filteredChains;
  }

  showInactiveBatches(event?: MatCheckboxChange): void {
    let filteredBatches: IBatchResult[];
    this.isInactiveBatches = event?.checked ?? this.isInactiveBatches;
    if (this.isInactiveBatches) {
      // Show inactive batches first, followed by active batches
      filteredBatches = this._sortArray(this.batchList);
    } else {
      // Show only active batches
      filteredBatches = this.batchList?.filter(chain => chain?.is_active);
    }
    this.batchDataSource.data = filteredBatches;
  }

  getProductChains() {
    this._facade.getProductChains$().subscribe({
      next: this._getProductChainsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getBatches(): void {
    this._batchFacade.getBatchList$().subscribe({
      next: this._getBatchListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  onBatchNumberFilterChanged(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue === '') {
      // If the filter value is empty, reset the filter and display all items
      this.batchDataSource.data = this.batchList.filter(chain => chain.is_active);
    } else {
      this.batchDataSource.data = this.batchList.filter(chain => {
        return chain.steps.some(step => {
          const batchNumber = step.supplier_batch_number;
          if (batchNumber?.toLowerCase().includes(filterValue)) {
            return true;
          } else {
            return false; // Explicitly return false when the filter criteria are not met
          }
        });
      });
    }
  }

  onReferenceNumberFilterChanged(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue === '') {
      this.dataSource.data = this.productChainList.filter(chain => chain.is_active);
    } else {
      this.dataSource.data = this.productChainList.filter(chain => {
        if (chain.external_reference?.toLowerCase().includes(filterValue)) {
          return true;
        } else {
          return false;
        }
      });
    }
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.isChains.emit(event.value === 'chains');
    if (event.value === 'batches') {
      this.getBatches();
    }
    this.cdr.detectChanges();
  }

  private _getProductChainsSuccess(data: IProductChainsResponse): void {
    if (this.chainUuidList?.length) {
      this.productChainList = data.results.filter(chain => this.chainUuidList?.includes(chain.uuid));
    } else {
      this.productChainList = data.results;
      if (this.productUuid)
        this.productChainList = this.productChainList.filter(chain => chain.product?.uuid === this.productUuid);
      if (this.supplier) this.productChainList = this.supplier.product_chain.length > 0 ? this.productChainList : [];
    }

    this.showInactiveChains();
  }

  private _getBatchListSuccess(data: IBatchResponse): void {
    if (this.chainUuidList?.length) {
      this.batchList = data.results.filter(batch =>
        this.chainUuidList?.includes(batch.batch[0].batch_chain.product_chain)
      );
    } else {
      this.batchList = data.results;
      if (this.productUuid) this.batchList = this.batchList.filter(chain => chain.product?.uuid === this.productUuid);
      if (this.supplier) this.batchList = this.supplier.product_chain.length > 0 ? this.batchList : [];
    }

    this.showInactiveBatches();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  createProductChain(product: IProduct | IProductResponse): void {
    const navigationExtras: NavigationExtras = { state: { product: product } };
    this._router.navigate(['chain-setup'], navigationExtras);
  }

  onQuickSearchBatch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.batchDataSource.data = this.filteredBatches(filterValue);
  }

  filteredBatches(filterValue: string) {
    return this.batchList.filter(batch => {
      // Filtering by name, reference, product name...
      const nameMatch = batch.name.toLowerCase().includes(filterValue);
      const referenceMatch = batch.reference?.toLowerCase().includes(filterValue);
      const productNameMatch = batch.product.name.toLowerCase().includes(filterValue);

      // Return true if any of the filter criteria match.
      return nameMatch || referenceMatch || productNameMatch;
    });
  }

  private _sortArray(list: IBatchResult[] | IProductChainResult[]): IBatchResult[] | IProductChainResult[] {
    return list.sort((a, b) => {
      if (a.is_active && !b.is_active) {
        return 1;
      } else if (!a.is_active && b.is_active) {
        return -1;
      } else {
        return 0; // no change in order
      }
    });
  }
}

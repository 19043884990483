<div class="wrapper">
  <h2 mat-dialog-title>{{ mode + ' Contact' | translate }}</h2>
  <app-personal-data-tooltip></app-personal-data-tooltip>
  <div class="contact-wraper">
    <h6>{{ 'Contact general info' | translate }}</h6>

    <form [formGroup]="form">
      <div class="input-holder">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'First Name' | translate }}</mat-label>
          <input
            test-id="input-add_contact-first_name"
            matInput
            formControlName="first_name"
            placeholder="First Name"
            required
          />
        </mat-form-field>

        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Last Name' | translate }}</mat-label>
          <input
            test-id="input-add_contact-last_name"
            matInput
            formControlName="last_name"
            placeholder="Last Name"
            required
          />
        </mat-form-field>
      </div>

      <div class="input-holder">
        <div class="input-wraper">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'E-mail' | translate }}</mat-label>
            <input test-id="input-add_contact-email" matInput formControlName="email" placeholder="E-mail" required />
            @if (form.controls['email'].errors && form.controls['email'].errors['nonUniqueName']) {
              <mat-error>{{
                'This email was already used for a contact, please use another one.' | translate
              }}</mat-error>
            }
            @if (form.controls['email'].errors && form.controls['email'].errors['email']) {
              <mat-error>{{ 'Invalid email' | translate }}</mat-error>
            }
            @if (form.controls['email'].errors && form.controls['email'].errors['required']) {
              <mat-error>{{ 'Email is required' | translate }}</mat-error>
            }
          </mat-form-field>
          <app-custom-icon
            class="indicator"
            [icon_category]="'essential'"
            [icon_name]="'info-circle'"
            [matTooltip]="
              'account-setup.We collect your email address to send you important updates and notifications related to your account. Your email will only be used for these purposes and will not be shared with third parties.'
                | translate
            "
            matTooltipClass="custom-tooltip-center"
          ></app-custom-icon>
        </div>

        <div class="input-wraper">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Phone' | translate }}</mat-label>
            <input test-id="input-add_contact-phone" matInput formControlName="phone" placeholder="Phone" />
          </mat-form-field>
          <app-custom-icon
            class="indicator"
            [icon_category]="'essential'"
            [icon_name]="'info-circle'"
            [matTooltip]="
              'Your phone number is collected to provide customer support and verify your identity. We may contact you regarding any issues or updates related to your account.'
                | translate
            "
            matTooltipClass="custom-tooltip-center"
          ></app-custom-icon>
        </div>
      </div>

      <mat-divider></mat-divider>
      <h6>{{ 'Contact details' | translate }}</h6>
      <div class="input-holder">
        <div class="descInput">
          <p>Supplier contact title</p>
          <div
            class="identifier-info-holder d-flex align-items-center justify-content-center cursor-pointer"
            (mouseover)="mouseEvUniqueIdentifier(true)"
            (mouseout)="mouseEvUniqueIdentifier(false)"
          >
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
            <div class="tooltip left" *ngIf="uniqueIdentifierInfo">
              <p>
                {{ 'Please add the function that this contact has in the company.' | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="descInput">
          <p>Supplier contact language</p>
          <div
            class="identifier-info-holder d-flex align-items-center justify-content-center cursor-pointer"
            (mouseover)="mouseEvIdentifierType(true)"
            (mouseout)="mouseEvIdentifierType(false)"
          >
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
            <div class="tooltip" *ngIf="identifierTypeInfo">
              <p>
                {{
                  'Please choose the default language of the selected contact in which the tool can communicate with him in order to continue build the products supply chain.'
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="input-holder">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Contact Function(manager...' | translate }}</mat-label>
          <input test-id="input-add_contact-position" matInput formControlName="position" placeholder="Function" />
        </mat-form-field>

        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Language' | translate }}</mat-label>
          <mat-icon matSuffix class="icon-display">arrow_drop_down</mat-icon>
          <input
            test-id="input-add_contact-language_prefered"
            matInput
            formControlName="language_prefered"
            [matAutocomplete]="auto"
            placeholder="Choose supplier contact language"
          />
          <mat-autocomplete
            class="autocomplete-primary"
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (closed)="onClosedEvent()"
            (opened)="isOpened()"
          >
            <mat-option *ngFor="let option of supplierLanguageOptions" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="slide-toggle-wrapper">
        <p>{{ 'Main Contact' | translate }}</p>
        <mat-slide-toggle
          test-id="slide_toggle-add_contact-main_contact"
          class="custom-slide-toggle"
          [color]="'primary'"
          formControlName="main_contact"
        >
        </mat-slide-toggle>
        <div class="vertical-line"></div>
        <p>{{ 'Active' | translate }}</p>
        <mat-slide-toggle
          test-id="slide_toggle-add_contact-is_active"
          class="custom-slide-toggle"
          [color]="'primary'"
          formControlName="is_active"
        >
        </mat-slide-toggle>
      </div>
    </form>
    <mat-dialog-actions align="end" class="d-flex justify-content-between">
      <app-data-secured-badge test-id="data-secured-badge-add_contact"></app-data-secured-badge>
      <div>
        <app-rounded-button
          test-id="button-add_contact-cancel"
          size="md"
          mat-dialog-close
          color="tetriary-link"
          button_text="{{ 'Cancel' | translate }}"
        >
        </app-rounded-button>
        <app-rounded-button
          color="primary"
          button_text="{{ data.supplierContact?.uuid ? 'Save Changes' : ('Create Contact' | translate) }}"
          size="md"
          [disabled]="form && !form.valid"
          (click)="addContact()"
          [attr.test-id]="
            data.supplierContact?.uuid ? 'button-add_contact-save_changes' : 'button-add_contact-create_contact'
          "
        >
        </app-rounded-button>
      </div>
    </mat-dialog-actions>
  </div>
</div>

<div [ngClass]="entityForCertification === 'Supplier' ? 'supplier-holder' : 'holder'" class="holder">
  <h2 mat-dialog-title>
    {{ mode === 'Update' ? ('Edit Certificate' | translate) : ('Create New Certificate' | translate) }}
  </h2>
  <div class="notification" *ngIf="expired">
    <div class="not-info">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'" class="cursor-pointer">
      </app-custom-icon>
      <p>{{ 'This certificate is out of date!' | translate }}</p>
    </div>
    <app-custom-icon
      (click)="expired = !expired"
      id="close-circle"
      class="cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
    ></app-custom-icon>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="create-supplier-wraper">
      <form [formGroup]="form">
        <div class="top-holder">
          <div class="form-holder">
            <h6 class="form-title">{{ 'Certification' | translate }}</h6>
            <div class="inputs-holder">
              <div class="certificate-name">
                <div *ngIf="!(entityForCertification === 'Brand' || entityForCertification === 'Step')" class="col-6">
                  <mat-form-field
                    *ngIf="certificates.length; else createCert"
                    class="dropdown-primary certificate-type mat-form-field-10-px-brd-rd-dd"
                    appearance="outline"
                  >
                    <input
                      id="certificate"
                      formControlName="certificate"
                      [value]="
                        form.controls['certificate'].value?.valid ? form.controls['certificate'].value?.name : ''
                      "
                      matTooltip="Select certificate"
                      matTooltipShowDelay="1000"
                      placeholder="{{ 'Search certificate type' | translate }}"
                      panelClass="dropdown-primary"
                      matInput
                      [matAutocomplete]="auto"
                      (input)="filterItem($event)"
                      #inputFilter
                      #trigger="matAutocompleteTrigger"
                      required
                      class="search-input"
                    />
                    <mat-autocomplete
                      [displayWith]="displayFn"
                      class="cert-list-autocomplete autocomplete-primary"
                      #auto="matAutocomplete"
                      (closed)="onClosedEvent()"
                      (opened)="isOpened()"
                    >
                      <mat-option
                        [attr.test-id]="'option-certification_dashboard-certificate_' + certificate.uuid"
                        *ngFor="let certificate of certificates"
                        [value]="certificate"
                        placeholder="certificate.name"
                      >
                        <div class="option-holder">
                          <p class="m-0 cert-name">{{ certificate.name }}</p>
                          <div class="details">
                            <p class="m-0 category-name">{{ certificate.category.name }}</p>
                            <p class="m-0 domain-name">{{ certificate.domain?.name }}</p>
                          </div>
                        </div>
                      </mat-option>
                    </mat-autocomplete>

                    <app-custom-icon
                      class="cursor-pointer"
                      (click)="openOrClosePanel($event, trigger)"
                      matSuffix
                      [icon_category]="'arrows'"
                      [icon_name]="opened ? 'arrow-up-2' : 'arrow-down-2'"
                    ></app-custom-icon>
                  </mat-form-field>
                </div>
                <div class="col-6 certificator">
                  <mat-form-field class="input-primary" appearance="outline">
                    <mat-label>{{ 'Certificator' | translate }}</mat-label>
                    <input
                      test-id="input-certification_dashboard-certificator"
                      matInput
                      placeholder="{{ 'Certificator' | translate }}"
                      formControlName="certificator"
                      [maxlength]="30"
                    />
                  </mat-form-field>
                </div>
              </div>
              <ng-template #createCert>
                <div class="col-6">
                  <div class="cetrification-name col-12">
                    {{
                      'Sorry there are no certifications created. In order to create certificate please first create
                    certification.' | translate
                    }}
                  </div>
                  <div class="create-certificate-btn col-12">
                    <app-rounded-button
                      color="primary"
                      [button_text]="'Create Certificate Type'"
                      [size]="'md'"
                      (click)="createEditCertificateType(null)"
                    >
                    </app-rounded-button>
                  </div>
                </div>
              </ng-template>
              <div
                *ngIf="form.controls['certificate']?.value?.category?.name?.trim().toUpperCase() !== 'AUDIT'"
                class="flex-row date-picker-holder"
              >
                <mat-form-field class="input-primary" appearance="outline">
                  <mat-label>{{ 'Start Date' | translate }}</mat-label>
                  <input
                    test-id="input-certification_dashboard-validity_start"
                    matInput
                    [matDatepicker]="picker_start"
                    formControlName="validity_start"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker_start"></mat-datepicker-toggle>
                  <mat-datepicker #picker_start></mat-datepicker>
                  <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar-search'"></app-custom-icon>
                </mat-form-field>
                <mat-form-field class="input-primary" appearance="outline">
                  <mat-label>{{ 'End Date' | translate }}</mat-label>
                  <input
                    test-id="input-certification_dashboard-validity_end"
                    matInput
                    [matDatepicker]="picker_end"
                    formControlName="validity_end"
                    [min]="minEnd"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker_end"></mat-datepicker-toggle>
                  <mat-datepicker #picker_end></mat-datepicker>
                  <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar-search'"></app-custom-icon>
                </mat-form-field>
              </div>
            </div>
            <mat-checkbox
              test-id="checkbox-certification_dashboard-apply_to_supplier_sites"
              [checked]="supplierSitesView"
              *ngIf="entityForCertification === 'Supplier' && mode === 'Create'"
              (change)="getCheckBoxvalue($event)"
              [ngClass]="docURLTransformed ? 'checkbox-primary mt-59' : 'checkbox-primary mt-39'"
            >
              {{ 'apply certificate to supplier sites' | translate }}
            </mat-checkbox>
          </div>
          <div class="uploader-holder">
            <h6 class="upload-title" *ngIf="mode === 'Create'">{{ 'Upload your certificate' | translate }}</h6>
            <h6 class="upload-title" *ngIf="mode === 'Update'">{{ 'Certificate file' | translate }}</h6>

            <div class="img-sert-wrap" *ngIf="!docURLTransformed">
              <div class="info-section">
                <p>
                  {{
                    'No files uploaded yet. Please upload your document in the required format, and no more than 10mb.'
                      | translate
                  }}
                </p>
              </div>
              <div [ngClass]="entityForCertification === 'Supplier' ? 'upload-file' : 'upload-file upload-file-w50'">
                <div class="" [ngClass]="{ 'upload-btn': !warn, 'warn-uploaded': warn }">
                  <div class="icon-circle">
                    <app-custom-icon
                      id="document-text"
                      [icon_category]="'files'"
                      [icon_name]="'document-text'"
                    ></app-custom-icon>
                  </div>
                  <input type="file" class="file-upload" #fileUpload (change)="onFileSelected($event)" />
                  <div class="button-holder">
                    <div class="text-holder" *ngIf="!warn">
                      <p>
                        {{ 'pdf.doc.xml' | translate }} <br />
                        {{ '/ maximum 10MB' | translate }}
                      </p>
                    </div>
                    <div class="text-holder" *ngIf="warn">
                      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"></app-custom-icon>
                      <div class="text">
                        <p class="bolder" *ngIf="warn">{{ 'Your file is larger than 10mb!' | translate }}</p>
                        <p *ngIf="warn">{{ 'Please reduce the size of the file.' | translate }}</p>
                      </div>
                    </div>

                    <app-rounded-button
                      [attr.test-id]="
                        warn
                          ? 'button-certification_dashboard-try_again'
                          : 'button-certification_dashboard-upload_from_pc'
                      "
                      [color]="warn ? 'turquoise' : 'primary'"
                      [size]="'md'"
                      [icon_right]="true"
                      [icon_category]="warn ? 'arrows' : 'essential'"
                      [icon_name]="warn ? 'refresh-square' : 'upload'"
                      [button_text]="warn ? 'Try again' : 'Upload from PC'"
                      #fileUpload
                      (change)="onFileSelected($event)"
                      (click)="fileUpload.click(); form.controls['document'].markAsDirty()"
                    >
                    </app-rounded-button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="docURLTransformed" class="certificate-uploaded">
              <div
                [ngClass]="
                  entityForCertification === 'Supplier' ? 'box-text-holder' : 'box-text-holder box-text-holder-w50'
                "
              >
                <div class="box-icon-uploaded" *ngIf="mode === 'Create'">
                  <div class="icon-uploaded">
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
                  </div>

                  <p>{{ 'Your Certificate was uploaded successfully!' | translate }}</p>
                </div>
                <div class="box-text" *ngIf="mode === 'Update'">
                  <p>
                    {{
                      'This is your uploaded file. If this is not the correct file, you can remove the file by clicking on the x button, and then upload a new one.'
                        | translate
                    }}
                  </p>
                </div>
              </div>

              <div class="box-file">
                <div [ngClass]="mode === 'Update' ? 'box-file-uploaded mt-14' : 'box-file-uploaded'">
                  <div class="icon-circle">
                    <app-custom-icon [icon_category]="'files'" [icon_name]="'document-text-white'"></app-custom-icon>
                  </div>
                  <a [href]="docURLTransformed" target="_blank" bypassSecurityTrustUrl>
                    <p>{{ fileName }}</p>
                  </a>
                  <div class="close-btn" *ngIf="docURLTransformed">
                    <app-custom-icon
                      id="close-circle"
                      class="cursor-pointer"
                      (click)="clearFile()"
                      [icon_category]="'essential'"
                      [icon_name]="'close-circle'"
                    ></app-custom-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="file-too-large"></div>
          </div>
        </div>

        <div *ngIf="entityForCertification === 'Supplier'" class="divider"></div>

        <div *ngIf="supplierSitesView" class="supplier-sites-holder">
          <h6>{{ 'Choose on which supplier sites you want to apply this certificate' | translate }}</h6>
          <div class="card-holder">
            <div *ngFor="let manufactori of data.manufactories; let i = index" class="site-card">
              <div class="site-details">
                <div class="icon-holder">
                  <app-custom-icon [icon_category]="'buildings'" [icon_name]="'factory-white'"></app-custom-icon>
                </div>
                <div class="details">
                  <p class="name">{{ manufactori.name | slice: 0 : 20 }}</p>
                  <p class="address">
                    {{ manufactori.city ? manufactori.city + ',' : '' }}
                    {{ manufactori.address ? manufactori.address + ',' : '' }} {{ manufactori.country }}
                  </p>
                  <div *ngIf="manufactori.is_main_location" class="main-site">
                    <div class="dot"></div>
                    <p class="main-site-text">{{ 'main supplier site' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="action">
                <div class="action-text">
                  {{ this.toggleArray[i] ? 'applied on this site' : ('switch on to apply' | translate) }}
                </div>
                <mat-slide-toggle
                  [checked]="manufactori.toggleStatus"
                  class="custom-slide-toggle"
                  [color]="'primary'"
                  (change)="onChange($event, i, manufactori.uuid)"
                >
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="entityForCertification==='Supplier'">
                    <app-supplier-sites-table [sites]="data.manufactories ?? []"
                        [alreadySelected]="data.alreadySelected ?? []" (listChange)="setSites($event)">
                    </app-supplier-sites-table>
                </div> -->
      </form>
    </div>
  </mat-dialog-content>
</div>
<mat-dialog-actions align="end">
  <div class="discard-btn" *ngIf="mode === 'Create'">
    <app-rounded-button
      color="secondary"
      [size]="'md'"
      [button_text]="'Discard'"
      mat-dialog-close
      class="cert-action-btn"
      test-id="button-certification-dashboard-discard"
    >
    </app-rounded-button>
  </div>
  <div class="cancel-btn" *ngIf="mode === 'Update'">
    <app-rounded-button mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Cancel'">
    </app-rounded-button>
  </div>

  <div class="create-btn">
    <app-rounded-button
      [attr.test-id]="
        mode === 'Create'
          ? 'button-certification_dashboard-create_certificate'
          : 'button-certification_dashboard-save_changes'
      "
      color="primary"
      [size]="'md'"
      [button_text]="mode === 'Create' ? 'Create Certificate' : 'Save changes'"
      [icon_category]="'learning'"
      [icon_name]="'certificate'"
      [icon_right]="mode === 'Create' ? true : false"
      class="cert-action-btn"
      (click)="submit()"
      [disabled]="form && (!form.valid || form.pristine)"
    >
    </app-rounded-button>
  </div>
</mat-dialog-actions>

export function base64ToFile(base64Image: string, filename: string): File {
  const arr = base64Image.split(',');
  const mime = RegExp(/:(.*?);/).exec(arr[0]);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime ? mime[1] : '' });
}

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  IPublicationSupplier,
  IPublicationSupplierListResponse,
} from '@app/core/interface/publication-management.interface';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-suppliers-publication',
  templateUrl: './suppliers-publication.component.html',
  styleUrl: './suppliers-publication.component.scss',
  providers: [PublicationManagementFacade],
})
export class SuppliersPublicationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  publicationSuppliersList: IPublicationSupplier[] = [];
  publicationSuppliersObservable$!: Observable<IPublicationSupplier[]>;
  cardDataSource = new MatTableDataSource<IPublicationSupplier>();

  constructor(
    private _publicationManagementFacade: PublicationManagementFacade,
    private _snackbarService: SnackbarService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getPublicationSuppliersList();
  }

  getPublicationSuppliersList(): void {
    this._publicationManagementFacade.getPublicationSuppliersList$().subscribe({
      next: this._getPublicationSuppliersListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
    this.publicationSuppliersObservable$ = this.cardDataSource.connect();
    this._changeDetectorRef.detectChanges();
  }

  private _getPublicationSuppliersListSuccess(data: IPublicationSupplierListResponse): void {
    if (data && data.results.length) {
      this.paginator.pageSize = 16;
      this.publicationSuppliersList = data.results;
      this.cardDataSource.data = data.results;
    }
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource);
  }
  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}

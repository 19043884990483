import {
  IProductChainStep,
  IStepCertification,
  SipSupplier,
  StepSupplierIdentified,
  SupplierStatus,
} from './steps.interface';

import { AnchorSpec } from 'jsplumb';
import { ICluster } from './cluster.interface';
import { IManufactory } from './manufactories.interface';
import { IMedia } from './medias.interface';
import { INameID } from './projects.interface';
import { IPhase } from './phase-management.interface';

export interface INodeConnection {
  uuids: [string, string];
}

export interface IGetConnection {
  getUuids(): string[];
}

export interface INode {
  id?: string;
  name: string;
  description?: string;
  position_x?: number;
  position_y?: number;
  supplier?: {
    uuid: string;
    name: string;
  };
  product_chain?: string | { name: string; uuid: string };
  type_step: {
    name: string;
    uuid: string;
    color: string;
    position: number;
  };
  uuid?: string;
  certification_step?: IStepCertification[];
  manufactories: IManufactory;
  next_steps?: string[];
  medias?: IMedia[];
  documents?: IMedia[];
  ingredient?: {
    name: string;
    uuid: string;
  };
  output_tags?: string;
  youtube_url?: string;
  phase?: {
    name: string;
    uuid: string;
    color: string;
  };
  group?: ICluster;
  status?: string;
  supplier_batch_number: string;
  supplier_status?: SupplierStatus;
  co2_impacts?: ICo2Impact[];
  brand_status?: string;
  sip_supplier_identified?: SipSupplier;
  sip_supplier_identified_approved?: StepSupplierIdentified;
  is_active?: boolean;
  active_status_by_user?: {
    email: string;
    supplier: string;
    first_name: string;
    last_name: string;
    username: string;
  };
  active_status_by_ui?: number;
  isChainComplete?: boolean;
}

export interface ICo2Impact {
  value?: number;
  unit?: string;
  unit2?: string;
}

export interface IAnchorDirection {
  anchor1: AnchorSpec | undefined;
  anchor2: AnchorSpec | undefined;
}

export interface IProductChainsResponse {
  count: number;
  next: string;
  previous: string;
  in_progress: number;
  completed: number;
  results: IProductChainResult[];
  error?: IProductChainErrorResponse;
}

export interface IProductChainResult {
  external_reference: string;
  publication_link?: string;
  created_at: string;
  reference?: string;
  name: string;
  groups: ICluster[];
  links_steps: IProductChainLink[];
  status: string;
  steps: IProductChainStep[];
  respect_cycle: string;
  updated_at: string;
  published_chain_uuid: string;
  uuid: string;
  direction: string;
  product: IProductFromChain;
  is_active?: boolean;
  tags: [];
  type_steps: IPhase[];
  batch: {
    uuid: string;
    name: string;
    qrcode: string;
    quantity: string;
    reference: string;
    quantity_unit: {
      name: string;
      uuid: string;
    };
    transport: string;
    batch_chain: {
      uuid: string;
      name: string;
      product_chain: string;
    };
    is_active?: boolean;
    external_reference: string;
  }[];
  error?: IProductChainErrorResponse;
}

export interface IProductFromChain {
  name: string;
  project: INameID;
  subproject: string;
  uuid: string;
  created_at: string;
  image: string;
}

export interface IProductChainLink {
  step_from: string;
  step_to: string;
  product_chain?: string;
  uuid?: string;
}

export interface IProductChainErrorResponse {
  detail: string[];
  missing_translations?: boolean;
}

export interface IDeleteProductChain {
  detail: string;
  error?: {
    detail: string[];
  };
}
export interface ICo2 {
  value: string | number;
  unit: string;
  unit2: string;
}

export interface IStepSupplierStatus {
  steps_supplier_status_updated: number;
  steps_supplier_status_to_update: number;
  steps_supplier_status_not_applicable: number;
  error?: {
    detail: string[];
  };
}

export enum ChainStatus {
  in_progress = 'IN PROGRESS',
  complete = 'COMPLETE',
}

export interface IAddStepPhaseModalData {
  selectedChain?: IProductChainResult;
  selectedStepsList?: string[];
}

<div class="holder d-flex flex-column justify-content-between">
  <div class="info-card-wrap d-flex flex-column">
    <div class="header d-flex justify-content-between align-items-start">
      <div class="info d-flex flex-column">
        <p class="m-0 info-text">
          {{ 'Configure which fields and media will be visible for your products' | translate }}
        </p>
        <div class="published-holder d-flex align-items-center">
          <div class="published">
            <div class="published-box"></div>
            <p>{{ 'Published chain' | translate }}</p>
          </div>
          <div class="non-published">
            <div class="non-published-box"></div>
            <p>{{ 'Non-published chain' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="filter">
        <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline">
          <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
          <input (keyup)="applyFilter($event)" matInput placeholder="Quick search..." #input />
          <app-custom-icon
            class="search-filter"
            [icon_category]="'search'"
            [icon_name]="'search-normal-2'"
          ></app-custom-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="cards-holder">
      <div class="card" *ngFor="let product of productsObservable$ | async">
        <app-product-publication-card
          [product]="product"
          (refresh)="getPublicationProducts()"
        ></app-product-publication-card>
      </div>
    </div>
  </div>

  <div class="footer">
    <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of products"></mat-paginator>
  </div>
</div>

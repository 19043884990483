<div class="holder d-flex flex-column justify-content-between">
  <div class="table-wraper row">
    <table class="custom-table" mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Batch Name' | translate }}</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Batch ID' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="product-info">
            <div class="logo">
              <img
                class="avatar"
                src="{{
                  element.product.image
                    ? element.product.image
                    : '../../../../../../assets/images/upload-placeholder-blue.svg'
                }}"
              />
            </div>
            <div class="name">
              <p>{{ element.product.name }}</p>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Tags' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let tag of element.tags">{{ tag }} </span>
          <span *ngIf="!element.tags.length">/</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div
            class="chain-status"
            [ngClass]="{ 'in-progress': element.status === 'IN PROGRESS', completed: element.status === 'COMPLETE' }"
          ></div>
          {{ element.status | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header">{{ 'Actions' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="actions-body">
          <div class="action-icons" *ngIf="element.is_active">
            <div class="d-flex gap-12">
              <app-custom-icon
                matTooltip="Open batch"
                matTooltipClass="custom-tooltip-center"
                [matTooltipPosition]="'above'"
                [icon_category]="'security'"
                [icon_name]="'eye'"
                *ngIf="element.is_active"
                (click)="viewProductBatch(element.uuid)"
              ></app-custom-icon>
              <!-- Temporaly removed because we miss that functionallity. -->
              <!-- <app-custom-icon
              matTooltip="Display batch steps in list"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
              [icon_category]="'files'"
              [icon_name]="'table'"
              *ngIf="element.is_active"
            ></app-custom-icon> -->
              <app-custom-icon
                matTooltip="Deactivate batch"
                matTooltipClass="custom-tooltip-center"
                [matTooltipPosition]="'above'"
                [icon_category]="'essential'"
                [icon_name]="'deactivate-red'"
                *ngIf="element.is_active"
                (click)="updateBatchStatus(true, element)"
              ></app-custom-icon>
            </div>
          </div>

          <div class="icon-holder">
            <app-custom-icon
              matTooltip="{{ 'Reactivate Chain' | translate }}"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
              [icon_category]="'essential'"
              [icon_name]="'activate'"
              class="activate"
              id="activate"
              *ngIf="!element.is_active"
              (click)="updateBatchStatus(false, element)"
            ></app-custom-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of contacts"> </mat-paginator>
  </div>
</div>

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ILanguageManagement,
  ILanguageManagementBrand,
  ILanguageManagementBrandsResponse,
  ILanguageManagementResponse,
} from '@app/core/interface/language-management.interface';

import { BrandTranslationDialogComponent } from '../brand-translation-dialog/brand-translation-dialog.component';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-brand-translations',
  templateUrl: './brand-translations.component.html',
  styleUrl: './brand-translations.component.scss',
  providers: [LanguageManagementFacade],
})
export class BrandTranslationsComponent implements OnInit, AfterViewInit, OnDestroy {
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  cardDataSource = new MatTableDataSource<ILanguageManagementBrand>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  brand!: ILanguageManagementBrand;
  languageManagementBrandsList: ILanguageManagementBrand[] = [];
  @Input() selectedChainUuid!: string | null;
  modelName!: string;
  brandDataFromBackend!: ILanguageManagementResponse;
  brandDataForTranslate: ILanguageManagement[] = [];
  refreshSubscription!: Subscription;

  constructor(
    readonly _languageManagementFacade: LanguageManagementFacade,
    readonly _snackbarService: SnackbarService,
    readonly _dialog: MatDialog,
    readonly _languageManagementService: LanguageManagementService
  ) {}

  ngOnInit(): void {
    this.getLanguageManagementBrands();
    this.subscribeToRefresh();
  }

  getLanguageManagementBrands(): void {
    this._languageManagementFacade.getLanguageManagementBrands$().subscribe({
      next: this._getLanguageManagementBrandsListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    this.paginator.pageSize = 8;
    this.cardDataSource.paginator = this.paginator;
  }

  subscribeToRefresh(): void {
    this.refreshSubscription = this._languageManagementService.refreshBrand$.subscribe(refresh => {
      if (refresh) {
        this.getLanguageManagementBrands();
      }
    });
  }

  isTranslationComplete(brand: ILanguageManagementBrand): boolean {
    return brand.translated_fields === brand.total_fields;
  }
  manageTranslations(brandUuid: string): void {
    this._getBrandForTranslate(brandUuid);

    setTimeout(() => {
      this._openDialog(brandUuid);
    }, 500);
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  ////////////////////////////////////////Private Methods////////////////////////////////////////

  private _openDialog(brandUuid: string): void {
    const dialogRef = this._dialog.open(BrandTranslationDialogComponent, {
      width: '1160px',
      height: '800px',
      panelClass: 'top-padding-0',
      data: {
        brandUuid: brandUuid,
        brandDataForTranslate: this.brandDataForTranslate,
        modelName: this.modelName,
        selectedChainUuid: this.selectedChainUuid,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getLanguageManagementBrands();
      }
    });
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  private _getLanguageManagementBrandsListSuccess(data: ILanguageManagementBrandsResponse): void {
    if (data && data.results.length) {
      this.languageManagementBrandsList = data.results;
      this.cardDataSource.data = data.results;
      this._languageManagementService.triggerBrandRefresh(false);
    }
  }

  private _getBrandForTranslate(uuid: string): void {
    this._languageManagementFacade.getBrandForTranslation$(uuid).subscribe(data => {
      this.brandDataFromBackend = data;

      this.brandDataForTranslate = [
        {
          ...this.brandDataFromBackend.default_translation,
          uuid: 'default',
          count_translated_fields: 0,
        },
        ...this.brandDataFromBackend.translations,
      ];
      this.modelName = 'Brand data';
    });
  }
}

import { AfterViewInit, Component, ElementRef, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagement,
  ILanguageManagementPutRequest,
  ILanguageManagementPutRequestError,
  ILanguageManagementSupplyChain,
} from '@app/core/interface/language-management.interface';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { LanguageManagementTableComponent } from '@app/shared/components/language-management-table/language-management-table.component';

/////class for dummy data
export class IChainStep {
  name?: string;
  translations_progress?: number;
  translations_total?: number;
}
@Component({
  selector: 'app-supply-chain-translation-dialog',
  templateUrl: './supply-chain-translation-dialog.component.html',
  styleUrl: './supply-chain-translation-dialog.component.scss',
  providers: [LanguageManagementFacade],
})
export class SupplyChainTranslationDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('childComponent') childComponent!: LanguageManagementTableComponent;
  supply_chain!: ILanguageManagementSupplyChain;
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly _languageManagementService = inject(LanguageManagementService);
  readonly _dialogRef = inject(MatDialogRef<SupplyChainTranslationDialogComponent>);
  public supplyChainDataForTranslate: ILanguageManagement[] = [];
  public modelName: string = '';
  isSelected = true;
  selectedStep: IChainStep | undefined;
  step!: IChainStep;
  chainSteps: IChainStep[] = [];
  selectedChainUuid!: string;
  @ViewChild('list') list!: ElementRef<HTMLElement>;
  isVisibleLeft = false;
  isVisibleRight = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly _data: {
      supply_chain: ILanguageManagementSupplyChain;
      supplyChainDataForTranslate: ILanguageManagement[];
      modelName: string;
      chainSteps: IChainStep[];
      selectedChainUuid: string;
    }
  ) {}

  ngOnInit(): void {
    this.modelName = this._data.modelName;
    this.supply_chain = this._data.supply_chain;
    this.supplyChainDataForTranslate = this._data.supplyChainDataForTranslate;
    this.chainSteps = this._data.chainSteps;
    this.selectedChainUuid = this._data.selectedChainUuid;
  }

  private _removeAllWithNameStep(data: ILanguageManagement[]): ILanguageManagement[] {
    return data.map(data => {
      const filteredFields = Object.fromEntries(
        Object.entries(data.translated_fields).filter(([key]) => !key.includes('Step'))
      );
      return {
        ...data,
        translated_fields: filteredFields,
      };
    });
  }

  private _selectOnlyWithNameStep(data: ILanguageManagement[]): ILanguageManagement[] {
    return data.map(data => {
      const filteredFields = Object.fromEntries(
        Object.entries(data.translated_fields).filter(([key]) => key.includes('Step'))
      );
      return {
        ...data,
        translated_fields: filteredFields,
      };
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._checkOverflow();
    }, 0);
  }

  onSelectStep(step: IChainStep): void {
    this.selectedStep = step;
    this.isSelected = false;
  }

  onSelectSupplyChain(): void {
    this.isSelected = true;
    this.selectedStep = undefined;
  }

  handleLanguageManagementArrayChange(arrayForPut: ILanguageManagementPutRequest[]) {
    this._languageManagementFacade.putSupplyChainForTranslation$(this.supply_chain.uuid, arrayForPut).subscribe({
      next: this.handleSupplyChainTranslationSuccess.bind(this),
      error: this.handleSupplyChainTranslationError.bind(this),
    });
  }

  private handleSupplyChainTranslationSuccess() {
    this._snackbarService.openTypeSnackbar('Translations saved successfully', NotificationType.success);
    this._languageManagementService.triggerSupplyChainRefresh(true);
    if (this.selectedChainUuid) {
      this._languageManagementService.triggerTotalCounterRefresh(true);
    }
  }

  private handleSupplyChainTranslationError(error: ILanguageManagementPutRequestError) {
    this._snackbarService.openTypeSnackbar(error.detail[0], NotificationType.error);
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onSaveTranslations(): void {
    this.childComponent.sendToParent();
    this._dialogRef.close(true);
  }

  scroll(element: HTMLElement, direction: number) {
    element.scrollBy({ left: 180 * direction, behavior: 'smooth' });
    setTimeout(() => this._checkScroll(element), 300); // Adjust icon visibility after scrolling
  }

  /////////////////////////////////////////////Private methods///////////////////////////////////////////

  // Check if the list has overflow and update arrow visibility
  private _checkOverflow(): void {
    const element = this.list?.nativeElement;
    this.isVisibleRight = this._canScrollEnd(element);
    this.isVisibleLeft = this._canScrollStart(element);
  }

  // Update the visibility of arrows based on scroll position
  private _checkScroll(element: HTMLElement): void {
    this.isVisibleRight = this._canScrollEnd(element);
    this.isVisibleLeft = this._canScrollStart(element);
  }

  private _canScrollStart(element: HTMLElement): boolean {
    return element?.scrollLeft > 0;
  }

  private _canScrollEnd(element: HTMLElement): boolean {
    return element?.scrollLeft + element?.clientWidth < element?.scrollWidth;
  }
}

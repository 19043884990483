import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import {
  IPublicationProduct,
  IPublicationProductsListResponse,
} from '@app/core/interface/publication-management.interface';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-products-publication',
  templateUrl: './products-publication.component.html',
  styleUrl: './products-publication.component.scss',
  providers: [PublicationManagementFacade],
})
export class ProductsPublicationComponent implements OnInit {
  readonly _publicationManagementFacade = inject(PublicationManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly breakpointObserver = inject(BreakpointObserver);
  readonly _changeDetectorRef = inject(ChangeDetectorRef);
  productsList!: IPublicationProduct[];
  cardDataSource = new MatTableDataSource<IPublicationProduct>();
  productsObservable$!: Observable<IPublicationProduct[]>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  small_screen = false;

  ngOnInit(): void {
    this.getPublicationProducts();
  }

  getPublicationProducts(): void {
    this._publicationManagementFacade.getPublicationProducts$().subscribe({
      next: this._getPublicationProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    this.paginator.pageSize = 16;
    this.productsObservable$ = this.cardDataSource.connect();
    this.cardDataSource.paginator = this.paginator;
  }

  private _getPublicationProductsSuccess(data: IPublicationProductsListResponse): void {
    if (data && data.results.length) {
      this.productsList = data.results;
      this.cardDataSource.data = data.results;
      this._changeDetectorRef.detectChanges();
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource);
  }
}

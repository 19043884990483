<ul class="breadcrumb d-flex align-items-center">
  <span class="d-flex" [ngClass]="{ hidden: bindBreadCrumbs.length < 2 }">
    <app-custom-icon
      [icon_category]="'arrows'"
      [icon_name]="'arrow-circle-right'"
      class="back-arrow"
      (click)="goBack()"
      test-id="icon-breadcrumb_header-go_back"
    ></app-custom-icon>
  </span>
  <li class="breadcrumb-li" *ngFor="let breadcrumb of bindBreadCrumbs; let i = index" class="breadcrumb-item">
    <a routerLink="{{ breadcrumb.url }}" [attr.test-id]="'link-breadcrumb_header-' + breadcrumb.url">
      {{ breadcrumb.label ?? '' | translate | titlecase }}
    </a>
    <app-custom-icon
      [icon_category]="'arrows'"
      [icon_name]="'arrow-right-short'"
      *ngIf="bindBreadCrumbs[i + 1]"
    ></app-custom-icon>
  </li>
</ul>

import { Component, inject, OnDestroy, OnInit, signal, computed, Signal } from '@angular/core';

import { ILanguageManagementBrandsResponse } from '@app/core/interface/language-management.interface';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-publication-management',
  templateUrl: './publication-management.component.html',
  styleUrl: './publication-management.component.scss',
  providers: [LanguageManagementFacade],
})
export class PublicationManagementComponent implements OnInit, OnDestroy {
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly _router = inject(Router);
  isAdditionalLanguages = true;
  selectedChainUuid: string | null = null;
  selectedIndex = signal<number>(0);
  isRestrictedPermissionUser = signal<boolean>(false);
  constructor() {
    this.initializeState();
  }

  ngOnInit(): void {
    this.getLanguageManagementBrands();
    this._checkRestrictedPermissionUser();
  }

  private initializeState(): void {
    const navigation = this._router.getCurrentNavigation();
    const isInitialNavigation = !sessionStorage.getItem('hasNavigated');
    const selectedChainUuid = navigation?.extras?.state?.['selectedChainUuid'];
    if (isInitialNavigation && selectedChainUuid) {
      this.selectedChainUuid = selectedChainUuid;
      this.selectedIndex.set(1);
      sessionStorage.setItem('selectedIndex', String(1));
      sessionStorage.setItem('hasNavigated', 'true');
    } else {
      this.restoreSelectedIndexFromSession();
    }
  }

  private _checkRestrictedPermissionUser(): void {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const isRestrictedUser = user?.is_restricted_permission;

    if (isRestrictedUser) {
      this.selectedIndex.set(1);
      this.isRestrictedPermissionUser.set(true);
    }
  }

  getLanguageManagementBrands(): void {
    this._languageManagementFacade.getLanguageManagementBrands$().subscribe({
      next: this._getLanguageManagementBrandsListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getLanguageManagementBrandsListSuccess(data: ILanguageManagementBrandsResponse): void {
    if (data && data.results.length) {
      this.isAdditionalLanguages = data.results[0].is_additional_languages;
    }
  }

  contentClass: Signal<string> = computed(() => {
    return this.selectedIndex() === 1 ? 'h-100' : 'h-100-1';
  });

  tabClass: Signal<string> = computed(() => {
    return this.selectedIndex() === 1 ? 'minHeightTab2' : 'minHeightTab1';
  });

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  onTabChange(index: number): void {
    this.selectedIndex.set(index);
    sessionStorage.setItem('selectedIndex', String(index));
  }

  private restoreSelectedIndexFromSession(): void {
    const storedIndex = sessionStorage.getItem('selectedIndex');
    if (storedIndex !== null) {
      this.selectedIndex.set(Number(storedIndex));
    }
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('hasNavigated');
    sessionStorage.removeItem('selectedIndex');
  }
}

<div class="dialog-wrapper d-flex">
  <!-- SEARCH WRAPPER -->
  <div class="search-wrapper">
    <div class="title-sm-wrapper">
      <h6 class="h6-strong pb-3">{{ data.product.name }}</h6>
    </div>
    <div class="search-card d-flex flex-column">
      <p class="p1-normal pe-5">
        {{ 'Please search for a batch reference to see the specific batch information' | translate }}
      </p>

      <form class="search-form">
        <mat-form-field>
          <input
            class="input-search"
            matInput
            placeholder="Search by batch reference..."
            [matAutocomplete]="auto"
            [formControl]="myControl"
          />
          <mat-autocomplete
            isOpen="'false'"
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onBatchChange($event.option.value)"
          >
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option.external_reference }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
  <!-- BATCH WRAPPER -->
  <div class="batch-wrapper">
    <div class="batch-inner d-flex flex-column">
      <h4 class="h4-strong">{{ data.product.name }}</h4>

      <h6 class="h6-strong">{{ 'Batch information' | translate }}</h6>
      <p class="p1-normal pt-0">{{ 'Batch reference:' | translate }}</p>
      <p class="p1-normal p1-royal">{{ selectedBatch?.external_reference }}</p>
      <p class="p1-normal pt-3">{{ 'Batch date:' | translate }}</p>
      <div class="d-flex" [ngClass]="{ 'gap-3': selectedBatch?.date_order !== null }">
        <p *ngIf="selectedBatch" class="p1-normal p1-royal">{{ selectedBatch.date_order }}</p>
        <p *ngIf="isDisplaySmall()">/</p>
        <p class="p1-normal p1-royal">{{ selectedBatch?.date_delivered }}</p>
      </div>
      <p class="p1-normal pt-3">{{ 'Quantity:' | translate }}</p>
      <p *ngIf="selectedBatch" class="p1-normal p1-royal">
        {{ selectedBatch.quantity ?? '/' }} {{ selectedBatch.quantity_unit?.symbol ?? '' }}
      </p>

      <p class="p1-normal p1-royal pt-3">
        {{ selectedBatch?.free_text }}
      </p>
    </div>
  </div>
  <app-custom-icon
    mat-dialog-close
    class="close-circle"
    [icon_category]="'essential'"
    [icon_name]="'close-circle'"
  ></app-custom-icon>
</div>

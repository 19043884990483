import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  infoDialogHeight,
  infoDialogWidth,
  NotificationType,
  OFFICE_FILENAME_EXTENSIONS,
  uploadFileSize,
} from '@app/core/constants';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import {
  ICertificate,
  ICertificatesResponse,
  ICertificatsContentType,
  IContentTypeURL,
} from '@app/core/interface/certificates.interface';
import { ICertificationProduct } from '@app/core/interface/products.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { CertificatesGroup } from '../certificates.group';
import { Observable, takeWhile } from 'rxjs';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { ICertificationSupplier } from '@app/core/interface/suppliers.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CertificateDashboardComponent } from '../certificate-dashboard/certificate-dashboard.component';
import { DateAdapter } from '@angular/material/core';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { transformTrustURL } from '@app/core/utils/transform-trust-url';
@Component({
  selector: 'app-certification-dashboard',
  templateUrl: './certification-dashboard.component.html',
  styleUrls: ['./certification-dashboard.component.scss'],
  providers: [CertificatesGroup, CertificatesFacade],
})
export class CertificationDashboardComponent implements OnInit, OnDestroy {
  @Input() certification!: ICertificationProduct;
  entityForCertification: ICertificatsContentType = 'Product';
  form!: UntypedFormGroup;
  certificates: Array<ICertificate> = [
    {
      name: '',
      category: {
        name: '',
        uuid: '',
      },
      uuid: '',
    },
  ];
  certificatesFilterList: Array<ICertificate> = [];
  docURL = '';
  docURLTransformed: SafeResourceUrl = '';
  fileName? = '';
  mode = 'Create';
  viewer: 'url' | 'office' = 'office';
  minEnd!: Date;
  alive = true;
  inputFilter!: ElementRef;
  supplierSitesView = false;
  toggleArray: Array<boolean> = new Array<boolean>();
  numberOfManufactories!: number;
  manufactoriesUuidArray: Array<string> = new Array<string>();
  opened = false;
  isOptionSelected = false;
  expired!: boolean | undefined;
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  filteredOptions!: Observable<string[]>;
  activeCertificates: Array<ICertificate> = [];
  warn = false;
  constructor(
    public dialogRef: MatDialogRef<CertificationDashboardComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      content_type: ICertificatsContentType;
      uuid: string;
      element?: ICertificationProduct;
      manufactories?: IManufactory[];
      alreadySelected?: string[];
      certificatesView?: boolean;
    },
    private readonly _group: CertificatesGroup,
    private readonly _certificatesFacade: CertificatesFacade,
    private readonly _snackbarService: SnackbarService,
    public datepipe: DatePipe,
    public sanitizer: DomSanitizer,
    private readonly _dialog: MatDialog,
    private readonly _facade: CertificatesFacade,
    private readonly dateAdapter: DateAdapter<Date>
  ) {
    if (this.data) this.entityForCertification = this.data.content_type;
    if (this.data.element) {
      this.mode = 'Update';
      this.expired = this.data.element.expired;
    }
    this.form = this._group.certificationForm;
    this.setManufactoriies();
    this.dateAdapter.setLocale('en-in'); // DD/MM/YYYY
  }

  setManufactoriies() {
    if (this.data.manufactories) {
      this.numberOfManufactories = this.data.manufactories?.length;
      if (this.mode === 'Update') {
        this.supplierSitesView = true;

        this.data.manufactories.forEach(el => {
          el.toggleStatus = false;
          if (this.data.element?.manufactories) {
            this.data.element?.manufactories.forEach((element: IManufactory) => {
              element.toggleStatus = true;
              if (element.toggleStatus == true) {
                const sitesUuid = element.uuid;
                const elementExists = this.manufactoriesUuidArray.includes(sitesUuid);
                if (!elementExists) {
                  this.manufactoriesUuidArray.push(element.uuid);
                  this.setSitesUuid(this.manufactoriesUuidArray);
                }
              }

              if (el.uuid === element.uuid && element.toggleStatus === true) {
                el.toggleStatus = true;
              }
            });
          }
        });
        this.setToggleArrayValue();
      } else {
        this.data.manufactories.forEach((el: IManufactory) => {
          el.toggleStatus = false;
        });
      }
    }
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  setToggleArrayValue() {
    for (let i = 0; i < this.numberOfManufactories; i++) {
      this.toggleArray.push(false);
    }
  }
  /**
   * On OnInit lifecyclehook we are getting filteregd Certificates using contyent_type and
   * set mode (used in title for the create / update btn)
   */
  ngOnInit(): void {
    this._certificatesFacade.getFilteredCertificates$(this.data.content_type).subscribe({
      next: this._success.bind(this),
      error: this._errorGetCert.bind(this),
    });
    this.form.controls['validity_start'].valueChanges.pipe(takeWhile(() => this.alive)).subscribe(date => {
      this.minEnd = date;
      date ? this.form.controls['validity_end'].enable() : this.form.controls['validity_end'].disable();
      if (!date && this.form.controls['validity_end'].value) {
        this.form.controls['validity_end'].patchValue(null);
      }
    });
  }

  ngAfterViewInit() {
    this.trigger?.panelClosingActions.subscribe(e => {
      if (!e?.source) {
        // clear value if is not from the filtered list
        this.form.get('certificate')?.setValue('');
        this.trigger.closePanel();
        this.opened = false;
        this.certificates = this.certificatesFilterList;
      }
    });
  }
  /**
   *
   * @param file Sets file as form value for document control
   */
  saveDocumentData(file?: File): void {
    this.form.get('document')?.setValue(file);
    this.form.get('document')?.markAsDirty();
  }
  /**
   * Submits the form , if element is present we fire update API if not Create ...
   */
  submit(): void {
    if (this.data.element) {
      this._certificatesFacade
        .updateCertification$(
          this.data.uuid,
          this.data.element.uuid,
          this.getTypeURL(this.data.content_type),
          this.requestPayload()
        )
        .subscribe({
          next: this._submitSuccess.bind(this),
          error: this._error.bind(this),
        });
    } else {
      this._certificatesFacade
        .createCertification$(this.requestPayload(), this.getTypeURL(this.data.content_type), this.data.uuid)
        .subscribe({
          next: this._submitSuccess.bind(this),
          error: this._error.bind(this),
        });
    }
  }

  /**
   *
   * @returns request payload values
   */
  requestPayload(): Record<string, string> {
    this.form.controls['certificator'].markAsDirty();
    if (!this.form.controls['certificator'].value) this.form.controls['certificator'].setValue('');
    this.form.controls['validity_start'].markAsDirty();
    this.form.controls['validity_end'].markAsDirty();
    const submitForm = GetDirtyValues(this.form);

    if (this.form.controls['manufactories'].value.value && this.data.content_type === 'Supplier')
      submitForm['manufactories'] = this.form.controls['manufactories'].value.value?.map(
        (site: IManufactory) => site.uuid
      );

    submitForm['certificate'] = this.form.controls['certificate'].value.uuid;
    if (submitForm['validity_start']) {
      submitForm['validity_start'] =
        this.datepipe.transform(new Date(submitForm['validity_start']), 'yyyy-MM-dd') ?? '';
    } else {
      submitForm['validity_start'] = '';
    }
    if (submitForm['validity_end']) {
      submitForm['validity_end'] = this.datepipe.transform(new Date(submitForm['validity_end']), 'yyyy-MM-dd') ?? '';
    } else {
      submitForm['validity_end'] = '';
    }
    if (this.form.controls['certificate']?.value?.category?.name?.trim().toUpperCase() === 'AUDIT') {
      delete submitForm['validity_start'];
      delete submitForm['validity_end'];
    }
    return submitForm;
  }
  /**
   *
   * @param content_type
   * @returns
   */
  getTypeURL(content_type: ICertificatsContentType): IContentTypeURL {
    let url = 'products';
    if (content_type === 'Brand') url = 'brands';
    if (content_type === 'Supplier') url = 'suppliers';
    if (content_type === 'Manufactory') url = 'manufactories';
    if (content_type === 'Step') url = 'steps';
    return url as IContentTypeURL;
  }
  /**
   *
   * @param event on file selection from event we are setting the file to blob and
   * create SafeResourceUrl if file is selected , so user can have option to open in new tab
   */
  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const file: File | undefined = inputElement.files?.[0];
    this.fileName = this.docURL = this.docURLTransformed = '';
    if (file) {
      this.fileName = file.name;
      if (file.size > uploadFileSize) {
        this.warn = true;
      } else {
        this.setView(this.fileName);
        file.arrayBuffer().then(arrayBuffer => {
          const blob = new Blob([new Uint8Array(arrayBuffer)], {
            type: file.type,
          });
          this.docURL = URL.createObjectURL(blob);
          this.docURLTransformed = transformTrustURL(this.docURL, this.sanitizer);
        });
        this.saveDocumentData(file);
      }
    } else {
      this.saveDocumentData();
    }
  }

  /**
   *
   * @param filename depending on extension from the filename we set viewer property
   */
  setView(filename: string) {
    const extIndex = filename.split('.').length - 1;
    const ext = filename.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      this.viewer = 'office';
    } else {
      this.viewer = 'url';
    }
  }
  /**
   *
   * clear alredy uploaded file
   */

  clearFile(): void {
    this.docURLTransformed = '';
    this.warn = false;
  }

  /**
   *
   * @param element if we have element we are calling this method to set the values in the form(edit scenario)
   */
  setForm(element: ICertificationProduct | ICertificationSupplier) {
    this.form.controls['certificate'].patchValue(this.getCertificateById(element.certificate.uuid));
    this.form.controls['certificator'].patchValue(element.certificator);
    this.form.controls['document'].patchValue(element.document);
    if (element.manufactories) this.form.controls['manufactories'].patchValue(element.manufactories);
    if (element.document) this.fileName = element.document.split('/').at(-1);
    this.docURLTransformed = this.docURL = element.document;
    this.setView(element.document);
    if (element.validity_start) {
      this.form.controls['validity_start'].patchValue(element.validity_start);
      this.minEnd = new Date(element.validity_start);
    }
    if (element.validity_end) this.form.controls['validity_end'].patchValue(element.validity_end);
  }
  /**
   *
   * @param id certificate id
   * @returns hole certificate obj depending on it's id
   */
  getCertificateById(id?: string): ICertificate | undefined {
    return this.certificates.find(res => res.uuid === id);
  }
  /**
   * On succesfull submit we close the modal with true in order to refresh the data in component that was invoked the dialog
   */
  private _submitSuccess(): void {
    this.dialogRef.close(true);
  }
  /**
   *
   * @param data this method is invoked on successfully getting the certificates ,sets the certificates and
   * if element is present(edit mode) sets the values in the form calling setForm
   */
  private _success(data: ICertificatesResponse): void {
    this.certificates = data.results;
    this.certificatesFilterList = data.results;
    this.activeCertificates = this.certificates.filter(certificate => certificate?.is_active);
    if (this.data.element) this.setForm(this.data.element);
  }
  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _errorGetCert(error: Record<string, string[]>): void {
    this.certificates = [];
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
  /**
   *
   * @param list List of selected manufactories that will be mapped with their ID's in order to save the list
   */
  setSites(list: IManufactory[]) {
    this.form.controls['manufactories'].setValue(list.map(res => res.uuid));
    this.form.controls['manufactories'].markAsDirty();
  }

  setSitesUuid(list: string[]) {
    this.form.controls['manufactories'].setValue(list);
    this.form.controls['manufactories'].markAsDirty();
  }

  /**
   * Delete Certificate using id
   */
  deleteCertificate(): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete certificate ${this.data.element?.document}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._facade.deleteCertificate$(this.data.uuid).subscribe({
          // next: this._navigateToCertificates.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }
  filterItem(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.certificates = this.activeCertificates;
    } else {
      this.certificates = this.activeCertificates.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  onChange($event: MatSlideToggleChange, i: number, uuid: string) {
    this.toggleArray[i] = !this.toggleArray[i];
    if ($event.checked) {
      this.manufactoriesUuidArray.push(uuid);
      this.setSitesUuid(this.manufactoriesUuidArray);
    }
    if (!$event.checked) {
      this.manufactoriesUuidArray.forEach((element: string, index: number) => {
        if (element === uuid) this.manufactoriesUuidArray.splice(index, 1);
        this.setSitesUuid(this.manufactoriesUuidArray);
      });
    }
  }

  openOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  onClosedEvent() {
    this.opened = false;
  }

  isOpened() {
    this.opened = true;
  }

  displayFn(subject: ICertificate | null): string {
    return subject?.name ?? '';
  }

  createEditCertificateType(uuid: string | null): void {
    const dialogRef = this._dialog.open(CertificateDashboardComponent, {
      width: '1160px',
      height: '768px',
      autoFocus: false,
      data: uuid,
      panelClass: 'top-padding-0',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
  getCheckBoxvalue(event: MatCheckboxChange) {
    this.supplierSitesView = event.checked;
    if (!this.supplierSitesView && this.mode == 'Create') {
      this.manufactoriesUuidArray = [];
    }
  }
}

import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagement,
  ILanguageManagementProduct,
  ILanguageManagementPutRequest,
  ILanguageManagementPutRequestError,
} from '@app/core/interface/language-management.interface';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { LanguageManagementTableComponent } from '@app/shared/components/language-management-table/language-management-table.component';

@Component({
  selector: 'app-product-translation-dialog',
  templateUrl: './product-translation-dialog.component.html',
  styleUrl: './product-translation-dialog.component.scss',
  providers: [LanguageManagementFacade],
})
export class ProductTranslationDialogComponent implements OnInit {
  public modelName: string = '';
  public productDataForTranslate: ILanguageManagement[] = [];
  @ViewChild('childComponent') childComponent!: LanguageManagementTableComponent;
  product!: ILanguageManagementProduct;
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  selectedChainUuid!: string;
  readonly _languageManagementService = inject(LanguageManagementService);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      product: ILanguageManagementProduct;
      productDataForTranslate: ILanguageManagement[];
      modelName: string;
      selectedChainUuid: string;
    },
    private _dialogRef: MatDialogRef<ProductTranslationDialogComponent>,
    private _snackbarService: SnackbarService,
    private _languageManagementFacade: LanguageManagementFacade
  ) {}
  ngOnInit(): void {
    this.modelName = this._data.modelName;
    this.product = this._data.product;
    this.productDataForTranslate = this._data.productDataForTranslate;
    this.selectedChainUuid = this._data.selectedChainUuid;
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onSaveTranslations(): void {
    this.childComponent.sendToParent();
    this._dialogRef.close(true);
  }

  handleLanguageManagementArrayChange(arrayForPut: ILanguageManagementPutRequest[]) {
    this._languageManagementFacade.putProductForTranslation$(this.product.uuid, arrayForPut).subscribe({
      next: this.handleTranslationSuccess.bind(this),
      error: this.handleTranslationError.bind(this),
    });
  }

  private handleTranslationSuccess() {
    this._snackbarService.openTypeSnackbar('Translations saved successfully', NotificationType.success);
    this._languageManagementService.triggerProductRefresh(true);
    if (this.selectedChainUuid) {
      this._languageManagementService.triggerTotalCounterRefresh(true);
    }
  }

  private handleTranslationError(error: ILanguageManagementPutRequestError) {
    this._snackbarService.openTypeSnackbar(error.detail[0], NotificationType.error);
  }
}

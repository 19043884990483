import { Component, Input, inject } from '@angular/core';
import {
  ILanguageManagement,
  ILanguageManagementResponse,
  ILanguageManagementSupplier,
} from '@app/core/interface/language-management.interface';

import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupplierTranslationDialogComponent } from '../supplier-translation-dialog/supplier-translation-dialog.component';

@Component({
  selector: 'app-supplier-translations-card',
  templateUrl: './supplier-translations-card.component.html',
  styleUrl: './supplier-translations-card.component.scss',
  providers: [LanguageManagementFacade],
})
export class SupplierTranslationsCardComponent {
  @Input() supplier!: ILanguageManagementSupplier;
  @Input() selectedChainUuid!: string | null;
  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly _dialog = inject(MatDialog);

  suppliereDataFromBackend!: ILanguageManagementResponse;
  supplierDataForTranslate: ILanguageManagement[] = [];
  isMissing = true;
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  modelName!: string;

  isTranslationComplete(supplier: ILanguageManagementSupplier): boolean {
    return supplier.translated_fields === supplier.total_fields;
  }
  manageTranslations(supplier: ILanguageManagementSupplier): void {
    this.getSupplierForTranslation(supplier);
  }

  getSupplierForTranslation(supplier: ILanguageManagementSupplier): void {
    this._languageManagementFacade.getSupplierForTranslation$(supplier.uuid).subscribe({
      next: data => this._getSupplierTranslateSuccess(data, supplier),
      error: this._error.bind(this),
    });
  }

  ////////////////////////////////////////Private Methods////////////////////////////////////////

  private _getSupplierTranslateSuccess(data: ILanguageManagementResponse, supplier: ILanguageManagementSupplier): void {
    this.suppliereDataFromBackend = data;

    this.supplierDataForTranslate = [
      {
        ...this.suppliereDataFromBackend.default_translation,
        uuid: 'default',
        count_translated_fields: 0,
      },
      ...this.suppliereDataFromBackend.translations,
    ];
    this.modelName = 'Supplier data';

    this._openDialog(supplier);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _openDialog(supplier: ILanguageManagementSupplier): void {
    const dialogRef = this._dialog.open(SupplierTranslationDialogComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: {
        supplier: supplier,
        supplierDataForTranslate: this.supplierDataForTranslate,
        modelName: this.modelName,
        selectedChainUuid: this.selectedChainUuid,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        dialogRef.close();
      }
    });
  }
}

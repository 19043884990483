<div class="wrapper">
  <div class="info">
    <div class="image">
      <img class="avatar" [src]="supplier?.logo ?? imgPlaceholder" alt="" />
    </div>

    <div class="text">
      <p class="p-strong">{{ supplier?.name }}</p>

      <div class="actions">
        <app-custom-icon
          id="settings"
          [icon_category]="'settings'"
          [icon_name]="'setting-4'"
          class="cursor-pointer"
          matTooltip="{{ 'Supplier visibility setup' | translate }}"
          matTooltipClass="custom-tooltip-center"
          matTooltipPosition="above"
          (click)="openSetupSupplier()"
        ></app-custom-icon>
      </div>
    </div>
  </div>

  <div class="toggle-holder">
    <div class="checkbox d-flex align-items-center">
      <mat-checkbox class="checkbox-primary" (change)="onConfidentialChange($event)" [checked]="supplier?.is_hidden">
        {{ 'Confidential Supplier' | translate }}
      </mat-checkbox>
    </div>
    <div class="horizontal-line"></div>
    <div class="toggle-wrapper d-flex align-items-center" [class.disabled]="supplier?.is_hidden">
      <div class="label" [class.disabled]="supplier?.is_hidden">
        {{ 'Supplier name' | translate }}
      </div>
      <mat-slide-toggle
        class="small-slide-toggle"
        [disabled]="supplier?.is_hidden"
        [checked]="!supplier?.is_name_private"
        (change)="onToggleChange('is_name_private', $event)"
      ></mat-slide-toggle>
    </div>
    <div class="toggle-wrapper d-flex align-items-center" [class.disabled]="supplier?.is_hidden">
      <div class="label" [class.disabled]="supplier?.is_hidden">
        {{ 'Supplier description' | translate }}
      </div>
      <mat-slide-toggle
        class="small-slide-toggle"
        [disabled]="supplier?.is_hidden"
        [checked]="!supplier?.is_description_private"
        (change)="onToggleChange('is_description_private', $event)"
      ></mat-slide-toggle>
    </div>
  </div>
</div>

<h2 mat-dialog-title>
  {{ title | translate }}
</h2>
<mat-dialog-content class="col-12 right-side">
  <!------------------------- No custom fields ------------------------->
  <div *ngIf="!customFields.length && !customFieldsData.length; else tabs">
    <app-create-product-form [mode]="data?.mode" [form]="form"> </app-create-product-form>
  </div>

  <!---------------------- if custom fields present --------------------->
  <ng-template #tabs>
    <mat-tab-group
      preserveContent
      (selectedTabChange)="tabChanged($event)"
      [ngClass]="data?.mode === 'edit' ? '' : 'matTabHeight'"
    >
      <!-- Standard fields tab -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="custom-tab-label">
            <app-custom-icon icon_category="grid" icon_name="grid-6"></app-custom-icon>
            <p class="p1-strong m-0">{{ 'Standard Fields' | translate }}</p>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-create-product-form [mode]="data?.mode" [form]="form"></app-create-product-form>
        </ng-template>
      </mat-tab>
      <!-- Custom fields tab -->
      <mat-tab
        test-id="tab-create_product_dialog-custom_fields"
        [label]="'Custom Fields' | translate"
        [disabled]="form.invalid"
      >
        <ng-template mat-tab-label>
          <div class="custom-tab-label" (click)="form.get('name')?.markAsTouched()">
            <app-custom-icon icon_category="grid" icon_name="element-plus"></app-custom-icon>
            <p class="p1-strong m-0">{{ 'Custom Fields' | translate }}</p>
            <div [ngClass]="showRedCircle ? 'red-circle' : 'd-none'"></div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-custom-fields-form
            [customFields]="customFields"
            [customFieldsData]="customFieldsData"
            [is_editing]="!!data"
            [form]="form"
          ></app-custom-fields-form>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end" class="d-flex">
  <app-rounded-button class="close-btn" mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Cancel'">
  </app-rounded-button>
  <app-rounded-button
    [color]="data?.mode === 'edit' ? 'turquoise' : 'primary'"
    [button_text]="btn"
    [icon_category]="'product'"
    [icon_name]="'3d-cube-scan'"
    [icon_right]="true"
    [size]="'md'"
    [disabled]="(form?.statusChanges | async) !== 'VALID' || form?.pristine"
    (click)="
      this.product ? (data?.mode === 'duplicate' ? cloneExistingProduct() : updateExistingProduct()) : createProduct()
    "
  >
  </app-rounded-button>
</mat-dialog-actions>

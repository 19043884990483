<div class="brand-holder d-flex flex-column justify-content-between">
  <div class="content-wrap">
    <p class="p-strong">{{ 'Here you can add different language translations to your brand' | translate }}</p>
    <div class="brand-content" *ngFor="let brand of languageManagementBrandsList">
      <div class="card d-flex">
        <div class="image">
          <img class="avatar" [src]="brand?.logo ?? imgPlaceholder" alt="" />
        </div>
        <div class="info">
          <div class="name-icon">
            <div class="name">
              <p class="p-normal">{{ brand?.name }}</p>
            </div>

            <div class="icon">
              <app-custom-icon
                class="cursor-pointer"
                [icon_category]="'essential'"
                [icon_name]="'translate'"
                (click)="manageTranslations(brand.uuid)"
              ></app-custom-icon>
            </div>
          </div>
          <div
            class="translation-progress"
            [ngClass]="{
              'progress-complete': isTranslationComplete(brand),
              'progress-incomplete': !isTranslationComplete(brand),
            }"
          >
            <p class="txt-strong">{{ 'Translations progress:' }}</p>
            <span class="txt">{{ brand?.translated_fields + '/' + brand?.total_fields }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of brands"></mat-paginator>
  </div>
</div>

import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import {
  ILanguageManagement,
  ILanguageManagementPutRequest,
  ITranslateTable,
  ITranslateTableField,
  ITranslateTableHeader,
} from '@app/core/interface/language-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-language-management-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    CdkTableModule,
    MatButtonModule,
    SharedModule,
  ],
  templateUrl: './language-management-table.component.html',
  styleUrl: './language-management-table.component.scss',
})
export class LanguageManagementTableComponent implements OnInit {
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<ITranslateTable>([]);

  @Input() modelName: string = '';
  @Input() languageManagementData: ILanguageManagement[] = [];
  @Output() languageManagementArrayChange = new EventEmitter<ILanguageManagementPutRequest[]>();

  tableHeader: ITranslateTableHeader[] = [];
  countTranslatedFields: number = 0;
  fields: ITranslateTable[] = [];
  languageCodes: string[] = [];

  languageManagmentPutRequestArray: ILanguageManagementPutRequest[] = [];

  sendToParent() {
    this._onSubmit();
    this.languageManagementArrayChange.emit(this.languageManagmentPutRequestArray);
  }

  snackbarService = inject(SnackbarService);
  fb = inject(FormBuilder);
  translateForm: FormGroup = this.fb.group({
    fields: this.fb.array([]),
  });

  ngOnInit(): void {
    this._countTranslateFields();
    this._mapTableHeader();
    this._getDispalyedColumns();
    this._mapTranslatedData();
    this.dataSource = new MatTableDataSource(this.fields);
    this._populateForm();
  }

  getFieldControlAsString(index: number, controlName: string): string {
    return this._getFieldControl(index, controlName).value?.replace(/\d/g, '');
  }

  getTranslationControl(fieldIndex: number, langIndex: number): FormControl {
    const fieldsArray = this.translateForm.get('fields') as FormArray;
    const group = fieldsArray.at(fieldIndex) as FormGroup;
    const translationsArray = group.get('translateField') as FormArray;
    const translationGroup = translationsArray.at(langIndex) as FormGroup;
    return translationGroup.get('text') as FormControl;
  }

  getRichTextControl(fieldIndex: number, langIndex: number): FormControl {
    const fieldsArray = this.translateForm.get('fields') as FormArray;
    const group = fieldsArray.at(fieldIndex) as FormGroup;
    const translationsArray = group.get('translateField') as FormArray;
    const translationGroup = translationsArray.at(langIndex) as FormGroup;
    return translationGroup.get('richText') as FormControl;
  }

  /////////////////////////////////////////////////PRIVATE METHODS///////////////////////////////////////////////

  private _getFieldControl(index: number, controlName: string): FormControl {
    const fieldsArray = this.translateForm.get('fields') as FormArray;
    const group = fieldsArray.at(index) as FormGroup;
    return group.get(controlName) as FormControl;
  }

  private _populateForm(): void {
    const fieldsArray = this.translateForm.get('fields') as FormArray;
    this.fields.forEach(field => {
      const group = this.fb.group({
        fieldName: [field.fieldName],
        translateField: this.fb.array(
          this.languageCodes.map(lang =>
            this.fb.group({
              languageCode: [lang],
              text: [this._getTextByLanguage(field, lang)],
              //when backend is ready we will get the richText value from the backend
              //richText: [field.translateField.find(f => f.languageCode.toLowerCase() === lang)?.richText || false],

              // For now, the backend does not provide a field for richText.
              // As a temporary solution, we handle it by enabling richText for specific field names ('description', 'summary', 'traceability_commitment').
              richText: [
                field.fieldName &&
                  ['description', 'summary', 'commitment'].some(keyword => field.fieldName.includes(keyword)),
              ],
              uuid: [field.translateField.find(f => f.languageCode.toLowerCase() === lang)?.uuid || ''],
            })
          )
        ),
      });
      fieldsArray.push(group);
    });
  }

  private _getTextByLanguage(element: ITranslateTable, languageCode: string): string {
    const field = element.translateField.find(f => f.languageCode.toLowerCase() === languageCode);
    return field ? field.text : '';
  }

  private _countTranslateFields(): void {
    this.languageManagementData.forEach(language => {
      const counterFields = Object.keys(language?.translated_fields).length;
      this.countTranslatedFields = counterFields;
    });
  }

  private _mapTranslatedData(): void {
    for (let index = 0; index < this.countTranslatedFields; index++) {
      this.fields.push({
        fieldName: Object.keys(this.languageManagementData[0].translated_fields)[index],
        translateField: this.languageManagementData.map(language => {
          return {
            languageCode: language.language_code,
            text: language.translated_fields[Object.keys(language.translated_fields)[index]],
            richText: false,
            uuid: language.uuid,
          };
        }),
      });
    }
  }

  private _getDispalyedColumns(): void {
    const languageCodes = this.languageManagementData.map(language => language.language_code.toLowerCase());
    this.languageCodes = languageCodes;
    this.displayedColumns = ['fieldName', ...languageCodes];
  }

  private _mapTableHeader(): void {
    this.languageManagementData.forEach(element => {
      this.tableHeader.push({
        languageName: element.language_code.toLowerCase(),
        //When backend is ready we will get the totalRows and translatedRows from the backend
        totalRows: this.countTranslatedFields,
        translatedRows: element.count_translated_fields,
      });
    });
  }

  private _mapFormDataToPutRequest(): void {
    const formDataForMapping = this.translateForm.value;
    const putRequestArray: ILanguageManagementPutRequest[] = [];
    const requestMap = new Map<string, ILanguageManagementPutRequest>();

    formDataForMapping.fields.forEach((field: ITranslateTable) => {
      field.translateField.forEach((translateField: ITranslateTableField) => {
        if (translateField.uuid === 'default') {
          return;
        }
        if (!requestMap.has(translateField.uuid)) {
          const newRequest: ILanguageManagementPutRequest = {
            uuid: translateField.uuid,
            translated_fields: {
              [field.fieldName]: translateField.text,
            },
          };
          requestMap.set(translateField.uuid, newRequest);
          putRequestArray.push(newRequest);
        } else {
          const existingRequest = requestMap.get(translateField.uuid);
          if (existingRequest) {
            existingRequest.translated_fields[field.fieldName] = translateField.text;
          }
        }
      });
    });
    this.languageManagmentPutRequestArray = putRequestArray;
  }

  private _onSubmit(): void {
    if (this.translateForm.valid) {
      this._mapFormDataToPutRequest();
    } else {
      this.snackbarService.openTypeSnackbar(
        'Unexpected error occurred, please try again later',
        NotificationType.error
      );
    }
  }
}

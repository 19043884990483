<div class="holder d-flex flex-column">
  <div class="header d-flex justify-content-between">
    <h6 class="m-0">{{ 'Publishing supply chain' | translate }}</h6>
    <app-custom-icon
      class="cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
      mat-dialog-close
    ></app-custom-icon>
  </div>
  <p class="m-0">
    {{
      'Please note that the chain cannot be published at the moment, because there are missing translations in the additional languages selected.'
        | translate
    }}
  </p>
  <p class="m-0">
    {{
      'Please translate all of the fields or deselect the additional languages in order to publish the supply chain.'
        | translate
    }}
  </p>
  <app-rounded-button
    color="primary"
    [button_text]="'Go to languages management'"
    [size]="'md'"
    (click)="goToPublicationLanguageManagement()"
  >
  </app-rounded-button>
</div>

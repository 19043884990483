import { Component, OnInit } from '@angular/core';
import { IResetPasswordErrorResponse, IResetPasswordResponse } from '@app/core/interface/forgot-password.interface';

import { ForgotPasswordFacade } from '@app/core/facade/forgot-password.facade';
import { NotificationType } from '@app/core/constants';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-link-sent',
  templateUrl: './link-sent.component.html',
  styleUrls: ['./link-sent.component.scss'],
})
export class LinkSentComponent implements OnInit {
  email = '';

  constructor(
    private readonly _facade: ForgotPasswordFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly _router: Router
  ) {}
  ngOnInit(): void {
    this.getSessionStorage();
  }

  getSessionStorage() {
    const isSessionStorage = sessionStorage.getItem('resetPassword');
    this.email = isSessionStorage as string;
  }
  resendEmail(): void {
    this._facade
      .resetPassword$(this.email)
      .pipe(take(1))
      .subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    this._router.navigate(['password_reset/link-sent']);
    sessionStorage.setItem('resetPassword', this.email);
  }

  private _success(data: IResetPasswordResponse): void {
    this._snackbarService.openTypeSnackbar(data.detail, NotificationType.success);
  }

  private _error(error: IResetPasswordErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}

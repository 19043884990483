import { IconMapping } from './interface/unit.interface';

export const USER = 'user';
export const REDIRECT = 'redirectUrl';
export const ACCESS_TOKEN = 'access_token';
export const DUPLICATE = 'duplicate';
export const REGISTER_STEP = 'register_step';
export const PUBLISH_STEP = 'publish_step';
export const FIRST_LOGIN = 'firstLogin';
export const ADD_DOCUMENT_ALLOWED_TYPES = [
  'jpeg',
  'jpg',
  'gif',
  'tiff',
  'tif',
  'jfif',
  'png',
  'mp4',
  'webm',
  'pdf',
  'docx',
  'doc',
  'xlsx',
  'xls',
  'pptx',
  'ppt',
  // Microsoft Office MIME types for HTTPS content streaming:
  'vnd.openxmlformats-officedocument.presentationml.presentation',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const OFFICE_FILENAME_EXTENSIONS = [
  'doc',
  'dot',
  'wbk',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'docb',
  'wll',
  'wwl',
  'xls',
  'xlt',
  'xlm',
  'xll_',
  'xla_',
  'xla5',
  'xla8',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'xlsb',
  'xla',
  'xlam',
  'xll',
  'xlw',
  'ppt',
  'pot',
  'pps',
  'ppa',
  'ppam',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppam',
  'ppsx',
  'ppsm',
  'sldx',
  'sldm',
  'pa',
  'ACCDA',
  'ACCDB',
  'ACCDE',
  'ACCDT',
  'ACCDR',
  'ACCDU',
  'MDA',
  'MDE',
  'one',
  'ecf',
  'pub',
  'xps',
  'odt',
];

export const EMAIL_SUGGESTIONS = [
  { en: '{ Brand name }', fr: '{ Nom de la marque }' },
  { en: '{ Brand contact name }', fr: '{ Nom du contact de la marque }' },
  { en: '{ Supplier name }', fr: '{ Nom du fournisseur }' },
  // { en: '{ Supplier contact name }', fr: '{ Nom du contact du fournisseur }' }, // temporary removed
  { en: '{ Product name }', fr: '{ Nom du produit }' },
  { en: '{ Project name }', fr: '{ Nom du projet }' },
  { en: '{ Current date }', fr: '{ Date actuelle }' },
  { en: '{ Direct supplier name }', fr: '{ Nom du fournisseur direct }' },
  { en: '{ Step name }', fr: "{ Nom de l'étape }" },
  { en: '{ Reasons of disapproval }', fr: '{ Raisons de désapprobation }' },
  { en: '{ Batch name }', fr: '{ Nom du lot }' },
];
export const BRAND_NAME = 'brand_name';
export const BRAND_LOGO = 'brand_logo';
export enum Storage {
  local = 'localStorage',
  session = 'sessionStorage',
}

export const DefaultUserNavList = [
  {
    icon: 'element-2',
    category: 'grid',
    label: 'Dashboard',
    url: 'dashboard',
    isDisabled: false,
    id: 'dashboard',
  },
  {
    icon: '3d-cube-scan',
    category: 'product',
    label: 'Products',
    url: 'products',
    isDisabled: false,
    id: 'products',
  },
  {
    icon: 'truck',
    category: 'product',
    label: 'Suppliers',
    url: '/dashboard/supplier/supplier-list',
    isDisabled: false,
    id: 'suppliers',
  },
  {
    icon: 'routing-2',
    category: 'location',
    label: 'Supply chains',
    url: '/dashboard/product-chains',
    isDisabled: false,
    id: 'supplyChains',
  },
  {
    icon: 'task-square',
    category: 'files',
    label: 'Tasks',
    url: '/dashboard/tasks',
    isDisabled: false,
    id: 'tasks',
  },
  {
    icon: 'setting-4',
    category: 'settings',
    label: 'Publication Management',
    url: '/manage-publications',
    isDisabled: false,
    id: 'publicationManagement',
  },
  {
    icon: 'certificate',
    category: 'learning',
    label: 'Certificates',
    url: '/dashboard/certificates',
    isDisabled: false,
    id: 'certificates',
  },
];

export const QaEnvUserNavList = [
  {
    icon: 'element-2',
    category: 'grid',
    label: 'Dashboard',
    url: 'dashboard',
    isDisabled: false,
    id: 'dashboard',
  },
  {
    icon: '3d-cube-scan',
    category: 'product',
    label: 'Products',
    url: 'products',
    isDisabled: false,
    id: 'products',
  },
  {
    icon: 'truck',
    category: 'product',
    label: 'Suppliers',
    url: '/dashboard/supplier/supplier-list',
    isDisabled: false,
    id: 'suppliers',
  },
  {
    icon: 'routing-2',
    category: 'location',
    label: 'Supply chains',
    url: '/dashboard/product-chains',
    isDisabled: false,
    id: 'supplyChains',
  },
  {
    icon: 'setting-4',
    category: 'settings',
    label: 'Publication Management',
    url: '/manage-publications',
    isDisabled: false,
    id: 'publicationManagement',
  },
  {
    icon: 'certificate',
    category: 'learning',
    label: 'Certificates',
    url: '/dashboard/certificates',
    isDisabled: false,
    id: 'certificates',
  },
];

export const SupplierUserNavList = [
  {
    icon: 'element-2',
    category: 'grid',
    label: 'supplier-menu.Dashboard',
    url: '/overview',
    isDisabled: false,
    id: 'dashboard',
  },
  {
    icon: 'buildings-2',
    category: 'buildings',
    label: 'supplier-menu.Company Information',
    url: '/company-info',
    isDisabled: false,
    id: 'companyInformation',
  },
  {
    icon: '3square',
    category: 'grid',
    label: 'supplier-menu.Supply Chains Steps',
    url: '/chain-steps-info',
    isDisabled: false,
    id: 'supplyChainsSteps',
  },
];

export const RESTRICTED_PERMISSION_USER_NAV_LIST = [
  {
    icon: 'setting-4',
    category: 'settings',
    label: 'Publication Management',
    url: '/manage-publications',
    isDisabled: false,
    id: 'publicationManagement',
  },
];

export const SUPPLIER = 'supplier';
export const PRODUCT_CHAINS = 'product_chains';
export const SELECTED_LANGUAGE = 'lang';
export const DIRECT_SUPPLIER = 'direct_supplier';
export const MAIN = 'main';

export const chainStepsTutorialTooltip = {
  id: 1,
  text: 'supply-chain-step.In order to complete this step first enter the site, the place where the production or the action performed for this step takes place',
  buttonText: 'supply-chain-step.Ok, next',
  x: '0px',
  y: '0px',
  isLast: false,
};

export const VIEW_MODES = {
  PERIOD: 'period',
  DATE: 'date',
};

export const uploadFileSize = 10485760;
export const uploadMediaSize = 26214400;
export const PROJECT_MANAGEMENT = 'Project management';
export const BATCH_STEP_DISAPPROVAL = 'batch step disapproval';
export const STEP_EDIT = 'step edit request';
export const INDIRECT_SUPPLIER = 'indirect supplier';
export const FIRST_INVITE = 'first invite';
export const NEXT_INVITE = 'next invite';
export const infoDialogHeight = '460px';
export const infoDialogWidth = '580px';

export enum NotificationType {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export const iconMapping: IconMapping = {
  success: { name: 'tick-circle' },
  warning: { name: 'danger-white' },
  error: { name: 'warning-2' },
};

export const snackBarDuration = 4000;
export const NOT_APPLICABLE = 'Not applicable';
export const SELECTED_TAB_INDEX_KEY = 'selectedTabIndex'; // Key as a string
export const DEFAULT_TAB_INDEX = 0; // Default index as a number

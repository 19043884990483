<div class="main-wraper" [ngClass]="pagesize === 12 ? 'main-wraper-pagesize-12' : ''">
  <div class="header row d-flex flex-row">
    <div class="toggle-group-filters">
      <div class="toggle-group">
        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onToggleChange($event)">
          <div class="toggle">
            <mat-button-toggle value="chains" aria-label="Product Chains List" [checked]="isChainsView">
              <span>{{ 'Supply Chains List' | translate }}</span>
            </mat-button-toggle>
          </div>
          <div class="toggle">
            <mat-button-toggle value="batches" aria-label="Batches List" [checked]="!isChainsView">
              <span>{{ 'Batches List' | translate }}</span>
            </mat-button-toggle>
          </div>
        </mat-button-toggle-group>
      </div>

      <ng-container *ngIf="group.value === 'chains'">
        <div class="filters">
          <div class="filter">
            <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline">
              <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
              <input
                test-id="input-chains_batches-quick_search_chains"
                matInput
                (keyup)="onQuickFilterChanged($event)"
                placeholder="Quick search..."
              />
              <app-custom-icon
                class="search-filter"
                [icon_category]="'search'"
                [icon_name]="'search-normal-2'"
                matIconSuffix
              ></app-custom-icon>
            </mat-form-field>
          </div>
          <div class="filter">
            <mat-form-field
              class="input-primary mat-form-field-10-px-brd-rd justify-content-end custom-filter-header"
              appearance="outline"
            >
              <mat-label class="label-text">{{ 'Filter by reference number...' | translate }}</mat-label>
              <input
                test-id="input-chains_batches-filter_by_reference_number"
                matInput
                (keyup)="onReferenceNumberFilterChanged($event)"
                placeholder="Filter by reference number..."
              />
              <app-custom-icon
                class="search-filter"
                [icon_category]="'essential'"
                [icon_name]="'filter-add'"
                matIconSuffix
              ></app-custom-icon>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="group.value === 'batches'">
        <div class="filters">
          <div class="filter">
            <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline">
              <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
              <input
                test-id="input-chains_batches-quick_search_batches"
                matInput
                (keyup)="onQuickSearchBatch($event)"
                placeholder="Quick search..."
              />
              <app-custom-icon
                class="search-filter"
                [icon_category]="'search'"
                [icon_name]="'search-normal-2'"
                matSuffix
              ></app-custom-icon>
            </mat-form-field>
          </div>
          <div class="filter">
            <mat-form-field
              class="input-primary mat-form-field-10-px-brd-rd justify-content-end custom-filter-header"
              appearance="outline"
            >
              <mat-label class="label-text">{{ 'Filter by supplier batch no...' | translate }}</mat-label>
              <input
                test-id="input-chains_batches-filter_by_supplier_batch_no"
                matInput
                (keyup)="onBatchNumberFilterChanged($event)"
                placeholder="Filter by supplier batch no..."
              />
              <app-custom-icon
                class="search-filter"
                [icon_category]="'essential'"
                [icon_name]="'filter-add'"
                matSuffix
              ></app-custom-icon>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="checkbox" *ngIf="group.value === 'chains'">
      <app-rounded-button
        color="primary md"
        [button_text]="'Create supply chain'"
        [icon_category]="'location'"
        [icon_name]="'routing-2'"
        [icon_right]="true"
        (click)="createProductChain(product)"
        test-id="button-chains_batches-create_supply_chain"
      >
      </app-rounded-button>
      <mat-checkbox
        test-id="checkbox-chains_batches-show_inactive_chains"
        class="checkbox-primary"
        (change)="showInactiveChains($event)"
        [checked]="isInactiveChains"
        >{{ 'show deactivated chains' | translate }}</mat-checkbox
      >
    </div>

    <div class="checkbox justify-content-end" *ngIf="group.value === 'batches'">
      <mat-checkbox
        test-id="checkbox-chains_batches-show_inactive_batches"
        class="checkbox-primary"
        (change)="showInactiveBatches($event)"
        [checked]="isInactiveBatches"
        >{{ 'show deactivated batches' | translate }}</mat-checkbox
      >
    </div>
  </div>
  <app-chains-list
    [enabledCo2]="toggleCo2"
    [dataSource]="dataSource"
    (refreshDataSource)="getProductChains()"
    *ngIf="group.value === 'chains'"
    [pageSize]="pagesize"
  >
  </app-chains-list>
  <app-batches-list
    [dataSource]="batchDataSource"
    (refetchBatches)="getBatches()"
    *ngIf="group.value === 'batches'"
    [pageSize]="pagesize"
  >
  </app-batches-list>
</div>

<form [formGroup]="translateForm">
  <div class="table-container">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="fieldName">
        <th class="fieldName" mat-header-cell *matHeaderCellDef>{{ modelName }}</th>
        <td class="ps-1" mat-cell *matCellDef="let element; let i = index">
          {{ getFieldControlAsString(i, 'fieldName') }}
        </td>
      </ng-container>

      <ng-container *ngFor="let language of tableHeader; let langIndex = index" [matColumnDef]="language.languageName">
        <th class="default-language-name" mat-header-cell *matHeaderCellDef>
          @if (langIndex === 0) {
            <div class="default-language-header-wrap">
              <div class="default-language-name">
                {{ language.languageName | shortToFullLanguage }}
              </div>
              <div class="default-language-message align-items-center">
                <span>{{ 'Your default language' | translate }}</span>
                <app-custom-icon
                  class="info-icon"
                  [icon_category]="'essential'"
                  [icon_name]="'info-circle'"
                  matTooltip="{{
                    'To add additional languages or change the default language, please contact support' | translate
                  }}"
                  matTooltipClass="custom-tooltip-center"
                  matTooltipPosition="above"
                ></app-custom-icon>
              </div>
              <div></div>
            </div>
          } @else {
            <div class="language-header-wrap">
              <div class="language-name">
                {{ language.languageName | shortToFullLanguage }}
              </div>
              <div class="language-translated">
                {{ language.translatedRows }} / {{ language.totalRows }} {{ 'translated' | translate }}
              </div>
            </div>
          }
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          @if (langIndex !== 0) {
            @if (getRichTextControl(i, langIndex).value) {
              <app-rs-rte
                [formControl]="getTranslationControl(i, langIndex)"
                [placeholder]="'Enter translation here..' | translate"
              ></app-rs-rte>
            } @else {
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [placeholder]="'Enter translation here..' | translate"
                  [formControl]="getTranslationControl(i, langIndex)"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="0"
                  cdkAutosizeMaxRows="5"
                >
                </textarea>
              </mat-form-field>
            }
          } @else {
            @if (getRichTextControl(i, langIndex).value) {
              <app-view-rich-text [content]="getTranslationControl(i, langIndex).value"></app-view-rich-text>
            } @else {
              {{ getTranslationControl(i, langIndex).value }}
            }
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicationManagementComponent } from './publication-management.component';
import { PublicationManagementRoutingModule } from './publication-management-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductPublicationCardComponent } from './publication-setup/products-publication/product-publication-card/product-publication-card.component';
import { SuppliersPublicationComponent } from './publication-setup/suppliers-publication/suppliers-publication.component';
import { ProductsPublicationComponent } from './publication-setup/products-publication/products-publication.component';
import { LanguageManagementComponent } from './language-management/language-management.component';
import { PendingValidationsComponent } from './pending-validations/pending-validations.component';
import { PublicationSetupComponent } from './publication-setup/publication-setup.component';
import { BrandTranslationsComponent } from './language-management/brand-translations/brand-translations.component';
import { ProductTranslationsComponent } from './language-management/product-translations/product-translations.component';
import { SupplierTranslationsComponent } from './language-management/supplier-translations/supplier-translations.component';
import { SupplyChainTranslationsComponent } from './language-management/supply-chain-translations/supply-chain-translations.component';
import { CertificateTranslationsComponent } from './language-management/certificate-translations/certificate-translations.component';
import { CertificateTranslationCardComponent } from './language-management/certificate-translations/certificate-translation-card/certificate-translation-card.component';
import { ProductTranslationCardComponent } from './language-management/product-translations/product-translation-card/product-translation-card.component';
import { BrandTranslationDialogComponent } from './language-management/brand-translation-dialog/brand-translation-dialog.component';
import { LanguageManagementTableComponent } from '../../shared/components/language-management-table/language-management-table.component';
import { CertificatesTranslationDialogComponent } from './language-management/certificate-translations/certificates-translation-dialog/certificates-translation-dialog.component';
import { ProductTranslationDialogComponent } from './language-management/product-translation-dialog/product-translation-dialog.component';
import { SupplierTranslationsCardComponent } from './language-management/supplier-translations/supplier-translations-card/supplier-translations-card.component';
import { SupplierTranslationDialogComponent } from './language-management/supplier-translations/supplier-translation-dialog/supplier-translation-dialog.component';
import { SupplyChainTranslationCardComponent } from './language-management/supply-chain-translations/supply-chain-translation-card/supply-chain-translation-card.component';
import { SupplyChainTranslationDialogComponent } from './language-management/supply-chain-translations/supply-chain-translation-dialog/supply-chain-translation-dialog.component';

@NgModule({
  declarations: [
    PublicationManagementComponent,
    SuppliersPublicationComponent,
    ProductsPublicationComponent,
    ProductPublicationCardComponent,
    LanguageManagementComponent,
    PendingValidationsComponent,
    PublicationSetupComponent,
    CertificateTranslationsComponent,
    CertificateTranslationCardComponent,
    BrandTranslationsComponent,
    ProductTranslationsComponent,
    ProductTranslationCardComponent,
    BrandTranslationDialogComponent,
    CertificatesTranslationDialogComponent,
    ProductTranslationDialogComponent,
    SupplierTranslationsComponent,
    SupplierTranslationsCardComponent,
    SupplierTranslationDialogComponent,
    SupplyChainTranslationsComponent,
    SupplyChainTranslationCardComponent,
    SupplyChainTranslationDialogComponent,
  ],
  imports: [
    CommonModule,
    PublicationManagementRoutingModule,
    SharedModule,
    TranslateModule.forChild({}),
    LanguageManagementTableComponent,
  ],
})
export class PublicationManagementModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IInfoInputDialogData } from '@app/core/interface/unit.interface';

@Component({
  selector: 'app-info-input-dialog',
  templateUrl: './info-input-dialog.component.html',
  styleUrls: ['./info-input-dialog.component.scss'],
})
export class InfoInputDialogComponent implements OnInit {
  dataObject!: IInfoInputDialogData;
  reasonInput!: UntypedFormControl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IInfoInputDialogData,
    public dialogRef: MatDialogRef<IInfoInputDialogData>
  ) {
    this.reasonInput = new UntypedFormControl('', Validators.required);
  }

  ngOnInit(): void {
    this.setData();
  }

  setData(): void {
    this.dataObject = this.dialogData;
    this.dataObject.btnText = this.dialogData.btnText;
    this.dataObject.infoText = this.dialogData.infoText;
    this.dataObject.label = this.dialogData.label;
  }

  onProceed() {
    this.dialogRef.close(this.reasonInput.value);
  }
}

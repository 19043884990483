<div class="holder d-flex flex-column justify-content-between">
  <div class="header">
    <h3 class="m-0">{{ 'Manage language translations' | translate }}</h3>
  </div>
  <div class="content d-flex flex-column">
    <!-- Temporary commented -->
    <!-- <div class="title d-flex align-items-center">
      <p class="m-0 label">{{ 'Supplier translated:' | translate }}</p>
      <div
        class="d-flex align-items-center name-icon cursor-pointer"
        [ngClass]="isSelected ? 'selected' : ''"
        (click)="onSelectSupplyChain()"
      >
        <p class="m-0 cert-name">{{ supply_chain.name }}</p>
        <app-custom-icon
          [id]="isSelected ? 'tick-circle' : 'circle'"
          [icon_category]="'essential'"
          [icon_name]="isSelected ? 'tick-circle' : 'circle'"
        >
        </app-custom-icon>
      </div>
    </div> -->
    <!-- <div class="step-wrapper d-flex flex-row">
      <div class="info-holder d-flex align-items-center">
        <p class="step-text">{{ 'Chain steps' | translate }}</p>
        <app-custom-icon
          [icon_category]="'essential'"
          [icon_name]="'info-circle'"
          class="cursor-pointer info-circle"
          matTooltip="{{ 'Please select each of the supply chain steps in order to translate them' | translate }}"
          matTooltipClass="custom-tooltip-center"
          matTooltipPosition="above"
        >
        </app-custom-icon>
      </div>
      <section class="list-with-scroll">
        <div class="step-holder d-flex flex-row" id="list" #list>
          <div
            class="step-card cursor-pointer"
            [ngClass]="selectedStep === step ? 'selected-step' : 'unselected-step'"
            *ngFor="let step of chainSteps"
            (click)="onSelectStep(step)"
          >
            <div class="step-info d-flex flex-column">
              <p class="translations m-0">{{ '0/8' | translate }}</p>
              <p class="step-name m-0">{{ step?.name }}</p>
            </div>
            <app-custom-icon
              [id]="!isSelected ? 'tick-circle' : 'circle'"
              [icon_category]="'essential'"
              [icon_name]="!isSelected ? 'tick-circle' : 'circle'"
            >
            </app-custom-icon>
          </div>
        </div>

        <div class="back-btn back-to-left-button cursor-pointer" *ngIf="isVisibleLeft" (click)="scroll(list, -1)">
          <app-custom-icon
            id="back-btn-left"
            matTooltip="To the left"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-left'"
          >
          </app-custom-icon>
        </div>

        <div class="back-btn back-to-right-button cursor-pointer" *ngIf="isVisibleRight" (click)="scroll(list, 1)">
          <app-custom-icon
            id="back-btn-right"
            matTooltip="To the right"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-right-long'"
          >
          </app-custom-icon>
        </div>
      </section>

    </div> -->

    <div class="info-wrapp d-flex align-items-center">
      <p class="m-0 prod-strong">{{ 'Supply chain translated:' | translate }}</p>
      <p class="m-0 prod-normal">{{ supply_chain.name }}</p>
    </div>

    <app-language-management-table
      [modelName]="modelName"
      [languageManagementData]="supplyChainDataForTranslate"
      #childComponent
      (languageManagementArrayChange)="handleLanguageManagementArrayChange($event)"
    />
  </div>
  <div class="footer d-flex justify-content-end align-items-center">
    <app-rounded-button
      class="underline-button"
      [button_text]="'Cancel'"
      [size]="'sm'"
      color="tetriary-link underlined"
      (click)="onCancel()"
    >
    </app-rounded-button>
    <app-rounded-button
      [button_text]="'Save translation'"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
      [icon_right]="true"
      [size]="'md'"
      color="turquoise"
      (click)="onSaveTranslations()"
    >
    </app-rounded-button>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable, Subscription } from 'rxjs';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagementProduct,
  ILanguageManagementProductsResponse,
} from '@app/core/interface/language-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';
import { LanguageManagementService } from '@app/core/service/language-management.service';

@Component({
  selector: 'app-product-translations',
  templateUrl: './product-translations.component.html',
  styleUrl: './product-translations.component.scss',
  providers: [LanguageManagementFacade],
})
export class ProductTranslationsComponent implements OnInit, AfterViewInit, OnDestroy {
  isMissing: boolean = true;
  hasSupplyChainFIlter = false;
  missingTranslations!: number;
  fullyTranslated!: number;
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  product!: ILanguageManagementProduct;
  productList!: ILanguageManagementProduct[];
  cardDataSource = new MatTableDataSource<ILanguageManagementProduct>();
  productsObservable$!: Observable<ILanguageManagementProduct[]>;
  private refreshSubscription!: Subscription;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() toggleChanged = new EventEmitter<string>();
  @Input() initialToggleState: string = 'missing';
  @Input() selectedChainUuid: string | null = null;

  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _snackbarService = inject(SnackbarService);
  readonly _languageManagementService = inject(LanguageManagementService);

  ngOnInit(): void {
    this.isMissing = this.initialToggleState === 'missing';
    this.getLanguageManagementProducts(!this.isMissing);
    this.hasSupplyChainFIlter = this.selectedChainUuid !== null;
    this.subscribeToRefresh();
  }

  subscribeToRefresh(): void {
    this.refreshSubscription = this._languageManagementService.refreshProduct$.subscribe(refresh => {
      if (refresh) {
        this.getLanguageManagementProducts(!this.isMissing);
      }
    });
  }

  ngAfterViewInit() {
    this.paginator.pageSize = 8;
    this.cardDataSource.paginator = this.paginator;
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.isMissing = event.value === 'missing';
    this.toggleChanged.emit(event.value);
    if (event.value === 'fully') {
      this.getLanguageManagementProducts(true);
    } else {
      this.getLanguageManagementProducts(false);
    }
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource);
  }

  getLanguageManagementProducts(isFullyTranslated: boolean): void {
    this._languageManagementFacade.getLanguageManagementProducts$(isFullyTranslated).subscribe({
      next: this._getLanguageManagementProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  //////////////////////////////////////////////////Private methods///////////////////////////////////////////////

  private _getLanguageManagementProductsSuccess(data: ILanguageManagementProductsResponse): void {
    if (data) {
      this.productList = data.results;
      this.missingTranslations = data?.products_missing_translations ?? 0;
      this.fullyTranslated = data?.products_fully_translated ?? 0;
      this.cardDataSource.data = data.results;
      this.productsObservable$ = this.cardDataSource.connect();
      this._languageManagementService.triggerProductRefresh(false);
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}

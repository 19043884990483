import { Component, OnInit } from '@angular/core';
import { IQuestionnaire, IQuestionnaireErrorResponse } from '@interface/questionnaire.interface';

import { ActivatedRoute } from '@angular/router';
import { BreadcrumbHeaderService } from '@service/breadcrumb-header.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { QuestionnaireFacade } from '@facade/questionnaire.facade';
import { QuestionnaireNameEditModalComponent } from '@module/questionnaires-management/questionnaire-name-edit-modal/questionnaire-name-edit-modal.component';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-questionnaire-details',
  templateUrl: './questionnaire-details.component.html',
  styleUrls: ['./questionnaire-details.component.scss'],
})
export class QuestionnaireDetailsComponent implements OnInit {
  public questionnaireDetails!: IQuestionnaire;
  icon = 'saved-changes';

  constructor(
    private readonly questionnaireFacade: QuestionnaireFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly _dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly breadcrumbsService: BreadcrumbHeaderService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(snapshot => {
      const id = snapshot.get('id');
      if (id) {
        this.getQuestionnaireDetails(id);
      }
    });
  }

  private getQuestionnaireDetails(uuid: string) {
    this.questionnaireFacade.getQuestionnaireDetails$(uuid).subscribe({
      next: this.getQuestionnaireDetailsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private getQuestionnaireDetailsSuccess(data: IQuestionnaire) {
    this.questionnaireDetails = data;
    this.breadcrumbsService.setBreadCrumbs(true, data.name);
  }

  private _error(error: IQuestionnaireErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  getQuestionsCount() {
    return this.questionnaireDetails.sections.map(x => x.questions.length).reduce((sum, current) => sum + current, 0);
  }

  editName(name: string) {
    this.icon = 'saving';
    const dialogRef = this._dialog.open(QuestionnaireNameEditModalComponent, {
      data: { title: 'Edit Questionnaire Name', data: name },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.questionnaireFacade.updateQuestionnaireDetails$(this.questionnaireDetails.uuid, result).subscribe(
          result => {
            this.icon = 'saved-changes';
            this.questionnaireDetails.name = result.name;
            this.breadcrumbsService.setBreadCrumbs(true, result.name);
          },
          error => this._error(error)
        );
      }
    });
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import {
  ILanguageManagement,
  ILanguageManagementPutRequest,
  ILanguageManagementPutRequestError,
} from '@app/core/interface/language-management.interface';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { LanguageManagementTableComponent } from '@app/shared/components/language-management-table/language-management-table.component';

@Component({
  selector: 'app-brand-translation-dialog',
  standalone: false,
  templateUrl: './brand-translation-dialog.component.html',
  styleUrl: './brand-translation-dialog.component.scss',
  providers: [LanguageManagementFacade],
})
export class BrandTranslationDialogComponent implements OnInit {
  public modelName: string = '';
  public brandDataForTranslate: ILanguageManagement[] = [];
  @ViewChild('childComponent') childComponent!: LanguageManagementTableComponent;
  brandUuid: string = '';
  selectedChainUuid!: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      brandUuid: string;
      brandDataForTranslate: ILanguageManagement[];
      modelName: string;
      selectedChainUuid: string;
    },
    private _dialogRef: MatDialogRef<BrandTranslationDialogComponent>,
    private _snackbarService: SnackbarService,
    private _languageManagementFacade: LanguageManagementFacade,
    private _languageManagementService: LanguageManagementService
  ) {}

  ngOnInit(): void {
    this.modelName = this._data.modelName;
    this.brandUuid = this._data.brandUuid;
    this.brandDataForTranslate = this._data.brandDataForTranslate;
    this.selectedChainUuid = this._data.selectedChainUuid;
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onSaveTranslations(): void {
    this.childComponent.sendToParent();
    this._dialogRef.close(true);
  }

  handleLanguageManagementArrayChange(arrayForPut: ILanguageManagementPutRequest[]) {
    this._languageManagementFacade.putBrandForTranslation$(this.brandUuid, arrayForPut).subscribe({
      next: this.handleBrandTranslationSuccess.bind(this),
      error: this.handleBrandTranslationError.bind(this),
    });
  }

  private handleBrandTranslationSuccess() {
    this._snackbarService.openTypeSnackbar('Translations saved successfully', NotificationType.success);
    this._languageManagementService.triggerBrandRefresh(true);
    if (this.selectedChainUuid) {
      this._languageManagementService.triggerTotalCounterRefresh(true);
    }
  }

  private handleBrandTranslationError(error: ILanguageManagementPutRequestError) {
    this._snackbarService.openTypeSnackbar(error.detail[0], NotificationType.error);
  }
}

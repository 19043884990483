<div class="content">
  <div class="content-small-devices">
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'chevron-up-solid'"></app-custom-icon>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="d-flex flex-column justify-content-between inner mt-auto">
        <img
          (click)="onSingleImgDialog(this.product?.image ?? undefined)"
          [src]="this.product?.image ? this.product?.image : imagePlaceholder"
          alt="Product"
          class="product-img cursor-pointer"
        />
        <div class="code-wrap d-flex flex-column">
          <h6 class="carbon__title d-inline-block w-100">Respect Code</h6>
          <div class="d-flex align-items-center justify-content-start">
            <img src="./../../../../../assets/logos/symbol-dark-circle.png" class="site-logo" />
            <span class="ref-num">{{ publication_reference ?? '' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex inner flex-column align-items-start justify-content-start">
        <div class="d-flex align-items-center pb-2">
          <img *ngIf="brand?.logo" src="{{ brand?.logo }}" class="brand-logo pe-2" />
          <h3 class="">{{ brand?.name }}</h3>
        </div>

        <h1 class="word-breakAll">{{ product?.name }}</h1>
        <div *ngIf="productCertificates && productCertificates.length" class="row">
          <div class="col-12 d-flex p-0">
            <div
              *ngFor="let certification of productCertificates"
              (click)="onCertificateDialog(certification)"
              class="cursor-pointer"
            >
              <!-- {{ certification.certificate?.name }}; &nbsp; -->
              <img
                class="certificate-logo"
                src="{{ certification.certificate?.image ? certification.certificate?.image : imagePlaceholder }}"
              />
            </div>
          </div>
        </div>
        <!-- <label class="carbon__title d-inline-block w-100"
            >Crafted in Italy <br />
            Produce since 2020</label
          > -->
        <div class="mt-auto d-flex align-items-between btn_brand justify-content-end w-100">
          <button
            *ngIf="batches !== null && batches?.length"
            class="btn btn_white"
            mat-button
            (click)="onBatchInfoDialog()"
          >
            {{ 'Batch Info' | translate }}
          </button>
          <button class="btn btn_blue" mat-button (click)="openDialog()">
            <!-- // Add to the button up -->
            <!-- [ngClass]="{ 'self-margin': batches?.length == 0 }" -->
            {{ 'Product Info' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="holder d-flex flex-column">
  <h2 mat-dialog-title>
    {{ 'Manage language translations' | translate }}
  </h2>

  <div class="content d-flex flex-column">
    <mat-dialog-content>
      <div class="margin-table">
        <app-language-management-table
          [modelName]="modelName"
          [languageManagementData]="brandDataForTranslate"
          #childComponent
          (languageManagementArrayChange)="handleLanguageManagementArrayChange($event)"
        />
      </div>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions>
    <app-rounded-button
      class="underline-button"
      color="tetriary-link underlined"
      [button_text]="'Cancel'"
      [size]="'md'"
      (click)="onCancel()"
    >
    </app-rounded-button>
    <app-rounded-button
      color="success"
      [button_text]="'Save Translations'"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
      [icon_right]="true"
      [size]="'md'"
      (click)="onSaveTranslations()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>

<div class="card-holder d-flex flex-column">
  <div class="wrapper d-flex align-items-center justify-content-between">
    <div class="info-wrapp d-flex align-items-center">
      <img class="logo" [src]="product.image ? product.image : imgPlaceholder" alt="" />
      <p class="m-0 cert-name">{{ product.name }}</p>
    </div>
    <app-custom-icon
      class="cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
      (click)="manageTranslations(product)"
    ></app-custom-icon>
  </div>
  <div class="progress" [ngClass]="isTranslationComplete(product) ? 'fully' : 'missing'">
    <p class="txt-strong">{{ 'Translations progress:' }}</p>
    <span class="txt">{{ product?.translated_fields + '/' + product?.total_fields }}</span>
  </div>
</div>

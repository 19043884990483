import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ICreateAccountErrorResponse } from '@app/core/interface/register.interface';
import { ILoginResponse } from '@app/core/interface/login.interface';
import { LoginFacade } from '@app/core/facade/login.facade';
import { NotificationType } from '@app/core/constants';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { RegisterGroup } from '../register.group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/service/translation.service';
import { UntypedFormGroup } from '@angular/forms';
import { transformTrustURL } from '@app/core/utils/transform-trust-url';

@Component({
  selector: 'app-create-account-new',
  templateUrl: './create-account-new.component.html',
  styleUrls: ['./create-account-new.component.scss'],
  providers: [RegisterFacade, RegisterGroup],
})
export class CreateAccountNewComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  hide = true;
  supplierEmail: string | null = null;
  uid: string | null = null;
  token: string | null = null;
  alive = true;
  lang: string | null = '';
  pdfUrl: SafeResourceUrl | null = null;

  constructor(
    private readonly _facade: RegisterFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly _regGrup: RegisterGroup,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _loginFacade: LoginFacade,
    public translate: TranslateService,
    public translation: TranslationService,
    private readonly sanitizer: DomSanitizer
  ) {
    this.form = this._regGrup.createAccountForm;
    if (this._route.snapshot.paramMap.get('uid') && this._route.snapshot.paramMap.get('token')) {
      this.form = this._regGrup.createSuppAccountForm;
      this.uid = this._route.snapshot.paramMap.get('uid');
      this.supplierEmail = this._route.snapshot.queryParamMap.get('supplier_email');
      this.lang = this._route.snapshot.queryParamMap.get('lang');
      this.token = this._route.snapshot.paramMap.get('token');
      this.supplierEmail && this.setSupplierInForm(this.supplierEmail);
    }
    this.lang = this.lang === 'fr' ? 'fr' : 'en';
    this.translation.language.next(this.lang);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngOnInit(): void {
    const customer = JSON.parse(sessionStorage.getItem('customer') || '{}');
    const paymentId = sessionStorage.getItem('paymentId') || '';
    if (customer && !this.supplierEmail) {
      this.form.patchValue({
        email: customer.email,
        chargebee_subscription_id: paymentId,
      });
    }
  }

  createAccount(): void {
    // Check if it's suppliers contact and if form is valid
    if (this.supplierEmail && this.form.valid) {
      const payload = {
        ...this.form.value,
        new_password1: this.form.controls['password1'].value,
        new_password2: this.form.controls['password2'].value,
      };
      delete payload.password1;
      delete payload.password2;
      this._facade.inviteSupplier$(payload).subscribe({
        next: this._successSupplierAcc.bind(this),
        error: this._error.bind(this),
      });
    }
    // Check if it's not suppliers contact and if form is valid
    if (!this.supplierEmail && this.form.valid) {
      this._facade
        .createAccount$({
          ...this.form.value,
        })
        .subscribe({
          next: this._success.bind(this),
          error: this._error.bind(this),
        });
    }
  }
  setSupplierInForm(email: string): void {
    this.form.controls['uid'].setValue(this.uid);
    this.form.controls['token'].setValue(this.token);
    this.supplierEmail = this.decodeEmail(email);
    this.form.controls['email'].setValue(this.supplierEmail);
  }

  decodeEmail(encodedEmail: string): string {
    // Decode percent-encoded characters
    let decodedEmail = decodeURIComponent(encodedEmail);
    // Correctly handle the '+' sign if it was converted to a space
    // Here we assume that an actual space in the email should remain a space
    // And a '+' that was converted to a space should become a '+'
    decodedEmail = decodedEmail.replace(' ', '+');
    return decodedEmail;
  }

  private _success(data: ILoginResponse): void {
    this._snackbarService.openTypeSnackbar(`Welcome, ${data.user.username}`, NotificationType.success);
    this._router.navigate(['register/account-created']);
  }

  private _successSupplierAcc(): void {
    this._router.navigate(['select-brand']);
  }

  private _error(error: ICreateAccountErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  logout() {
    this._loginFacade.logout$().subscribe({
      next: () => this._router.navigate(['/login']),
      error: this._error.bind(this),
    });
  }

  openUsePolicyDialog(): void {
    const pdfPath = "../../../../assets/terms/MSA PDNA's Terms and Conditions english.pdf";

    // Generate a safe URL for the PDF file
    this.pdfUrl = transformTrustURL(pdfPath, this.sanitizer);

    if (this.pdfUrl && this.lang === 'en') {
      window.open("../../../../assets/terms/MSA PDNA's Terms and Conditions english.pdf", '_blank');
    } else {
      window.open("../../../../assets/terms/MSA PDNA's Terms and Conditions french.pdf", '_blank');
    }
  }
}

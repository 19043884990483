<div class="background">
  <div class="product-grid-view">
    <!-- START Products overview -->
    <mat-card class="product-list-wraper">
      <div class="header-wraper">
        <mat-card class="options-header">
          <div class="product-heading">
            <p>
              {{ 'Products Overview' | translate }}
            </p>
          </div>
          <div class="filter-view-wraper">
            <div class="create-product-btn">
              <app-rounded-button
                (click)="openCreateDialog()"
                [button_text]="'Create Product'"
                [icon_category]="'product'"
                [icon_name]="'3d-cube-scan'"
                [icon_right]="true"
                [size]="'md'"
                class="custom-icon-class"
                test-id="button-products-create_product"
              >
              </app-rounded-button>
            </div>
            <div class="view-list-btn">
              <app-rounded-button
                [routerLink]="'/products/list'"
                [button_text]="'View Product list'"
                [icon_category]="'product'"
                [icon_name]="'menu-3lines'"
                [icon_right]="true"
                [size]="'md'"
                id="menu-3lines"
                test-id="button-products-view_product_list"
              >
              </app-rounded-button>
            </div>
          </div>
        </mat-card>
        <!-- Products overview detalis -->
        <div class="overview-details d-flex flex-row justify-content-between mt-2">
          <div class="detail-holder h-92 d-flex flex-row align-items-center justify-content-start">
            <div class="overview-dot dark-blue">
              <span test-id="span-products-active_products_created">{{
                productsDashboardInfo?.product_created || 0
              }}</span>
            </div>
            <div class="overview-title green">Active Products Created</div>
          </div>
          <div class="detail-holder h-92 d-flex flex-row align-items-center justify-content-start">
            <div class="overview-dot light-green">
              <span test-id="span-products-active_supply_chains">{{ productsDashboardInfo?.product_chains || 0 }}</span>
            </div>
            <div class="overview-title">
              <div>Active Supply Chains</div>
            </div>
          </div>
          <div class="detail-holder h-92 d-flex flex-row align-items-center justify-content-start">
            <div class="overview-dot dark-green">
              <span test-id="span-products-active_products_published">{{
                productsDashboardInfo?.product_published || 0
              }}</span>
            </div>
            <div class="overview-title">Active Products Published</div>
          </div>
        </div>
        <!-- Products overview details -->
      </div>
    </mat-card>
    <!-- END Products overview -->

    <!-- Products Project and Certificates -->
    <div class="d-flex justify-content-center">
      <!-- START Products Certificates -->
      <mat-card class="product-list-wraper w-100">
        <div class="header-wraper">
          <mat-card class="options-header">
            <div class="product-heading">
              <p>
                {{ 'Products Cube' | translate }}
              </p>
            </div>
            <div class="filter-view-wraper">
              <div class="create-product-btn">
                <!-- <app-rounded-button color="tetriary-link" [button_text]="'See details'" [icon_category]="'arrows'"
                  [icon_name]="'arrow-right'" [icon_right]="true" [size]="'md'">
                </app-rounded-button> -->
              </div>
            </div>
          </mat-card>
          <!-- Products projects detalis -->
          <div [ngClass]="show_project ? 'flex-column' : 'flex-row'" class="d-flex justify-content-center gap-3">
            <div [ngClass]="show_project ? '' : 'cube-holder'">
              <div class="overview-details d-flex flex-row justify-content-between">
                <div class="detail-holder d-flex flex-row align-items-center justify-content-start">
                  <div class="overview-subdot dark-blue">
                    <span test-id="span-products-total_certificates">{{ productsDashboardInfo?.certificates }}</span>
                  </div>
                  <div class="w-100">
                    <p class="overview-title green font-14 w-100 m-0">Total certificates</p>
                    <div class="d-flex align-items-center">
                      <app-custom-icon
                        class="icon-green"
                        [icon_category]="'essential'"
                        [icon_name]="'tick-circle'"
                      ></app-custom-icon>
                      <span class="ps-1 light-green">Up to date</span>
                    </div>
                  </div>
                </div>
                <div class="detail-holder d-flex flex-row align-items-center justify-content-start">
                  <div class="overview-subdot solid-yellow">
                    <span test-id="span-products-certificates_30_days_expires">{{
                      productsDashboardInfo?.certificates_30_days_expires
                    }}</span>
                  </div>
                  <div class="overview-title-details">
                    <p class="overview-title font-14 m-0">Certificates</p>
                    <span>Expire in next 30 days!</span>
                  </div>
                </div>
              </div>
            </div>

            <div [ngClass]="show_project ? '' : 'cube-holder'">
              <div class="overview-details d-flex flex-row justify-content-between">
                <div class="detail-holder d-flex flex-row align-items-center justify-content-start">
                  <div class="overview-subdot dark-green">
                    <span test-id="span-products-active_suppliers">{{ productsDashboardInfo?.active_suppliers }}</span>
                  </div>
                  <p class="overview-title font-14 green m-0">Active Suppliers</p>
                </div>
                <div class="detail-holder d-flex flex-row align-items-center justify-content-start">
                  <div class="overview-subdot dark-green">
                    <span test-id="span-products-active_products_components">{{
                      productsDashboardInfo?.active_components
                    }}</span>
                  </div>
                  <p class="overview-title font-14 m-0">Active products components</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Products projects details -->
        </div>
      </mat-card>
      <!-- Products Certificates -->
      <!-- START Products Projects -->
      <mat-card *ngIf="show_project" class="product-list-wraper w-100">
        <div class="header-wraper">
          <mat-card class="options-header">
            <div class="product-heading">
              <p>
                {{ 'Projects' | translate }}
              </p>
            </div>
            <div class="filter-view-wraper">
              <div class="create-product-btn">
                <app-rounded-button
                  color="secondary"
                  [routerLink]="'/products/projects'"
                  [button_text]="'Explore Projects'"
                  [icon_category]="'arrows'"
                  [icon_name]="'arrow-right'"
                  [icon_right]="true"
                  [size]="'md'"
                >
                </app-rounded-button>
              </div>
            </div>
          </mat-card>
          <div class="d-flex flex-column justify-content-between gap-3">
            <div class="detail-holder w-90 h-92 d-flex flex-row align-items-center justify-content-start">
              <div class="overview-dot dark-blue">
                <span>{{ productsDashboardInfo?.projects_info?.projects_created }}</span>
              </div>
              <div class="overview-title d-flex">
                <div class="m-0">Projects Created</div>
                <div class="ps-3 pe-3">|</div>
                <span class="sub-title">{{ productsDashboardInfo?.projects_info?.subprojects }} subprojects</span>
              </div>
            </div>
            <!-- Products projects detalis -->
            <div>
              <div class="overview-details d-flex flex-row justify-content-between">
                <div class="detail-holder w-40 d-flex flex-row align-items-center justify-content-start">
                  <div class="overview-subdot w-32 grey">
                    <span>{{ productsDashboardInfo?.projects_info?.projects_not_started }}</span>
                  </div>
                  <p class="overview-title font-14 green m-0">Not Started Projects</p>
                </div>
                <div class="detail-holder w-40 d-flex flex-row align-items-center justify-content-start">
                  <div class="overview-subdot w-32 light-green">
                    <span>{{ productsDashboardInfo?.projects_info?.projects_in_progress }}</span>
                  </div>
                  <p class="overview-title font-14 green m-0">In Progress Projects</p>
                </div>
                <div class="detail-holder w-40 d-flex flex-row align-items-center justify-content-start">
                  <div class="overview-subdot w-32 dark-green">
                    <span>{{ productsDashboardInfo?.projects_info?.projects_completed }}</span>
                  </div>
                  <p class="m-0 overview-title font-14">Completed Projects</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Products projects details -->
        </div>
      </mat-card>
      <!-- END Product Projects -->
    </div>
    <!-- END Products project -->

    <!-- START Recently added products -->
    <mat-card class="product-list-wraper overflow-auto">
      <div class="header-wraper">
        <mat-card class="options-header">
          <div class="product-heading">
            <p>
              {{ 'Recently added products' | translate }}
            </p>
          </div>
          <div class="filter-view-wraper"></div>
        </mat-card>
        <div class="overview-details fit-content d-flex flex-row justify-content-start">
          <div
            *ngFor="let recentlyProduct of productsDashboardInfo?.latest_products"
            class="details-holder background-solid-blue h-120 w-360 d-flex flex-row p-3 justify-content-start"
          >
            <div>
              <div class="product justify-content-around">
                <div class="image">
                  <img
                    src="{{
                      recentlyProduct.image ? recentlyProduct.image : '../../../../assets/images/brand-avatar.png'
                    }}"
                    alt=""
                  />
                </div>
                <div class="description justify-content-center">
                  <div class="name-holder">
                    <h5 class="name">{{ recentlyProduct.name }}</h5>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <div
                      class="chains"
                      [ngClass]="recentlyProduct.chain_created === 0 ? 'product-chains-gray' : 'product-chains-green'"
                    >
                      <div class="dot"></div>
                      <p>{{ recentlyProduct.chain_created + ' chains created' | translate }}</p>
                    </div>
                    <app-custom-icon
                      class="icon-royal-blue cursor-pointer"
                      [icon_category]="'security'"
                      [icon_name]="'eye'"
                      matTooltip="{{ 'View Product' | translate }}"
                      matTooltipClass="custom-tooltip-center centering"
                      [matTooltipPosition]="'above'"
                      routerLink="../dashboard/product/{{ recentlyProduct.uuid }}"
                    ></app-custom-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <!-- END Recently added Products -->
  </div>
</div>

<div class="card-holder d-flex flex-column">
  <div class="wrapper d-flex align-items-center justify-content-between">
    <div class="info-wrapp d-flex align-items-center">
      <img class="logo" [src]="certtificate.image ? certtificate.image : imgPlaceholder" alt="" />
      <p class="m-0 cert-name">{{ certtificate.name }}</p>
    </div>
    <app-custom-icon
      class="cursor-pointer"
      (click)="manageTranslations(certtificate)"
      [icon_category]="'essential'"
      [icon_name]="'translate'"
    ></app-custom-icon>
  </div>
  <div class="progress" [ngClass]="isTranslationComplete(certtificate) ? 'fully' : 'missing'">
    <p class="txt-strong">{{ 'Translations progress:' }}</p>
    <span class="txt">{{ certtificate?.translated_fields + '/' + certtificate?.total_fields }}</span>
  </div>
</div>

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { transformTrustURL } from '@app/core/utils/transform-trust-url';

@Component({
  selector: 'app-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss'],
})
export class CustomIconComponent implements OnChanges {
  iconsPath = '../../../../assets/Icons/';
  @Input() icon_name = '';
  @Input() icon_category = '';
  @Input() disabled = false;

  constructor(
    private readonly _matIconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['icon_category'] || changes['icon_name']) {
      this._matIconRegistry.addSvgIcon(
        `${this.icon_name}`,
        transformTrustURL(`${this.iconsPath}${this.icon_category}/24x/${this.icon_name}-24.svg`, this._domSanitizer)
      );
      this._matIconRegistry.addSvgIcon(
        `${this.icon_name}`,
        transformTrustURL(`${this.iconsPath}${this.icon_category}/32px/${this.icon_name}-32.svg`, this._domSanitizer)
      );
    }
  }
}

<div
  [ngClass]="
    !supplier?.certification_supplier?.length &&
    !supplier?.documents?.length &&
    !supplier?.medias?.length &&
    !supplier?.links?.length
      ? 'd-none'
      : 'holder'
  "
>
  <div class="certificates">
    <div class="cube-title">
      <p>
        {{ 'Certificates' | translate }}
      </p>
    </div>
    <div class="cube-list" *ngIf="supplier && supplier.certification_supplier?.length; else noneblock">
      <div class="certificate-number">
        <p test-id="paragraph-supplier_cube-certificates">{{ supplier?.certification_supplier?.length }}</p>
      </div>
    </div>
  </div>

  <div class="useful-data col-12">
    <div class="cube-title">
      <p>
        {{ 'Useful Data' | translate }}
      </p>
    </div>

    <div class="section-holder col-12">
      <section class="documents col-4">
        <div class="cube-subtitle">
          <p>
            {{ 'Documents' | translate }}
          </p>
        </div>
        <div class="cube-list" *ngIf="supplier && supplier.documents?.length; else noneblock">
          <div class="number">
            <p test-id="paragraph-supplier_cube-documents">{{ supplier?.documents?.length }}</p>
          </div>
        </div>
      </section>

      <section class="medias col-4">
        <div class="cube-subtitle">
          <p>
            {{ 'Medias' | translate }}
          </p>
        </div>
        <div class="cube-list" *ngIf="supplier && supplier.medias?.length; else noneblock">
          <div class="number">
            <p test-id="paragraph-supplier_cube-medias">{{ supplier?.medias?.length }}</p>
          </div>
        </div>
      </section>

      <section class="web-social col-4">
        <div class="cube-subtitle">
          <p>
            {{ 'Websites & Social' | translate }}
          </p>
        </div>
        <div class="cube-list" *ngIf="supplier && supplier.links?.length; else noneblock">
          <div class="number">
            <p test-id="paragraph-supplier_cube-websites_social">{{ supplier?.links?.length }}</p>
          </div>
        </div>
      </section>
    </div>
  </div>

  <ng-template #noneblock>
    <div class="cube-list">
      {{ '0' | translate }}
    </div>
  </ng-template>
</div>
<!-- No certicicates and useful data uploaded  -->
<div
  class=""
  [ngClass]="
    !supplier?.certification_supplier?.length &&
    !supplier?.documents?.length &&
    !supplier?.medias?.length &&
    !supplier?.links?.length
      ? 'holder-no-data'
      : 'd-none'
  "
>
  <div class="certificates">
    <div class="cube-title">
      <p>
        {{ 'Certificates' | translate }}
      </p>
    </div>
    <div class="cube-list">
      <div class="certificate-icon">
        <app-custom-icon id="certificate" [icon_category]="'learning'" [icon_name]="'certificate'"></app-custom-icon>
        <app-custom-icon id="note-2" [icon_category]="'learning'" [icon_name]="'note-2'"></app-custom-icon>
      </div>
    </div>
  </div>

  <div class="useful-data col-12">
    <div class="cube-title">
      <p>
        {{ 'Useful Data' | translate }}
      </p>
    </div>

    <div class="section-holder col-12">
      <p class="no-data">There are no certificates and useful data uploaded yet.</p>
    </div>
  </div>

  <ng-template #noneblock>
    <div class="cube-list">
      {{ '0' | translate }}
    </div>
  </ng-template>
</div>

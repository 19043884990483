import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ILanguageManagementSupplier,
  ILanguageManagementSuppliersResponse,
} from '@app/core/interface/language-management.interface';

import { LanguageManagementFacade } from '@app/core/facade/language-management.facade';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable, Subscription } from 'rxjs';
import { LanguageManagementService } from '@app/core/service/language-management.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-supplier-translations',
  templateUrl: './supplier-translations.component.html',
  styleUrl: './supplier-translations.component.scss',
  providers: [LanguageManagementFacade],
})
export class SupplierTranslationsComponent implements OnInit, AfterViewInit, OnDestroy {
  isMissing: boolean = true;
  hasSupplyChainFIlter = false;
  missingTranslations!: number | undefined | null;
  fullyTranslated!: number | undefined | null;
  suppliers!: ILanguageManagementSupplier[];
  cardDataSource = new MatTableDataSource<ILanguageManagementSupplier>();
  suppliersObservable$!: Observable<ILanguageManagementSupplier[]>;
  private refreshSubscription!: Subscription;

  readonly _languageManagementFacade = inject(LanguageManagementFacade);
  readonly _languageManagementService = inject(LanguageManagementService);
  readonly _snackbarService = inject(SnackbarService);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() toggleChanged = new EventEmitter<string>();
  @Input() initialToggleState: string = 'missing';
  @Input() selectedChainUuid: string | null = null;

  ngOnInit(): void {
    this.isMissing = this.initialToggleState === 'missing';
    this.getLanguageManagementSuppliers(!this.isMissing);
    this.hasSupplyChainFIlter = this.selectedChainUuid !== null;
    this.subscribeToRefresh();
  }

  subscribeToRefresh(): void {
    this.refreshSubscription = this._languageManagementService.refreshSupplier$.subscribe(refresh => {
      if (refresh) {
        this.getLanguageManagementSuppliers(!this.isMissing);
      }
    });
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
    this.paginator.pageSize = 8;
  }

  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource);
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.isMissing = event.value === 'missing';
    this.toggleChanged.emit(event.value);
    if (event.value === 'fully') {
      this.getLanguageManagementSuppliers(true);
    } else {
      this.getLanguageManagementSuppliers(false);
    }
  }

  getLanguageManagementSuppliers(isFullyTranslated: boolean): void {
    this._languageManagementFacade.getLanguageManagementSuppliers$(isFullyTranslated).subscribe({
      next: this._getLanguageManagementSuppliersSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
  //////////////////////////////////////////////////Private methods///////////////////////////////////////////////
  private _getLanguageManagementSuppliersSuccess(data: ILanguageManagementSuppliersResponse): void {
    if (data) {
      this.suppliers = data.results;
      this.missingTranslations = data?.suppliers_missing_translations;
      this.fullyTranslated = data?.suppliers_fully_translated;
      this.cardDataSource.data = data.results;
      this.suppliersObservable$ = this.cardDataSource.connect();
      this._languageManagementService.triggerSupplierRefresh(false);
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).forEach(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}

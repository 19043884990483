import { IResetPasswordErrorResponse, IResetPasswordResponse } from '@app/core/interface/forgot-password.interface';
import { Observable, map } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ForgotPasswordFacade } from '@app/core/facade/forgot-password.facade';
import { NotificationType } from '@app/core/constants';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  backBtnClass: Observable<string>;

  emailFormControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  constructor(
    private readonly _facade: ForgotPasswordFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly _breakpointObserver: BreakpointObserver,
    private readonly _router: Router
  ) {
    this.backBtnClass = this._breakpointObserver
      .observe('(min-width: 768px)')
      .pipe(map(({ matches }) => (matches ? 'secondary' : 'tetriary-link')));
  }

  onSubmit(): void {
    this._facade
      .resetPassword$(this.emailFormControl.value)
      .pipe(take(1))
      .subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
  }

  private _success(data: IResetPasswordResponse): void {
    this._snackbarService.openTypeSnackbar(data.detail, NotificationType.success);
    this._router.navigate(['password_reset/link-sent']);
    sessionStorage.setItem('resetPassword', this.emailFormControl.value);
  }

  private _error(error: IResetPasswordErrorResponse): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ICountry, ISupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';
import { Observable, map, startWith } from 'rxjs';

import { CountryList } from '@app/core/utils/country-list';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { Location } from '@angular/common';
import { ManageSupplierComponent } from '@app/module/dashboard/supplier/manage-supplier/manage-supplier.component';
import { MatDialog } from '@angular/material/dialog';
import { SetCountry } from '@app/core/utils/country';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupplierDetailsDialogComponent } from '../supplier-details-dialog/supplier-details-dialog.component';
import { SupplierManagmentGroup } from '../supplier-managment.group';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-supplier-page-details',
  templateUrl: './supplier-page-details.component.html',
  styleUrls: ['./supplier-page-details.component.scss'],
  providers: [SupplierManagmentGroup],
})
export class SupplierPageDetailsComponent implements OnInit, OnChanges {
  @Input() supplier!: Partial<ISupplierResponse> | Partial<ISupplier>;
  @Input() disabled!: boolean;
  @Output() isEditDetails = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<boolean>(false);
  @Output() show_buttons = new EventEmitter<boolean>();
  form!: UntypedFormGroup;
  countryList = CountryList.isoCountries;
  filteredCountries!: Observable<Array<ICountry>>;
  originalSupplier!: Partial<ISupplierResponse> | Partial<ISupplier>;
  component_btn!: boolean;
  editSupplierHeader!: boolean;
  constructor(
    private readonly _supplierGroup: SupplierManagmentGroup,
    private readonly _facade: SuppliersFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly _dialog: MatDialog,
    private readonly _suppliersFacade: SuppliersFacade,
    private readonly _location: Location
  ) {
    this.form = this._supplierGroup.supplierForm;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled && changes['supplier']) {
      this.originalSupplier = changes['supplier'].currentValue;
      this._setForm();
    }
  }
  /**
   * Filters countries on user typing or selecting dropdown
   * @param value value entered by user
   * @returns filtered list of countries
   */
  private _filter(value: string | ICountry): ICountry[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
    const filteredList = this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
    return filteredList;
  }
  /**
   * On OnInit we subscribe on country changes and filter the country in dropdown
   */
  ngOnInit(): void {
    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }
  /**
   * Shows only name as display value and keeps hole obj as actual value (code is used for requiest and name for display)
   * @param subject ICountry from country dropdown
   * @returns country name for display
   */
  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }
  /**
   * Sets form using supplier object
   */
  private _setForm() {
    if (this.originalSupplier)
      this.form.patchValue({
        description: this.originalSupplier.description,
        address: this.originalSupplier.address,
        city: this.originalSupplier.city,
        country: SetCountry(this.countryList, this.originalSupplier.country),
      });
  }
  /**
   * Sets original form and emit false for isEditDetails
   */
  cancel() {
    this._setForm();
    this.isEditDetails.emit(false);
  }
  save() {
    const submitForm = GetDirtyValues(this.form);
    if (submitForm['country'])
      if (typeof submitForm['country'] === 'string') {
        this.countryList.forEach(country => {
          if (country.name.toLowerCase() === submitForm['country'].toLowerCase()) {
            submitForm['country'] = country.code;
            this.form.controls['country'].patchValue(country);
          }
        });
      } else {
        submitForm['country'] = this.form.controls['country'].value.code;
      }
    this.supplier.uuid &&
      this._facade.updateSupplier$(this.supplier.uuid, submitForm).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
  }
  editDetails() {
    const dialogRef = this._dialog.open(ManageSupplierComponent, {
      data: { supplier: this.supplier, show_buttons: false, component_btn: false, editSupplierHeader: true },
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh.emit(true);
      }
    });
  }

  removeSupplier(): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete supplier: ${this.supplier.name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.supplier.uuid &&
          this._suppliersFacade.deleteSupplier$(this.supplier.uuid).subscribe({
            next: this._deleteSupplier.bind(this),
            error: this._error.bind(this),
          });
      }
    });
  }
  _deleteSupplier() {
    this._snackbarService.openTypeSnackbar(`Supplier deleted`, NotificationType.success);
    this._location.back();
  }

  viewDetails() {
    const dialogRef = this._dialog.open(SupplierDetailsDialogComponent, {
      data: this.supplier,
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refresh.emit(true);
      }
    });
  }
  /**
   * On success update we are emitting false for isEditDetails
   */
  private _success(sup: ISupplier) {
    this.originalSupplier.description = sup?.description;
    this.originalSupplier.country = sup?.country;
    this.originalSupplier.city = sup?.city;
    this.originalSupplier.address = sup?.address;
    this._snackbarService.openTypeSnackbar(`Update success`, NotificationType.success);
    this.isEditDetails.emit(false);
  }
  /**
   *
   * @param error Hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).forEach(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}

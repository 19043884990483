import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBatchPreview, IBrandPreview } from '@app/core/interface/preview_publicaton.interface';
import { SharedModule } from '@app/shared/shared.module';
import { Observable, map, startWith } from 'rxjs';

import { IProduct } from 'src/app/core/interface/products.interface';

@Component({
  standalone: true,
  imports: [SharedModule, CommonModule],
  selector: 'app-batch-info-dialog',
  templateUrl: './batch-info-dialog.component.html',
  styleUrls: ['./batch-info-dialog.component.scss'],
})
export class BatchInfoDialogComponent {
  myControl = new FormControl('');
  filteredOptions?: Observable<IBatchPreview[]>;
  selectedBatch?: IBatchPreview;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { product: IProduct; brand: IBrandPreview; batches: IBatchPreview[] }
  ) {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value ?? '');
      })
    );
  }

  private _filter(value: string) {
    if (typeof value != 'string') {
      return [];
    }

    return this.data.batches.filter(option => option.external_reference?.includes(value.toUpperCase()));
  }

  displayFn(batch: IBatchPreview | null): string {
    return batch?.external_reference ?? '';
  }

  onBatchChange(selectedBatch: IBatchPreview | undefined): void {
    this.selectedBatch = selectedBatch;
  }

  isDisplaySmall(): boolean {
    return window.innerWidth < 370;
  }
}

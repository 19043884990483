import { Component, inject, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-publishing-supply-chain-info-dialog',
  templateUrl: './publishing-supply-chain-info-dialog.component.html',
  styleUrl: './publishing-supply-chain-info-dialog.component.scss',
})
export class PublishingSupplyChainInfoDialogComponent {
  readonly router = inject(Router);
  readonly dialogRef = inject(MatDialogRef<PublishingSupplyChainInfoDialogComponent>);
  selectedChainUuid!: string;
  constructor(@Inject(MAT_DIALOG_DATA) private readonly _data: { selectedChainUuid: string }) {
    this.selectedChainUuid = this._data.selectedChainUuid;
  }
  goToPublicationLanguageManagement(): void {
    this.dialogRef.close();
    this.router.navigate(['/manage-publications'], {
      state: { selectedChainUuid: this.selectedChainUuid },
    });
  }
}
